import { FETCH_MYRIGHTS,UPDATE_MYRIGHTS,UPDATE_IS_LOGGED_IN} from "../../actions/actionTypes";
import { getAuth } from "firebase/auth";
const initialState = {
  myRights:null,
  loading: !getAuth().currentUser,
  loginType:null,
  userStatus:null,
  isLoggedIn:false
};

const reducer = (state = initialState, action) => {
  const auth = getAuth();
  let provider=auth?.currentUser?.providerData[0].providerId;
  switch (action.type) {
    case FETCH_MYRIGHTS.REQUEST:
      return { ...state,userStatus:null, loading: true };
    case FETCH_MYRIGHTS.FAILURE:
      return { ...state,userStatus:action.error_code,loading: false };
    case FETCH_MYRIGHTS.SUCCESS:
      return { ...state,userStatus:null, myRights:action.myRights,loginType:provider?provider:null, loading: false };
    case UPDATE_MYRIGHTS:
        return { ...state,userStatus:null, myRights:action.myRights,loginType:provider?provider:null, loading: false };
    case UPDATE_IS_LOGGED_IN:
          return { ...state, isLoggedIn: action.isLoggedIn};
    default:
      return state;
  }
};

export default reducer;
