import styles from "./overlaydropdown.module.css";
import IMAGES from "../../assets/images/images";
import React, { useState, useEffect, useRef } from 'react';

const Overlaydropdown = React.forwardRef(({ initials, children }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const overlayRef = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useImperativeHandle(ref, () => ({
    handleClick
  }));

  return (
    <div ref={overlayRef}>
      <button className={styles.applybtn} onClick={handleClick}>
        <div className={styles.userIcon}>{initials}</div>
      </button>

      {isOpen && (
        <div className={styles.overlayInnerbox}>
          {children}
        </div>
      )}
    </div>
  );
});

export default Overlaydropdown;


  {/* <div className={styles.headerContainer}>
                <div className={styles.header}>{headerText}</div>
                <div className={styles.close} >
                  <img src={IMAGES.close} onClick={handleClick} alt="close" height={"24"} width={"24"}/>
                </div>
            </div> */}