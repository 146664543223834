import React, { useEffect, useRef, useState } from "react";
import * as echarts from 'echarts';
import { Graph_Name, Graphs } from "../../utils/constants/insightsConstants";

const defaultStyle = { width: "100%", height: "400px" }

const LineChart = ({ legend, xAxisData, series, style = defaultStyle,YAxisName,grpahType,graphName, ...props }) => {
    const chartRef = useRef(null);

    const formatNumberWithUnits = (number) => {
        //const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let units=[];
        if(graphName==Graph_Name.Peak_In_Out){
            units = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
        }else{
            units = ['KB', 'MB', 'GB', 'TB'];
        }

        let i = 0;
        while (number >= 1000 && i < units.length - 1) {
            number /= 1000;
            i++;
        }
        return number.toFixed(2) + ' ' + units[i]
    }

    let yAxisOptions = {
        type: 'value',
        axisLine:{
           show:true,
           lineStyle:{
                 color:"#666666"
            }
        },
    };
    let tooltipOptions = {
        trigger: 'axis'
    };
    
    if (grpahType == Graphs.WEATHERMAPS) {
        yAxisOptions.axisLabel = {
            //formatter: `{value} ${YAxisName}`,
            formatter: function(value){
                return formatNumberWithUnits(value);
            },
            textStyle:{
                color:"#666666",
                fontFamily:"VodafoneLt-Regular",
                fontSize:14,
            }
        }
        // format tooltip
        tooltipOptions.formatter= function(params){
            let tooltipText=params[0].name +'<br/><table style="width:100%">';
                params.forEach(function(item){
                    tooltipText+='<tr>'+
                                '<td style="text-align:left;padding-right: 16px;">' +item.marker + item.seriesName + '</td>'+
                                '<td style="text-align:right;font-weight: bold">' +formatNumberWithUnits(item.value) + '</td>'+
                                '</tr>'
                })
                tooltipText+='</table>'
            return tooltipText;
        }
    } else {
        yAxisOptions.name = YAxisName;
       //yAxisOptions.nameLocation = "middle";
        yAxisOptions.nameTextStyle = {
            align:"right",
            color:"#666666",
            fontFamily:"VodafoneLt-Regular",
            fontSize:14,
        };
        yAxisOptions.axisLabel = {
            textStyle:{
                color:"#666666",
                fontFamily:"VodafoneLt-Regular",
                fontSize:14,
        }
        }

    }
    const legendLength = legend?.length
    
    useEffect(() => {
        const myChart = echarts.init(chartRef.current);
        const options = {
            tooltip: tooltipOptions,
            legend: legendLength>6?{
                type: 'scroll',
                orient: 'horizontal',
                data: legend,
                textStyle:{
                    color:"#666666",
                    fontFamily:" VodafoneRg-Bold",
                    fontSize:16
                },
                height:'100',
                pageButtonGap: 10,
                pageButtonPosition: 'end',
                pageIconColor: '#666666',
                pageIconInactiveColor: '#aaa',
                pageIconSize: 15,
                pageTextStyle: {
                    color: '#666666',
                    fontSize: 14
                },
                // formatter: function (name) {
                //     return echarts.format.truncateText(name, 80, '8px Microsoft Yahei', '…');
                // },
                tooltip: {
                    show: true
                }
            }:{
                data: legend,
                textStyle:{
                    color:"#666666",
                    fontFamily:" VodafoneRg-Bold",
                    fontSize:16
                },
                height:'100',
                //type: 'scroll',
                tooltip: {
                    show: true
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xAxisData,
                axisLabel: {
                   //interval: Math.round(xAxisData?.length/40),
                    rotate: 45,
                    //hideOverlap: true,
                    textStyle:{
                        color:"#666666",
                        fontFamily:"VodafoneLt-Regular",
                        fontSize:14
                    }
                },
                axisLine:{
                    lineStyle:{
                        color:"#666666"
                     }
                 },
               
            },
            yAxis: yAxisOptions,
            dataZoom: [
                /*{
                    type: 'slider',
                    start: 0,
                    end: 100
                },*/
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                }],
            series: series,
            grid: { containLabel: true,left: 90,bottom:10,right:10,height:'350'},
        };
        myChart.setOption(options);
        
        //CUP-778
        myChart.on('legendselectchanged', function (params) {
            const selected = params.selected;
            const allDeselected = Object.values(selected).every(value => !value);
            if(grpahType != Graphs.WEATHERMAPS){
                if (allDeselected) {
                    yAxisOptions.name = "";
                    myChart.setOption({
                        yAxis: yAxisOptions,
                    });
                } else {
                    yAxisOptions.name = YAxisName;
                    myChart.setOption({
                        yAxis: yAxisOptions,
                    });
                }
            }
        });
 //         _____________**__________________       //
        window.addEventListener('resize', function() {
            myChart.resize();
          });
        return () => {
            myChart.dispose();
        }
    }, [xAxisData,series,legend])
    return (
        <div ref={chartRef} className={`${style}`}></div>
    )
}

export default LineChart;