import {combineReducers} from "redux";
import onboardingList from './onboaedingList.reducer';
import onboardingDetail from "./onboardingDetail.reducer";
import onboardingRequestList from "./onboardingRequestList.reducer";
import onBoardingRequestDetail from "./onBoardingRequestDetail.reducer";
import accountMManagerList from "./accountManagerList.reducer";
import onBooardingApprovalList from "./onBoardingApprovalList.reduce"
import onBoardingApprovalDetail from "./onBordingApprovalDetail.reducer"
import onBordingCommentList from "./onBordingCommentList.reducer";
import extensionRequestDetail from "./extensionRequestDetail.reducer"
import primaryContactList from "./primaryContactList.reducer";
import extensionApprovalDetail from "./extensionApprovalDetail.reducer";
import getAllCustomerList from "./getAllCustomerList.reducer";
import redirectTab from "./redirectTab.reducer"
export default combineReducers({
    onboardingList,
    onboardingDetail,
    onboardingRequestList,
    onBoardingRequestDetail,
    accountMManagerList,
    onBooardingApprovalList,
    onBoardingApprovalDetail,
    onBordingCommentList,
    extensionRequestDetail,
    primaryContactList,
    extensionApprovalDetail,
    getAllCustomerList,
    redirectTab
})