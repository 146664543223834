export const UserManagementTab = {
    CUSTOMER: "CUSTOMERS",
    VF_GROUP_USER: "VF_GROUP_USER",
    ON_BOARDING_APPROVALS: "ON_BOARDING_APPROVALS",
    // MY_REQUEST: "MY_REQUEST",
    ON_BOARDING_REQUEST: "ON_BOARDING_REQUEST", 
}

export const ServiceType = {
    International_Voice_Services: "International_Voice_Services",
    IPX_Services: "IPX_Services",   
}
export const My_Request_SUBTAB = {
    ON_BOARDING_REQUEST: "ON_BOARDING_REQUEST",
    EXTENSION_ACCESS_REQUEST: "EXTENSION_ACCESS_REQUEST",
    EXTENSION_ACCESS_APPROVAL: "EXTENSION_ACCESS_APPROVAL",
    ON_BOARDING_APPROVAL: "ON_BOARDING_APPROVAL",
}

export const EXTENSTION_STATUS = {
    PENDING : "pending",
    APPROVED : "approved"
}

export const ON_BOARDING_STATUS = {
    PENDING : "pending",
    APPROVED : "approved",
    REJECTED : "rejected"
}

export const FUNCTIONAL_ROLE_ENUM = {
    USER_MANAGEMENT:"user_management_customer",
    INCIDENT_MANAGEMENT:"incident_management_customer",
    DASHBOARD:"dashboard_customer"
}