import React from 'react'
import styles from './fileupload.module.css'
import IMAGES from '../../../assets/images/images'
function FileUpload() {
    return (
        <div className={styles.mainContainer}>
              <div className={styles.subcontainer}>
                <div  className={styles.logo}><img src={IMAGES.attach}/></div>
                <div  className={styles.dragdrop}>Drag and drop file here</div>
                <div  className={styles.text}>Each file can be up to 5 MB, with a maximum of 3 files allowed for upload.</div>
                <div className={styles.button} >Browse</div>
              </div>
        </div>
    )
}

export default FileUpload