import { BASE_URL, GET, ONBOARDING_APPROVAL } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchonBoardingApprovalDetail = async (requestId) => {
  const token = await getAccessToken();
  const url = `${BASE_URL}${ONBOARDING_APPROVAL}/${requestId}`;
  return fetch(url, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);
};
