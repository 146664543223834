import { FETCH_ACCOUNT_ACCESS_APPROVAL_LIST } from "../actionTypes";
import { fetchAccountAccessApprovalList } from "../../../api/userManagement/accountAccessApproval.service";
const listRequest = ()=>({type: FETCH_ACCOUNT_ACCESS_APPROVAL_LIST.REQUEST})
const listFailure = (error)=>({type: FETCH_ACCOUNT_ACCESS_APPROVAL_LIST.FAILURE, error})
const listSuccess = (obj)=>({type: FETCH_ACCOUNT_ACCESS_APPROVAL_LIST.SUCCESS, obj})


export const getAccountAccessApprovalList = (next=null)=>{
    return (dispatch, getState)=>{        
        dispatch(listRequest())
        return fetchAccountAccessApprovalList(next)  
        .then(response => {
            // console.log("response",response)
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(listSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(listFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}