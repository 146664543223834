import {combineReducers} from "redux";
import totalInOutMessages from "./totalInOutMesg.reducer";
import totalINOutMessagesBySigNode from "./totalInoutBySigNode.reducer";
import finalTypeFinalDist from "./finalTypeandFinalDestination.reducer"
import SigNodeConnId from "./sigNodeandConnectionId.reducer"
export default combineReducers({
    totalInOutMessages,
    totalINOutMessagesBySigNode,
    finalTypeFinalDist,
    SigNodeConnId
})