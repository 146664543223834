import { Field, Form, Formik,useField,ErrorMessage } from "formik";
import React, { useId, useState } from "react";
import * as Yup from "yup";
import Asterisk from "../../../../../components/asterisk/Asterisk";
import { BackButton, Submit } from "../../../../../components/button/buttons";
import { TextInput } from "../../../../../components/input/inputs";
import IMAGES from "../../../../../assets/images/images";  
import { FormFieldLabel as Label,FormErrorLabel as ErrorLabel } from "../../../../../components/label/Labels";
import { Sidebar } from "../../../../../components/sidebar/Sidebar";
import styles from "./editmodule.module.css";
import { Alert } from "../../../../../components/alert/alerts";
import { USER_TYPE } from "../../../../../utils/constants/userConstants";
import * as DOMPurify from 'dompurify';
import { capitalizeWords } from "../../../../../utils/utils";

import {connect} from "react-redux";
import { getUser } from "../../../../../redux/actions/userManagement/userViewer.action";
import { createUser } from "../../../../../redux/actions/userManagement/newUser.action";
import { openAlertPopup } from "../../../../../redux/actions/Alert/alert.action";
import { Loader } from "../../../../../components/loader/Loader";
const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? styles.inputError
    : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
        {/* <Asterisk /> */}
      </div>
      <TextInput  {...field}  {...props} placeholder={placeholder}  style={inputStyle}   onChange={props.onChange} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const PhoneNumberTextField = ({ label, placeholder, ...props }) => {
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
      </div>
      <TextInput {...props} placeholder={placeholder} />
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};



const EditModule =({ data,userList,functionalRolesData, updateUserPermissions, openAlertPopup,...props })  => {


    let [isAlert, setIsAlert] = useState(false);
    let [isEditPermission, setIsEditPermission] = useState(false);
    let [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = useState({
      message: "",
      image: IMAGES.success,
    });
  
    const validationSchema = Yup.object().shape({
      functionalRoles: Yup.array().min(1),
    });
    let initialValues = {
      functionalRoles:
      data?.functionalRoles && data?.functionalRoles.length > 0 ? data?.functionalRoles : [],
    };
  
    const editPermission = () => {
      setIsEditPermission(!isEditPermission);
    };

    
  const {functionalRoles}=props;
  const [showAddUser, setShowAddUser] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);





  const getEmailDomain=(email)=>{
    return email?email.split("@")[1]:"";
  }
  
 

  const close = () => setShowAddUser(false);
  
  return (
    <div>
      <div
        onClick={() => setShowAddUser(true)}
        className={styles.addButton}
      >
        <img className={styles.editrole} src={IMAGES.editrole} />
        </div>

      {showAddUser && (
        <Sidebar
          isOpen={showAddUser}
          headerTxt="Edit User Permissions"
          onClose={close}
          className={styles.sidebar}
        >
      
        <div style={{ width: "100%" }}>
          <div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={async (values, { resetForm }) => {
                values.userType = data.userType;
                setShowLoader(true);
                updateUserPermissions(values, data.uid)
                  .then((result) => {
                    if (result.status) {
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: "User permissions updated successfully.",
                      //   image: IMAGES.success,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: "User permissions updated successfully!",
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    } else {
                      let error_msg = result?.error;
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: error_msg,
                      //   image: IMAGES.error,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: error_msg,
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    }
                  })
                  .catch((error) => {
                    setIsEditPermission(!isEditPermission);
                    // setMessage({
                    //   message: "Something went wrong.",
                    //   image: IMAGES.error,
                    // });
                    // setIsAlert(true);
                    openAlertPopup({
                      message: "Something went wrong.",
                      image: IMAGES.error
                    });
                    setShowLoader(false);
                  });
              }}
            >
              {({ isValid, dirty, submitForm }) => (
                <Form className={styles.form}>
                     <TextField
        name="name"
        label="Contact Name"
        placeholder="Enter Email"
        value={data?.displayName} // Set the value to the email of the user
        disabled
      />
                  <TextField
        name="email"
        label="Email Address" 
        placeholder="Enter Email"
        value={data?.email} // Set the value to the email of the user
        disabled
      />
                  <div className={styles.sectionContainer}>
                    <div className={styles.sectionTitle}>Modules Assigned</div>
                    {showLoader ? (
                      <div className={styles.loaderContainer}>
                        <Loader style={styles.loaderContainer} />
                      </div>
                    ) : isEditPermission ? (
                      <div>
                        <img
                          src={IMAGES.save}
                          className={styles.Icon}
                          onClick={submitForm}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={IMAGES.editIcon}
                          className={styles.Icon}
                          onClick={editPermission}
                        />
                      </div>
                    )}
                  </div>
                  <ul
                    style={{
                      listStyle: "none",
                      paddingInlineStart: 0,
                      display: "grid",
                      gap: 16,
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    {functionalRolesData?.map((role) => (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <fieldset disabled={!isEditPermission}>
                          <CheckBox
                            name="functionalRoles"
                            value={role.id}
                            label={role.name}
                          />
                        </fieldset>
                      </li>
                    ))}
                  <ErrorMessage name ="functionalRoles">
                    {(msg)=><ErrorLabel text={msg} />}
                  </ErrorMessage>
                  </ul>
                  <div></div>
                
                </Form>
              )}
            </Formik>
          </div>
        </div>
      
        </Sidebar>
      )}
   
    </div>
  );
};



const mapStateToProps = (state) => {
    return {
        functionalRoles:state.functionalRoles.roles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (payload) => {
            return dispatch(createUser(payload))
        },
        getUser: (type,custID,searchKey,next=null) => {
            return dispatch(getUser(type,custID,searchKey,next))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditModule);

