import { connect } from "react-redux";
import AccountAccessApprovaltDetail from "./accountAccessApprovalDetail";
import { extensionGrantAccess, getExtensionApprovalDetail } from "../../../../../../redux/actions/userManagement/extensionApprovalDetail.action";
import { openAlertPopup } from "../../../../../../redux/actions/Alert/alert.action";
const mapStateToProps = (state) => {

  return {
    requestDetails : state.onboardingRequest.extensionApprovalDetail.requestDetail,
    loading : state.onboardingRequest.extensionApprovalDetail.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getExtensionRequestDetail: (requestId)=>{
      return dispatch(getExtensionApprovalDetail(requestId))
    },
    extensionGrantAccess: (payload,requestId)=>{
      return dispatch(extensionGrantAccess(payload,requestId))
    },
    openAlertPopup: (payload) => {
      return dispatch(openAlertPopup(payload))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountAccessApprovaltDetail);
