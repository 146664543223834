import {OPEN_COMMENT_FORM,CLOSE_COMMENT_FORM, ADD_COMMENT_FORM, FETCH_COMMENTS, RESET_DEMAND_COMMENTS} from "../../actions/actionTypes"

const initialState = {
    isCommentFormOpen: false,
    comments: [],
    next:null,
    isLoading:false  
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_COMMENT_FORM:
            return { ...state, isCommentFormOpen:true};
        case CLOSE_COMMENT_FORM:
            return { ...state, isCommentFormOpen:false};
        case ADD_COMMENT_FORM:
            return {...state, isCommentFormOpen:false};
        case FETCH_COMMENTS.REQUEST:
            return {...state,isLoading:true}
        case FETCH_COMMENTS.FAILURE:
            return {...state,isLoading:false}
        case FETCH_COMMENTS.SUCCESS:  
        return {
            ...state,
            comments: action.obj.isLoadMore ? [...state.comments,...action.obj.data] : action.obj.data,
            next: action.obj.next,
            isLoading:false
          };
        case RESET_DEMAND_COMMENTS:
            return initialState;
        default:
            return state
    }
}

export default reducer;