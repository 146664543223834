import { FETCH_WEATHERMAP_FILTER_VALUES} from "../../actionTypes";
import { fetchWeatherMapFilterValues } from "../../../../api/dashboard/weatherMap/weatherMap.service";

const request = () => ({ type: FETCH_WEATHERMAP_FILTER_VALUES.REQUEST });
const failure = (error) => ({ type: FETCH_WEATHERMAP_FILTER_VALUES.FAILURE, error });
const success = (data) => ({ type: FETCH_WEATHERMAP_FILTER_VALUES.SUCCESS,data});


export const getWeatherMapFiltersValue = (cp_name) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchWeatherMapFilterValues(cp_name)
      .then((res) => {
        dispatch(success(res?.weatherMapFilters));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
