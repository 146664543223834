import {
    BASE_URL,
    POST,
    IAM_USERS
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import {getAccessToken} from "../../authActions/authActions";
  
  export const addUser = async(payload) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${IAM_USERS}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
  };

  
  