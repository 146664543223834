import { NEW_USER } from "../../actions/actionTypes";

const initialState = {
    isLoading:false,
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case NEW_USER.REQUEST:
            return {...state,isLoading:true}
        case NEW_USER.FAILURE:
            return {...state,isLoading:false}
        case NEW_USER.SUCCESS:           
            return {...state,isLoading:false}        
        default:
            return state
    }   
}

export default reducer;
