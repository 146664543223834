import React, { useState } from "react";
import styles from "./radiotabs.module.css";
import { SERVICE_REQUEST_OPTIONS } from "../../utils/constants/serviceConstants";


export const RadioTabs = (props) => {   
    const handleChange = (item) => {     
        props.setActiveRadio((item == SERVICE_REQUEST_OPTIONS.CREATE_NEW) ? false : true);
    }

    return (
        <div className={styles.serviceTabContainer}>
            {
                props.options.map((item, index) => {
                    return (
                        <div className={styles.radioContainer}>
                            <label className={`${styles.radioDiv} } `}><input
                                type='radio'
                                id={index}
                                value={item}
                                checked={props.defaultActiveTab == item}
                                name='serviceTab'
                                className={styles.serviceRadio}
                                onChange={() => handleChange(item)}
                            />
                                {item}</label>
                        </div>
                    )
                })
            }
        </div>
    )
}

