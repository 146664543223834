import { FORGOT_PASSWORD } from "../../actions/actionTypes";

const initialState = {
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FORGOT_PASSWORD.REQUEST:
            return {...state}
        case FORGOT_PASSWORD.FAILURE:
            return {...state}
        case FORGOT_PASSWORD.SUCCESS:           
            return {...state}        
        default:
            return state
    }   
}

export default reducer;
