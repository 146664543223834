import { connect } from "react-redux";
import OnboardingApprovalListViewer from "./onboardingApprovalListViewer";
import { getOnBordingApprovalList } from "../../../../../../redux/actions/userManagement/onBoardingApprovalList.action";

const mapStateToProps = (state) => {
  return {
    requestList : state.onboardingRequest.onBooardingApprovalList,
    myRights : state.myRights.myRights
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getOnBordingApprovalList: (requestId)=>{
      return dispatch(getOnBordingApprovalList(requestId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingApprovalListViewer);
