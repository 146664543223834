import { diameterJSON } from "../../../modules/dashboard/graphs/diameterJSON";
import {
    BASE_URL,
    GET,
    POST,
    RIGHTS_ME,
    DIAMETER_GRAPH1,
    DIAMETER_GRAPH2,
    DIA_SIGNODE_CONNECTIONID,
    DIA_FINALTYPE_FINALDIST
} from "../../constants";
import { statusParsing, jsonParsing } from "../../utils";
import { getAccessToken } from "../../../authActions/authActions";

/*export const fetchTotalINOutMessages = async (filter) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${DIAMETER_GRAPH1}`;
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth": `Bearer ${token}`
        },
    })
        .then(statusParsing)
        .then(jsonParsing);

};
export const fetchTotalINOutMessagesBySigNode = async (filter) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${DIAMETER_GRAPH2}`;
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth": `Bearer ${token}`
        },
    })
        .then(statusParsing)
        .then(jsonParsing);
};*/

//================POST CALL==================


export const fetchTotalINOutMessages = async(filter) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${DIAMETER_GRAPH1}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
      body: JSON.stringify(filter)
    })
    .then(statusParsing)
    .then(jsonParsing);
  };

  export const fetchTotalINOutMessagesBySigNode = async(filter,graphType=null) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${DIAMETER_GRAPH2}?graphType=${graphType}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
      body: JSON.stringify(filter)
    })
    .then(statusParsing)
    .then(jsonParsing);
  };

  export const fetchDiaSigNodeandConectionId = async(cp_name) => {
    const token = await getAccessToken();
    let resource = `${BASE_URL}${DIA_SIGNODE_CONNECTIONID}`;
    if(cp_name){
        resource=`${BASE_URL}${DIA_SIGNODE_CONNECTIONID}?cp_name=${cp_name}`
    }else{
        resource=`${BASE_URL}${DIA_SIGNODE_CONNECTIONID}`;
    }
    return fetch(resource, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);
  };

  export const fetchDiaFinalTypeandFinalDist = async(cp_name) => {
    const token = await getAccessToken();
    let resource = `${BASE_URL}${DIA_FINALTYPE_FINALDIST}`;
    if(cp_name){
        resource=`${BASE_URL}${DIA_FINALTYPE_FINALDIST}?cp_name=${cp_name}`
    }else{
        resource=`${BASE_URL}${DIA_FINALTYPE_FINALDIST}`;
    }
    return fetch(resource, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);
  };