import GenericInformation from "./GenericInformation";
import {connect} from "react-redux";
import { getGenericInfo } from "../../redux/actions/genericInformation/genericInformation.action";

const mapStateToProps = (state) => {
    return {
            pops:state.genericInformation.data,
            loadingPOPs:state.genericInformation.isLoading,
            myRights:state.myRights.myRights,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGenericInfo: (tadigID) => {
            return dispatch(getGenericInfo(tadigID))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GenericInformation);
