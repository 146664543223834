import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {AppContainer} from "../../components/Container/Containers";

import { app } from '../../configuration/gcp-identity-platform-config';
import { Loader } from "../../components/loader/Loader";
import { getAuth } from "firebase/auth";
import CAN from '../../casl/can';
import {Action , Resource} from '../../casl/constants';

const AuthGuard = ({ children,...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let [urlState, setURLState] = useState("");

  useEffect(()=>{
    if (location.pathname !== '/login' &&  location.pathname !== '/' &&  location.pathname !== '/invalid-user' && location.pathname !== '/inactive-user' && location.pathname !== '/verifyemail' && location.pathname !== '/enable-mfa') {
      setURLState(urlState = location.pathname);
    }
  },[])

  useEffect(() => {
   
    const hasRights = props.userDetails? true : false;
    const emailVerified = props.userDetails? getAuth(app).currentUser?.emailVerified : false;
    const isValidMFAUser = getAuth(app).currentUser?.providerData[0]?.providerId === "password" ? true : false
    const hasMFAEnabled = getAuth(app).currentUser?.reloadUserInfo?.mfaInfo ? true : false
    if(hasRights){      
          if(props.userDetails.status != "active") {
            navigate("/inactive-user");
          } 
          else if(emailVerified ===false){        
            navigate("/verifyemail");
          }
          else if (isValidMFAUser && hasMFAEnabled === false){
            navigate("/enable-mfa");
          }else if ((location.pathname == '/login' || location.pathname == '/' || location.pathname == '/invalid-user' || location.pathname == '/inactive-user' || location.pathname == '/verifyemail' || location.pathname == '/enable-mfa') && !urlState) {
                let _defaultPath=getDefaultlandingPgae(props.userDetails.permissions);
                navigate(_defaultPath);
                props.updateIsLoggedIn(true);
          }else if(urlState){
            navigate(urlState);
            setURLState("");
            props.updateIsLoggedIn(true);
          }else{
            navigate(location.pathname);
            props.updateIsLoggedIn(true);
          }
      }else if(props.userStatus=="404" || location.pathname === "/invalid-user") { //location.pathname === "/invalid-user" || 
        navigate("/invalid-user");
      }  
      else{
       // setURLState('');
        navigate("/login");
        props.updateIsLoggedIn(false);
      };
  }, [location.pathname,props.userDetails,props.isAppLoading]);


  const getDefaultlandingPgae = (permissions) => {  // to set default landing page if urlState is empty 
    if( CAN(Action.LIST, Resource.IPX_POP) ){
      return "/dashboard";
    }else if( CAN(Action.LIST, Resource.DEMANDS) || CAN(Action.LIST, Resource.LIVE_SERVICES) ){
      return "/connectivity";
    }else if( CAN(Action.GET, Resource.DIAMETER) ){
      return "/insights";
    }else if( CAN(Action.LIST, Resource.CUSTOMERS) || CAN(Action.LIST, Resource.RIGHTS) ){
      return "/user-management";
    }else if( CAN(Action.LIST, Resource.INCIDENTS) || CAN(Action.LIST, Resource.SERVICE_REQUESTS) ){
      return "/incident-management";
    }else if( CAN(Action.CREATE, Resource.QUERY) ){
      return "/contact-us";
    }
    return "invalid-user";
  };

  return(
    props.isAppLoading?
    (<AppContainer>
      <Loader/>
    </AppContainer>
    ):children
  ) 
};
//https://javascript.plainenglish.io/route-guards-in-react-5441b7d31334
export default AuthGuard;
