import { Graphs } from "../../../utils/constants/insightsConstants";
import * as Constants from "./constants";
import moment from 'moment';


const formatXAxisDate=(value,timegranularity)=>{
    let _xAxis="";
    if(timegranularity==Constants.MINUTE15 || timegranularity==Constants.MINUTE5){
        _xAxis=moment(value).format("MMM DD, h:mm A");
    }
    if(timegranularity==Constants.HOUR){
        _xAxis=moment(value).format("MMM DD, h A");
    }
    if(timegranularity==Constants.DAY){
        _xAxis=moment(value).format("MMM DD");
    }
    if(timegranularity==Constants.MONTH){
        _xAxis=moment(value).format("YYYY MMM");
    }
    return _xAxis;
}
export const getUniqueValues=(obj,key)=>{
    let unique = [...new Set(obj.map(item => item[key]))];
    return sortArray(unique);
}

export const processInOutData=(data,legendData,timegranularity)=>{
    let result={};
    let total_in_messages_array = [];
    let total_out_messages_array = [];
    let total_messages_array = [];
    let xAxis_array = [];  
    let totalCount = 0; 
    data.forEach((value, index, array) => {
        total_in_messages_array.push(value?.total_in_messages);
        total_out_messages_array.push(value?.total_out_messages);
        total_messages_array.push(value?.total_messages);
        let _value= typeof value?.timeX == 'object' ? value?.timeX?.value:value?.timeX // for granularity minute and hour type is object and for day and month type is string
        let _xAxis=formatXAxisDate(_value,timegranularity);
        xAxis_array.push(_xAxis);
    });    
    totalCount = (total_in_messages_array.length) + (total_out_messages_array.length) + (total_messages_array.length)

    let series = [
        {
            name: legendData[0],
            type: 'line',
            // stack: 'Total',
            data: total_in_messages_array
        },
        {
            name: legendData[1],
            type: 'line',
            // stack: 'Total',
            data: total_out_messages_array
        },
        {
            name: legendData[2],
            type: 'line',
            // stack: 'Total',
            data: total_messages_array
        },        
    ]
    result.xAxis_array=xAxis_array;
    result.series=series;
    result.totalCount=totalCount;
    return result;
}


export const processInOutDataByNode=(data,timegranularity,graphType)=>{
        let finalObject={};
        let legendData=[];
        let xAxisData=[]
        let seriesData=[];
        data.forEach((item) => {
            let _value= typeof item?.timeX == 'object' ? item?.timeX?.value:item?.timeX // for granularity minute and hour type is object and for day and month type is string
            xAxisData.push(_value);
        })
        let unique_xAxisData = [...new Set(xAxisData)];
        let _unique_xAxisData=[];
        unique_xAxisData.forEach((value=>{
           let _xAxis=formatXAxisDate(value,timegranularity);
            _unique_xAxisData.push(_xAxis);
        }))
        let objectByNode=Object.groupBy(data, ({ vrs_signalling_nodes }) => vrs_signalling_nodes);
        Object.keys(objectByNode).forEach((key) => {
            let total_in_key,total_out_key;
            if(graphType==Graphs.DIAMETER){
                 total_in_key=key+"-"+Constants.IN_MESSAGE;
                 total_out_key=key+"-"+Constants.OUT_MESSAGE;
            }else {
                 total_in_key=key+"-"+Constants.IN_MSUs;
                 total_out_key=key+"-"+Constants.OUT_MSUs;
            }
            legendData.push(total_in_key);
            legendData.push(total_out_key);
            finalObject[total_in_key]=[];
            finalObject[total_out_key]=[];
            objectByNode[key].forEach((item) => {
                finalObject[total_out_key].push(item.total_out_messages);
                finalObject[total_in_key].push(item.total_in_messages);
            });
         });

         Object.keys(finalObject).forEach((key) => {
            seriesData.push({
                name: key,
                type: 'line',
                // stack: 'Total',
                data: finalObject[key]
            })
         });
        let result={};
        result.xAxisData=_unique_xAxisData;
        result.legendData=legendData;
        result.seriesData=seriesData;
        return  result;
}  

export const processPeakInOutData=(data,timegranularity)=>{
    let finalObject={};
    let legendData=[];
    let xAxisData=[]
    let seriesData=[];
    data.forEach((item) => {
        xAxisData.push(item.report_date?.value+"T"+item?.hour.toString().padStart(2, "0")+":00:00");
    })
    let unique_xAxisData = [...new Set(xAxisData)];
    let _unique_xAxisData=[];
    unique_xAxisData.forEach((value=>{
        let _xAxis=formatXAxisDate(value,timegranularity);
        _unique_xAxisData.push(_xAxis);
    }))
    let objectByRouter=Object.groupBy(data, ({ target }) => target);
    Object.keys(objectByRouter).forEach((key) => {
        let max_peak_in_key=Constants.MAX_PEAK_IN+"-"+key;
        let max_peak_out_key=Constants.MAX_PEAK_OUT+"-"+key;
        legendData.push(max_peak_in_key);
        legendData.push(max_peak_out_key);
        finalObject[max_peak_in_key]=[];
        finalObject[max_peak_out_key]=[];
        objectByRouter[key].forEach((item) => {
            finalObject[max_peak_in_key].push(item.peak_in);
            finalObject[max_peak_out_key].push(item.peak_out);
        });
     });


     Object.keys(finalObject).forEach((key) => {
        seriesData.push({
            name: key,
            type: 'line',
            // stack: 'Total',
            data: finalObject[key]
        })
     });
    let result={};
    result.xAxisData=_unique_xAxisData;
    result.legendData=legendData;
    result.seriesData=seriesData;
    return  result;
} 

export const processVolumeInOutData=(data,timegranularity)=>{
    let finalObject={};
    let legendData=[];
    let xAxisData=[]
    let seriesData=[];
    data.forEach((item) => {
        xAxisData.push(item.report_date?.value+"T"+item?.hour.toString().padStart(2, "0")+":00:00");
    })
    let unique_xAxisData = [...new Set(xAxisData)];
    let _unique_xAxisData=[];
    unique_xAxisData.forEach((value=>{
        let _xAxis=formatXAxisDate(value,timegranularity);
        _unique_xAxisData.push(_xAxis);
    }))
    let objectByRouter=Object.groupBy(data, ({ target }) => target);
    Object.keys(objectByRouter).forEach((key) => {
        let max_volume_in_key=Constants.MAX_VOLUME_IN+"-"+key;
        let max_volume_out_key=Constants.MAX_VOLUME_OUT+"-"+key;
        legendData.push(max_volume_in_key);
        legendData.push(max_volume_out_key);
        finalObject[max_volume_in_key]=[];
        finalObject[max_volume_out_key]=[];
        objectByRouter[key].forEach((item) => {
            finalObject[max_volume_in_key].push(item.volume_in);
            finalObject[max_volume_out_key].push(item.volume_out);
        });
     });


     Object.keys(finalObject).forEach((key) => {
        seriesData.push({
            name: key,
            type: 'line',
            // stack: 'Total',
            data: finalObject[key]
        })
     });
    let result={};
    result.xAxisData=_unique_xAxisData;
    result.legendData=legendData;
    result.seriesData=seriesData;
    return  result;
} 

export const sortArray =(array)=>{
    const newArray=JSON.parse(JSON.stringify(array)); // No change in original array
    return newArray.sort((a,b)=>a?.toUpperCase().localeCompare(b?.toUpperCase()));  // Ascending alphabetical
}


