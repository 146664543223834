import {OPEN_COMMENT_FORM,CLOSE_COMMENT_FORM, ADD_COMMENT_FORM, FETCH_COMMENTS, RESET_DEMAND_COMMENTS} from "../actionTypes"
import { addCommentRequest, fetchComments } from "../../../api/newService/comment.service";

export const openCommentForm = (payload)=>{
    return {
        type: OPEN_COMMENT_FORM,
        payload
    }
}

export const closeCommentForm = (payload)=>{
    return {
        type: CLOSE_COMMENT_FORM,
        payload
    }
}

const request = () => ({ type: ADD_COMMENT_FORM.REQUEST })
const failure = (error) => ({ type: ADD_COMMENT_FORM.FAILURE, error })
const success = () => ({ type: ADD_COMMENT_FORM.SUCCESS })
const commentRequest = () => ({ type: FETCH_COMMENTS.REQUEST })
const commentfailure = (error) => ({ type: FETCH_COMMENTS.FAILURE, error })
const commentSuccess = (obj)=>({type: FETCH_COMMENTS.SUCCESS, obj})
const resetComments = () => ({ type: RESET_DEMAND_COMMENTS });

export const AddComment = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addCommentRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}




export const getComments = (requestId,next=null)=>{
    return (dispatch, getState)=>{        
        dispatch(commentRequest())
        return fetchComments(requestId,next)
        .then(response => { 
            let obj={
                "data":response?.comments,
                "next":response?.next,
                "isLoadMore": next?true:false
            }
            dispatch(commentSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(commentfailure(error?.message))
            return Promise.resolve(false)
        })
    }
}

export const clearDemandComments = () => {
    return (dispatch) => {
      dispatch(resetComments());
    };
  }