import AddComment from "./addComment";
import { connect } from "react-redux";
import { AddOnboardingComment, getOnBoardingComments } from "../../../../../../redux/actions/userManagement/onboardingAddComment.action";
import { openAlertPopup } from "../../../../../../redux/actions/Alert/alert.action";

const mapStateToProps = (state) => {
    return { 
      
    } 
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      AddComment: (payload)=>{
           return dispatch(AddOnboardingComment(payload))
      },
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      },
      getOnBoardingComments:(requestId,next=null)=>{
        return dispatch(getOnBoardingComments(requestId,next))
      },
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddComment);


