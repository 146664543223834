import { BASE_URL, EXTENSION_APPROVAL_LIST, GET, PATCH } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchExtensionApprovalDetail = async (requestId) => {
  const token = await getAccessToken();
  const url = `${BASE_URL}${EXTENSION_APPROVAL_LIST}/${requestId}`;
  return fetch(url, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);
};


export const extensionApprovalGrantAccess = async (payload,requestId) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${EXTENSION_APPROVAL_LIST}/${requestId}`;
  return fetch(resource, {
    method: PATCH,
    headers: {
      "Content-Type": "application/json",
      "userauth": `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  })
    .then(statusParsing)
    .then(jsonParsing);
};