import { createMongoAbility, AbilityBuilder } from "@casl/ability";

const ability = new createMongoAbility(); // refrence https://stackoverflow.com/questions/76356514/using-casl-v6-with-nestjs

export default (action, subject) => {
  return ability.can(action, subject);
};

export const defineRulesForUser = (permissions) => {
  const _permissions = permissions;
  const { can, rules } = new AbilityBuilder();
  if (_permissions) {
    _permissions.forEach((p) => {
      let per = p.split(".");
      can(per[2], per[1]);
    });
  }
  ability.update(rules);
};
