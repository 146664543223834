import NewRequestViewer from "./NewRequestViewer";
import {connect} from "react-redux";
import { getServicesList, downloadServicesList } from "../../../../redux/actions/newService/servicesList.actions";
import { openCommentForm,closeCommentForm } from "../../../../redux/actions/newService/comment.actions";
import { openServiceStatusForm, closeServiceStatusForm } from "../../../../redux/actions/newService/serviceStatus.actions";
import { getCustomers } from "../../../../redux/actions/newService/customers.action";
import { getServices } from "../../../../redux/actions/newService/customerService.action";

const mapStateToProps = (state) => {
    return {
      servicesList: state.servicesList.data,
      isLoading: state.servicesList.isLoading,  
      isCommentFormOpen:state.newService.comment.isCommentFormOpen, 
      isServiceStatusFormOpen:state.newService.serviceStatus.isServiceStatusFormOpen,
      myRights:state.myRights.myRights,
      next:state.servicesList.next,  
      customers:state.newService.customers.customers.customers,
      services:state.newService.services.services.services,
    }
  }

const mapDispatchToProps = (dispatch)=>{
    return {
        getServicesList: (filter = '', searchKey = '',next=null)=>{
            return dispatch(getServicesList(filter, searchKey,next))
        },
        downloadServicesList: (filter = '', searchKey = '',next=null)=>{
            return dispatch(downloadServicesList(filter, searchKey,next))
        },
        openCommentForm : () => {
            return dispatch(openCommentForm())
        },
        closeCommentForm: () => {
            return dispatch(closeCommentForm())
        },
        openServiceStatusForm : () => {
            return dispatch(openServiceStatusForm())
        },
        closeServiceStatusForm: () => {
            return dispatch(closeServiceStatusForm())
        },
        getCustomers: () => {
            return dispatch(getCustomers())
        },
        getServices: ()=>{
            return dispatch(getServices())
        }  
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRequestViewer);


