import styles from "./labels.module.css"

export const FormFieldLabel = ({text, optional,style=null, ...rest})=>{
    return (
        <label className={`${styles.label} ${style}`} {...rest}>{optional? `${text} (Optional)`: text}</label>
    );
}
export const FormFieldValue = ({text, optional, Component ="li"})=>{
    return (
        <Component className={styles.value}>{optional? `${text} (Optional)`: text}</Component>
    );
}

export const FormErrorLabel = ({text})=>{
    return (
        <label className={styles.error}>{text}</label>
    );
}