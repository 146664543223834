import {OPEN_SERVICE_STATUS_FORM,CLOSE_SERVICE_STATUS_FORM} from "../../actions/actionTypes"

const initialState = {
    isServiceStatusFormOpen: false,       
};

const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case OPEN_SERVICE_STATUS_FORM:
            return { ...state, isServiceStatusFormOpen:true};
        case CLOSE_SERVICE_STATUS_FORM:
            return { ...state, isServiceStatusFormOpen:false};             
        default:
            return state
    }
}

export default reducer;