import {
  BASE_URL, 
  POST,  
  COMMENT,
  GET
} from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const addCommentRequest = async (payload) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${COMMENT}`;
  return fetch(resource, {
    method: POST,
    headers: {
      "Content-Type": "application/json",
      "userauth": `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  })
    .then(statusParsing)
    .then(jsonParsing);
};


export const fetchComments = async (requestId,next) => {
  const token = await getAccessToken();    
  let url = `${BASE_URL}${COMMENT}/${requestId}`;   
  if(next){
    url=`${url}?next=${next}`
  }
  return fetch(url, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);   

};