import {SET_INCIDENT_PREVIEW_MODE} from "../actionTypes"
import { SUBMIT_INCIDENT_REQUEST} from "../actionTypes";
import { addIncidentRequest } from "../../../api/incidentManagement/raiseIncident.service";


export const setIncidentPreviewMode= (payload)=>{   
    return {
        type: SET_INCIDENT_PREVIEW_MODE,             
        payload        
    }
}

const request = () => ({ type: SUBMIT_INCIDENT_REQUEST.REQUEST });
const failure = (error) => ({ type: SUBMIT_INCIDENT_REQUEST.FAILURE, error });
const success = () => ({ type: SUBMIT_INCIDENT_REQUEST.SUCCESS });

export const SubmitIncident = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addIncidentRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      });
  };
};