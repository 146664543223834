import { SET_ACTIVE_PRIMARY_TAB, SET_ACTIVE_SECONDARY_TAB } from "../../actions/actionTypes";
import { UserManagementTab, My_Request_SUBTAB } from "../../../utils/constants/userManagementConstant"

const initialState = {
    activePrimaryTab: UserManagementTab.CUSTOMER,
    activeSecondaryTab: My_Request_SUBTAB.ON_BOARDING_REQUEST,
};

const tabReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_PRIMARY_TAB:
            return {
                ...state,
                activePrimaryTab: action.tab,
            };
        case SET_ACTIVE_SECONDARY_TAB:
            return {
                ...state,
                activeSecondaryTab: action.tab,
            };
        default:
            return state;
    }
};

export default tabReducer;
