import {UPLOAD_ATTACHMENT,DOWNLOADS,INCIDENT_DOWNLOADS, SERVICE_REQUEST_DOWNLOADS} from "../actionTypes"
import { uploadAttachments,downloadFile,incidentDownloadFile, serviceRequestDownloadFile } from "../../../api/uploadAttachment/attachment.service";

const uploadAttachmentRequest = ()=>({type: UPLOAD_ATTACHMENT.REQUEST});
const uploadAttachmentFailure = (error)=>({type: UPLOAD_ATTACHMENT.FAILURE, error})
const uploadAttachmentSuccess = ()=>({type: UPLOAD_ATTACHMENT.SUCCESS})


export const uploadAttachment = (payload)=>{
    return (dispatch, getState)=>{
        dispatch(uploadAttachmentRequest())
        return uploadAttachments(payload)
        .then(response => {
            dispatch(uploadAttachmentSuccess())
            return Promise.resolve({res:response,status:true})
        })
        .catch(error => {
            dispatch(uploadAttachmentFailure(error?.message))
            return Promise.resolve({error:error?.message,status:false})
        })
    }
}



const downloadRequest = () => ({ type: DOWNLOADS.REQUEST })
const  downloadfailure = (error) => ({ type: DOWNLOADS.FAILURE, error })
const  downloadSuccess = (data)=>({type: DOWNLOADS.SUCCESS, data})


export const downloadFiles = (originalFileName,uniqueFileName)=>{
    return (dispatch, getState)=>{        
        dispatch( downloadRequest())
        return downloadFile(originalFileName,uniqueFileName)
        .then(response => { 
            dispatch( downloadSuccess(response))
            return Promise.resolve({file:response,status:true})
        })
        .catch(errorMessage => {
            dispatch(downloadfailure(errorMessage))
            return Promise.resolve({error:errorMessage,status:false})
        })
    }
}

const incidentDownloadRequest = () => ({ type: INCIDENT_DOWNLOADS.REQUEST })
const  incidentDownloadfailure = (error) => ({ type: INCIDENT_DOWNLOADS.FAILURE, error })
const  incidentDownloadSuccess = (data)=>({type: INCIDENT_DOWNLOADS.SUCCESS, data})


export const incidentDownloadFiles = (originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId)=>{
    return (dispatch, getState)=>{        
        dispatch( incidentDownloadRequest())
        return incidentDownloadFile(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId)
        .then(response => { 
            dispatch( incidentDownloadSuccess(response))
            return Promise.resolve({file:response,status:true})
        })
        .catch(errorMessage => {
            dispatch(incidentDownloadfailure(errorMessage))
            return Promise.resolve({error:errorMessage,status:false})
        })
    }
}


const serviceRequestDownloadRequest = () => ({ type: SERVICE_REQUEST_DOWNLOADS.REQUEST })
const  serviceRequestDownloadfailure = (error) => ({ type: SERVICE_REQUEST_DOWNLOADS.FAILURE, error })
const  serviceRequestDownloadSuccess = (data)=>({type: SERVICE_REQUEST_DOWNLOADS.SUCCESS, data})


export const serviceRequestDownloadFiles = (originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId)=>{
    return (dispatch, getState)=>{        
        dispatch( serviceRequestDownloadRequest())
        return serviceRequestDownloadFile(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId)
        .then(response => { 
            dispatch( serviceRequestDownloadSuccess(response))
            return Promise.resolve({file:response,status:true})
        })
        .catch(errorMessage => {
            dispatch(serviceRequestDownloadfailure(errorMessage))
            return Promise.resolve({error:errorMessage,status:false})
        })
    }
}