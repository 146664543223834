import {SERVICE_ADD_COMMENT_FORM,FETCH_SERVICE_COMMENTS,RESET_SERVICE_COMMENTS} from "../actionTypes"
import { addServiceCommentRequest, fetchServiceComments  } from "../../../api/incidentManagement/serviceComment.service"

const request = () => ({ type: SERVICE_ADD_COMMENT_FORM.REQUEST })
const failure = (error) => ({ type: SERVICE_ADD_COMMENT_FORM.FAILURE, error })
const success = () => ({ type: SERVICE_ADD_COMMENT_FORM.SUCCESS })

const commentRequest = () => ({ type: FETCH_SERVICE_COMMENTS.REQUEST })
const commentfailure = (error) => ({ type: FETCH_SERVICE_COMMENTS.FAILURE, error })
const commentSuccess = (obj)=>({type: FETCH_SERVICE_COMMENTS.SUCCESS, obj})
const resetComments = () => ({ type: RESET_SERVICE_COMMENTS });

export const ServiceAddComment = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addServiceCommentRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}


export const getServiceComments = (requestId,next=null)=>{
  return (dispatch, getState)=>{        
      dispatch(commentRequest())
      return fetchServiceComments(requestId,next)
      .then(response => {
          let obj={
              "data":response?.data,
              "next":response?.next,
              "isLoadMore": next?true:false
          }
          dispatch(commentSuccess(obj))
          return Promise.resolve(true)
      })
      .catch(error => {
          dispatch(commentfailure(error?.message))
          return Promise.resolve(false)
      })
  }
}

export const clearServiceComments = () => {
  return (dispatch) => {
    dispatch(resetComments());
  };
}