import { FETCH_ACCOUNT_ACCESS_REQUEST_LIST } from "../actionTypes";
import { fetchAccountAccessRequestList } from "../../../api/userManagement/accountAccessRequestList.service";

const listRequest = ()=>({type: FETCH_ACCOUNT_ACCESS_REQUEST_LIST.REQUEST})
const listFailure = (error)=>({type: FETCH_ACCOUNT_ACCESS_REQUEST_LIST.FAILURE, error})
const listSuccess = (obj)=>({type: FETCH_ACCOUNT_ACCESS_REQUEST_LIST.SUCCESS, obj})


export const getAccountAccessRequestList = (next=null)=>{
    return (dispatch, getState)=>{        
        dispatch(listRequest())
        return fetchAccountAccessRequestList(next)
        .then(response => {      
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(listSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(listFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}