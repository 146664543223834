import { FETCH_INCIDENT_COMMENTS, INCIDENT_ADD_COMMENT_FORM,RESET_INCIDENT_COMMENTS } from "../../actions/actionTypes";

const initialState = {
    isCommentFormOpen: false,
    comments: [],
    next: null,
    isLoading: false  
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INCIDENT_ADD_COMMENT_FORM:
            return { ...state, isCommentFormOpen: false };
        case FETCH_INCIDENT_COMMENTS.REQUEST:
            return { ...state, isLoading: true }
        case FETCH_INCIDENT_COMMENTS.FAILURE:
            return { ...state, isLoading: false };
        case FETCH_INCIDENT_COMMENTS.SUCCESS:
            return {
                ...state,
                comments: action.obj.isLoadMore ? [...state.comments, ...action.obj.data] : action.obj.data,
                next: action.obj.next,
                isLoading: false
            };
        case RESET_INCIDENT_COMMENTS:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
