import {combineReducers} from "redux";
import service from "./service.reducer"
import submitServiceRequst from "./submitServiceRequest.reducer"
import locations from "./locations.reducer"
import customers from "./customers.reducer"
import serviceDetail from "./serviceDetail.reducer"
import comment from "./comment.reducer"
import serviceStatus from "./serviceStatus.reducer"
import services from "./customerService.reducer"
export default combineReducers({
    service,
    submitServiceRequst,
    locations,
    customers,
    serviceDetail,
    comment,
    serviceStatus,
    services
})