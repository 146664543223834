import {OPEN_SERVICE_STATUS_FORM,CLOSE_SERVICE_STATUS_FORM} from "../actionTypes"

export const openServiceStatusForm = (payload)=>{
    return {
        type: OPEN_SERVICE_STATUS_FORM,
        payload
    }
}

export const closeServiceStatusForm = (payload)=>{
    return {
        type: CLOSE_SERVICE_STATUS_FORM,
        payload
    }
}

// const request = () => ({ type: ADD_COMMENT_FORM.REQUEST })
// const failure = (error) => ({ type: ADD_COMMENT_FORM.FAILURE, error })
// const success = () => ({ type: ADD_COMMENT_FORM.SUCCESS })


// export const AddComment = (payload) => {
//   return (dispatch, getState) => {
//     dispatch(request());
//     return addCommentRequest(payload)
//       .then((res) => {
//         dispatch(success(res));
//         return Promise.resolve(true);

//       })
//       .catch((error) => {
//         dispatch(failure(error?.message));
//         return Promise.resolve(false);
//       })
//   }
// }


   
