import {
    BASE_URL, 
    POST,  
    GET,
    CUSTOMER_ONBOARDING_REQUEST,
    ACCOUNT_MANAGER_LIST,
    PRIMARY_CONTACT_LIST,
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";
  
  export const submitOnboardingRequest = async (payload) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${CUSTOMER_ONBOARDING_REQUEST}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth": `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
      .then(statusParsing)
      .then(jsonParsing);
  };

export const fetchAccountManagerList = async (requestId) => {
  const token = await getAccessToken();
  const url = `${BASE_URL}${ACCOUNT_MANAGER_LIST}`;
  return fetch(url, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);
};



export const fetchPrimaryContactList = async (requestId) => {
    const token = await getAccessToken();
    const url = `${BASE_URL}${PRIMARY_CONTACT_LIST}/${requestId}`;
    return fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userauth" : `bearer ${token}`
        },
      })
      .then(statusParsing)
      .then(jsonParsing);
  };
  