import { connect } from "react-redux";
import AccountAccessRequestDetail from "./accountAccessRequestDetail";
import { getExtensionRequestDetail } from "../../../../../../redux/actions/userManagement/extensionRequestDetail.action";
const mapStateToProps = (state) => {
// console.log("requestDetails", state.onboardingRequest);

  return {
    requestDetails : state.onboardingRequest.extensionRequestDetail.requestDetail,
    loading : state.onboardingRequest.extensionRequestDetail.loading,
  }; 
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExtensionRequestDetail: (requestId)=>{
      return dispatch(getExtensionRequestDetail(requestId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountAccessRequestDetail);
