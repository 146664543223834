import { FETCH_DIA_SIGNODE_CONNECTIONID } from "../../../actions/actionTypes";

const initialState = {
    isLoading:false,
    sigNode_connId:[]
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_DIA_SIGNODE_CONNECTIONID.REQUEST:
            return {...state,sigNode_connId:[], isLoading:true}
        case FETCH_DIA_SIGNODE_CONNECTIONID.FAILURE:
            return {...state,sigNode_connId:[], isLoading:false}
        case FETCH_DIA_SIGNODE_CONNECTIONID.SUCCESS:           
            return {...state, sigNode_connId: [...action.data],isLoading:false}        
        default:
            return state
    }   
}

export default reducer;
