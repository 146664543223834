import ValidateEmail from "./validateEmail";
import { verifyEmailAddress } from "../../redux/actions/userManagement/verifyEmail.action";
import {connect} from "react-redux";
import { openAlertPopup } from "../../redux/actions/Alert/alert.action";
const mapStateToProps = (state)=>{
    return {
      myRights:state.myRights.myRights,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        verifyEmailAddress: () => {
          return dispatch(verifyEmailAddress())
          },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmail);


