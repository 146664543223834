import React from 'react';
import styles from './dynamicTable.module.css';
import { USER_TYPE } from '../../utils/constants/userConstants';

const DynamicTable = ({ headers, data, ...props }) => {
  return (
    <div className={styles.border}>
    <div className={styles.table} {...props}>
    <div className={styles.thead}>
        <div className={styles.tr}>
          {headers?.map((header, index) => (
            <div
              key={index}
              className={`${styles.th} ${index===headers.length-1?"":styles.tableheader} ${
                (index === 1 && props.userType!==USER_TYPE.CUSTOMER) ? styles.thFlex2 : ''
              }`}
            >
              {header}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.tbody}>
      {data?.map((row, rowIndex) => (
        <div key={rowIndex} className={styles.tr}>
          {headers?.map((header, colIndex) => (
            <div
              key={colIndex}
              className={`${styles.td} ${colIndex===headers.length-1?"":styles.tableheader} ${
                (colIndex === 1 && props.userType!==USER_TYPE.CUSTOMER) ? styles.tdFlex2 : ''
              }`}
            >
              {row[header]}
            </div>
          ))}
        </div>
      ))}
    </div>
    </div>
    </div>
  );
};

export default DynamicTable;