import { updateServicesStatus } from "../../../api/newService/newService.service"
import {CLOSE_NEWSERVICE_FORM,OPEN_NEWSERVICE_FORM,UPDATE_SERVICE_STATUS,SET_ACTIVE_TAB_SERVICES, SET_IS_AMEND_MODE} from "../actionTypes"


export const openNewServiceForm= (payload)=>{   
    return {
        type: OPEN_NEWSERVICE_FORM,             
        payload
        
    }
}

export const closeNewServiceForm= (payload)=>{
    return {
        type: CLOSE_NEWSERVICE_FORM,
        payload
    }
}
export const setServiceActiveTab= (tab)=>{
    return {
        type: SET_ACTIVE_TAB_SERVICES,
        tab
    }
}

export const setIsAmendMode = (isAmendMode)=>{
    return {
        type: SET_IS_AMEND_MODE,
        isAmendMode
    }
}




const updateRequest = ()=>({type: UPDATE_SERVICE_STATUS.REQUEST})
const updateFailure = (error)=>({type: UPDATE_SERVICE_STATUS.FAILURE, error})
const updateSuccess = ()=>({type: UPDATE_SERVICE_STATUS.SUCCESS})

export const updateServiceStatus = (payload, id)=>{
    return (dispatch, getState)=>{
        dispatch(updateRequest())
        return updateServicesStatus(payload, id)
        .then(res => {
            dispatch(updateSuccess(res))
            // return Promise.resolve(true)
            return Promise.resolve({res:res,status:true});
        })
        .catch(error => {
            dispatch(updateFailure(error?.message))
            // return Promise.resolve(false)
            return Promise.resolve({error:error?.message,status:false});
        })
    }
}
