import React  from "react";
import {
  LoginChildContainer,
  LoginContainer,
} from "../../components/Container/Containers";
import styles from "./InvalidUser.module.css";
import IMAGES from "../../assets/images/images";
import { LinkButton } from "../../components/button/buttons";
import { logOut } from "../../authActions/authActions";
import { useNavigate } from "react-router-dom";


const InvalidUser = () => {
  const navigate = useNavigate();

  const Logo = () => {
    return (
      <div className={styles.headerLogo}>
        <img className={styles.logo} src={IMAGES.logo} alt="Logo" />
      </div>
    );
  };

  const Header = () => {
    return <div className={styles.headerText}>IPXplore</div>;
  };

  return (
    <>
      <LoginContainer>
        <LoginChildContainer>
          <Logo />
          <Header />
          <div className={styles.invalidUserContainer}>
            You do not have access to the Portal. Please contact utsav.burman@vodafone.com
          </div>
          <div className={styles.linkbtn}>
          <LinkButton className={styles.resetLink}
                    title={"Click here to sign in with a different user"}
                    onClick={() => {
                      logOut().then((res)=>{
                          navigate('/login');
                      }).catch((error)=>{

                      })
                      //window.location.reload(); //to navigate to login page
                    }}
                />
          </div>
        </LoginChildContainer>
      </LoginContainer>
    </>
  );
};

export default InvalidUser;
