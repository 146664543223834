import React from "react";
import styles from "./invalid.module.css";
import IMAGES from "../../assets/images/images";

const Invalid = ({ children , logo, headerText }) => {
  const Logo = () => {
    return (
      <div className={styles.headerLogo}>
        <img className={styles.logo} src={logo} alt="Logo" />
      </div>
    );
  };

  const Header = () => {
    return <div className={styles.headerText}>{headerText}</div>;
  };

  return (  
      <div className={styles.Container}>
        <div className={styles.SubContainer}>
          <Logo />
          <Header />
          <div className={styles.messageContainer}>
            {children}
          </div>
        </div>
      </div>
  );
};

export default Invalid;
