import { FETCH_SERVICE_COMMENTS,RESET_SERVICE_COMMENTS } from "../../actions/actionTypes";

const initialState = {
    comments: [],
    next: null,
    isLoading: false  
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICE_COMMENTS.REQUEST:
            return { ...state, isLoading: true }
        case FETCH_SERVICE_COMMENTS.FAILURE:
            return { ...state, isLoading: false };
        case FETCH_SERVICE_COMMENTS.SUCCESS:
            return {
                ...state,
                comments: action.obj.isLoadMore ? [...state.comments, ...action.obj.data] : action.obj.data,
                next: action.obj.next,
                isLoading: false
            };
        case RESET_SERVICE_COMMENTS:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
