import React, { useState,useEffect } from 'react'
import { Breadcrumbs } from '../../../../../../components/breadcrumbs/Breadcrumbs';
import { Banner } from '../../../../../../components/banner/Banners';
import IMAGES from '../../../../../../assets/images/images';
import { useParams } from 'react-router-dom';
import { ChildContainer, Container } from '../../../../../../components/Container/Containers';
import styles from './accountAccessApprovalDetail.module.css';
import { useNavigate } from 'react-router-dom';
import  AddComment  from '../addComment/addComment.container';
import { Loader } from '../../../../../../components/loader/Loader';
import { formatDate } from '../../../../../../utils/arrayUtils';
import SectionGuard from '../../../../../../hoc/guards/SectionGuard';
import CAN from '../../../../../../casl/can';
import { Action, Resource } from '../../../../../../casl/constants';
import { EXTENSTION_STATUS } from '../../../../../../utils/constants/userManagementConstant';

const AccountAccessApprovalList = ({ data}) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
    
  const navigate = useNavigate();

  const back = (requestId) => {
    navigate(`/user-management`);
  }

    return (
     <div>
         <div className={styles.back}>
         <div>  <span><img className={styles.backbutton} src={IMAGES.backarrow} onClick={()=>back()} /></span><span className={styles.id}>{data?.requestId}</span>
         <span className={`${styles.status} ${styles[data?.requestStatus?.value]}`}>{data?.requestStatus?.label}</span></div>
      </div>
      <div className={styles.container}>
      <div className={styles.requestDetail}>Request Details</div>
      <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Requested By</div>
                <div className={styles.value}>{data?.createdBy?.name}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Requested Date</div>
                <div className={styles.value}>{formatDate(data?.createdAt)}</div>
             </div>
             {data?.requestStatus?.value===EXTENSTION_STATUS.APPROVED &&(
              <>
                <div className={styles.list}>
                 <div className={styles.label}>Approved By</div>
                 <div className={styles.value}>{data?.approvedBy?.name}</div>
                </div>
                <div className={styles.list}>
                 <div className={styles.label}>Approved Date</div>
                 <div className={styles.value}>{formatDate(data?.approvedAt)}</div>
                </div>
              </>
             )}
             </div>
     </div>
     </div>
    )
  };

  const AccountAccessDetail = ({ data,onFileDownload,loading,...props }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    

    return (
     <div>
      
      <div className={styles.container}>
      <div className={styles.requestDetail}>Customer Details</div>
      <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Organisation Leagal Name</div>
                <div className={styles.value}>{data?.displayName}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Service Type</div>
                <div className={styles.value}>{data?.serviceType?.label}</div>
             </div>
            {data?.cpName!==null&&(
             <div className={styles.list}>
               <div className={styles.label}>TADIG Code</div>
               <div className={styles.value}>{data?.cpName}</div>
             </div>
            )}
             </div>
     </div>
     </div>
    )
  };
 

  const CommentDetail = ({data, onFileDownload}) => {
   
    return (
     <div  className={styles.comment} >
     No Data Found
     </div>
    );
  };


const AccountAccessApprovalDetail = (props)=> {
  // console.log("props",props)
  const [isSubmit,setIsSubmit] = useState(false)
  let { requestId } = useParams();
  useEffect(() => {
    props.getExtensionRequestDetail(requestId);
    window.scrollTo(0,0);
  }, [requestId]);


  const grantAccess = (data) =>{
    setIsSubmit(true);
    // console.log("grantAccess",data)
    const payload ={
      serviceType: data.serviceType.value,
      displayName: data.displayName
    }
    console.log("payload",payload)
    // return
    props.extensionGrantAccess(payload,data.id).then((result) => {          
      if (result.status) {
        props.openAlertPopup({
          message: "Access granted successfully!",
          image: IMAGES.success
        });
        setIsSubmit(false);   
        let next=null;     
        props.getExtensionRequestDetail(data.id);
      } else {
        let error_msg = result?.error?.message ?? "Something went wrong.";
        props.openAlertPopup({
          message: error_msg,
          image: IMAGES.error
        });
        setIsSubmit(false);
      }
    })
      .catch((error) => {
        props.openAlertPopup({
          message: "Something went wrong.",
          image: IMAGES.error
        });
        setIsSubmit(false);     
        // close()
    });
  }
  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Extension Access Approval"}></Banner>
      <ChildContainer>
      {props.loading?
           <div className={styles.comentsLoader}>
           <Loader />
         </div>:
         <>
          <AccountAccessApprovalList data={props.requestDetails}/>
          <AccountAccessDetail data={props.requestDetails}/>
        </>
      }
        {props.requestDetails?.requestStatus?.value===EXTENSTION_STATUS.PENDING && !props.loading&&(
           <SectionGuard canAccess={CAN(Action.APPROVER, Resource.CUSTOMER_ACCESSR_REQUEST)}>
            <div className={styles.grantAccess}>
              {!isSubmit ? <button className={styles.commonbtncss} onClick={(e)=>grantAccess(props.requestDetails)}>Grant Access</button>:
              <div className={`${styles.btnLoaderContainer} `} ><Loader style={styles.btnLoader}/></div>}
            </div>
            </SectionGuard>
        )}
                 {/* <div  className={styles.commentsection}>
                  <div className={styles.comtext}> Comment History</div>
                  <div> <AddComment source={'incDetail'}/></div>
                 </div>
                 <div className={styles.alert}>Request has been created and assigned to Commercial Manager.  </div>
        <CommentDetail/> */}
      </ChildContainer>
    </Container>
  )
};

export default AccountAccessApprovalDetail;
