import React from "react";
import styles from "./noAccess.module.css";
import IMAGES from "../../assets/images/images";

const NoAccess = ({ children }) => {
  const Logo = () => {
    return (
      <div className={styles.headerLogo}>
        <img className={styles.logo} src={IMAGES.logo} alt="Logo" />
      </div>
    );
  };

  const Header = () => {
    return <div className={styles.headerText}>IPXplore</div>;
  };

  return (  
      <div className={styles.Container}>
        <div className={styles.SubContainer}>
          <Logo />
          <Header />
          <div className={styles.messageContainer}>
            {children}
          </div>
        </div>
      </div>
  );
};

export default NoAccess;
