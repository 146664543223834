import AddTicket from "./addTicket";
import {connect} from "react-redux";
import {openAlertPopup} from "../../../../redux/actions/Alert/alert.action";
import {setIncidentPreviewMode} from "../../../../redux/actions/incidentManagement/raiseIncident.action";

const mapStateToProps = (state) => { 
    return {
              isIncidentPreviewModeOn:state.IncidentManagement.isIncidentPreviewModeOn,
              myRights : state.myRights.myRights
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        },
        setIncidentPreviewMode: (payload) => {
            return dispatch(setIncidentPreviewMode(payload))
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTicket);
