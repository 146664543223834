import { ADD_NEW_ACCOUNT_MAMAGER} from "../actionTypes";
import { addAM } from "../../../api/userManagement/newAccountManager.service";

const request = () => ({ type: ADD_NEW_ACCOUNT_MAMAGER.REQUEST });
const failure = (error) => ({ type: ADD_NEW_ACCOUNT_MAMAGER.FAILURE, error });
const success = (data) => ({ type: ADD_NEW_ACCOUNT_MAMAGER.SUCCESS,data});



export const AddNewAccountManager = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addAM(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      });
  };
};
