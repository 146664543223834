import { FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER,UPDATE_WEATHERMAP_VOLUME_INOUT_BY_ROUTER_FILTER } from "../../../actions/actionTypes";

const initialState = {
    isLoading:false,
    filters:{},
    data:[]
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER.REQUEST:
            return {...state,data:[], isLoading:true}
        case FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER.FAILURE:
            return {...state,data:[], isLoading:false}
        case FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER.SUCCESS:           
            return {...state, data: [...action.data],isLoading:false} 
        case UPDATE_WEATHERMAP_VOLUME_INOUT_BY_ROUTER_FILTER:   
        return {...state, filters:{...state.filters,...action.filters}}         
        default:
            return state
    }   
}

export default reducer;
