import { SUBMIT_ONBOARDING_REQUEST,FETCH_ACCOUNT_MANAGER_LIST,FETCH_PRIMARY_CONTACT_LIST} from "../actionTypes";
import { fetchAccountManagerList, submitOnboardingRequest,fetchPrimaryContactList } from "../../../api/userManagement/submitOnboardingRequest";

const request = () => ({ type: SUBMIT_ONBOARDING_REQUEST.REQUEST });
const failure = (error) => ({ type: SUBMIT_ONBOARDING_REQUEST.FAILURE, error });
const success = () => ({ type: SUBMIT_ONBOARDING_REQUEST.SUCCESS });

export const SubmitRequst = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return submitOnboardingRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      });
  };
};

const requestListRequest = ()=>({type: FETCH_ACCOUNT_MANAGER_LIST.REQUEST})
const requestListFailure = (error)=>({type: FETCH_ACCOUNT_MANAGER_LIST.FAILURE, error})
const requestListSuccess = (obj)=>({type: FETCH_ACCOUNT_MANAGER_LIST.SUCCESS, obj})

export const getAccountManagerList = (next=null)=>{
    return (dispatch, getState)=>{        
        dispatch(requestListRequest()) 
        return fetchAccountManagerList(next)  
        .then(response => {            
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(requestListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(requestListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}

const ListRequest = ()=>({type: FETCH_PRIMARY_CONTACT_LIST.REQUEST})
const ListFailure = (error)=>({type: FETCH_PRIMARY_CONTACT_LIST.FAILURE, error})
const ListSuccess = (obj)=>({type: FETCH_PRIMARY_CONTACT_LIST.SUCCESS, obj})

export const getPrimaryContactList = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(ListRequest()) 
        return fetchPrimaryContactList(requestId)  
        .then(response => {            
            let obj={
              'data': [...response.data, {displayName: "Other",  email: "other",}],   // Push last option as "other" in the primary contact list  
                //"isLoadMore": next?true:false
            }    
            dispatch(ListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(ListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}