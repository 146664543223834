import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from 'formik';
import styles from "./addComment.module.css";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../../../components/label/Labels";
import { FormTextArea } from "../../../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../../../components/button/buttons";
import FileAttachment from "../../../../../../components/fileAttachment/fileAttachment.container";
import Asterisk from "../../../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../../../assets/images/images";
import { Sidebar } from "../../../../../../components/sidebar/Sidebar";
import * as DOMPurify from 'dompurify';


let obj = {
  width: "auto"
}

const FieldWrapper = ({ children }) => {
  return <div  className={styles.fieldWrapper}>{children}</div>;
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
      <Label text={label} optional={optional} style={styles.labelcompLevel}/><Asterisk />
      </div>      
      <FormTextArea {...field} {...props}  style={styles.additionalStyle}/>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const AddComment = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [show, setShow] = useState(false);
  const close = () => setShow(false);

  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  let _initialValues = {
    comment: "",
    files: [],
  
  };

  const validationSchema = Yup.object({
    comment: Yup.string().max(1000,"Comment must be at most 1000 characters").required("Required"),
  //  files:Yup.array().max(3)
    
  });

 
 

  return (
    <>
       <div>
  {props.source === "Reject" ? (
    <button onClick={() => setShow(true)} className={`${styles.reject} ${styles.commonbtncss}`}>Reject</button>
  ) : props.source === "Approve" ? (
    <button onClick={() => setShow(true)} className={`${styles.approve} ${styles.commonbtncss}`}>Approve</button>
  ) : (
    <button onClick={() => setShow(true)} className={`${styles.comment} ${styles.commonbtncss}`}>Need More Info</button>
  )}
</div>
      {show && (
     <Sidebar isOpen={show} 
     headerTxt={
        props.source === "Reject" ? "Reject Request" : 
        props.source === "Approve" ? "Approve Request" : 
        "Request an Information"
      } 
     onClose={close} sideNavStyle={styles.sideNavStyle}>
    <Formik
      initialValues={_initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (values, { resetForm }) => {       
        setIsSubmit(true);
        let obj={};
        if(props.source==="addComment"){
          obj = {
            requestId: props?.data?.requestId,
            requestDocumentId: props?.data?.id,
            commentMessage: values?.comment,
            commentAttachments: [],
            uniqueCustomerRefId: props?.data?.uniqueCustomerRefId,
          };
          }else{
            obj = {
              requestId: props?.data?.requestId,
              uniqueCustomerRefId: props?.data?.uniqueCustomerRefId,
              requestStatus: props.source==="Approve"?"approved":"rejected",
              commentMessage: values?.comment,
            };
          }
          // console.log("obj",obj)
          // return
        if(props.source==="addComment"){
          props.AddComment(obj).then((result) => {          
            if (result.status) {
              props.openAlertPopup({
                message: "Comment submitted successfully!",
                image: IMAGES.success
              });
              setIsSubmit(false);
              close();           
              resetForm();   
              let next=null;     
              props.getOnBoardingComments(obj.requestDocumentId,next);
            } else {
              let error_msg = result?.error?.message ?? "Something went wrong.";
              props.openAlertPopup({
                message: error_msg,
                image: IMAGES.error
              });
              setIsSubmit(false); 
              close()
            }
          })
            .catch((error) => {
              props.openAlertPopup({
                message: "Something went wrong.",
                image: IMAGES.error
              });
              setIsSubmit(false);     
              close()
          });
        }else{
          props.onBoardingApproveOrReject(obj,props?.data?.id).then((result) => {         
            if (result.status) {
              props.openAlertPopup({
                message: props.source==="Approve"?"Customer has been onboarded successfully!":"Request has been rejected.",
                image: IMAGES.success
              });
              setIsSubmit(false);
              close();           
              resetForm();   
              let next=null;     
              props.getOnBoardingComments(props?.data?.id,next);
              props.getOnBoardingApprovalDetail(props?.data?.id);
            } else {
              let error_msg = result?.error?.message ?? "Something went wrong.";
              props.openAlertPopup({
                message: error_msg,
                image: IMAGES.error
              });
              setIsSubmit(false); 
              close()
            }
          })
            .catch((error) => {
              props.openAlertPopup({
                message: "Something went wrong.",
                image: IMAGES.error
              });
              setIsSubmit(false);     
              close()
          });
        }
      }}
    >
      {({isValid, dirty, values, setFieldValue, resetForm }) => (  
        <>
        <Form 
        className={styles.form}
         id="id_comment_form"
        >          
          <TextArea
            label={"Reason"}
            id="description"
            name="comment"
          />
          <div className={styles.commentValidation}>1000 characters</div>    
        </Form>
        <div className={styles.footer}>
        <hr className={styles.separator} />
        <ButtonWrapper>
          <BackButton className={styles.backButton}
            style={styles.submit}
            title={"Back"} 
            closePopup={close} 
          />
          <Submit
            id="saveButton"
            className={styles.saveButton}
            style={styles.submit}
            title={"Submit"}
            form="id_comment_form"
            isSubmitting={isSubmit}
            disabled={!isValid || !dirty}
          />
        </ButtonWrapper>
        </div>
      </>
      )}
    </Formik>
    </Sidebar>
   
        )}
    </>
  )
};


export default AddComment;

