import NewVFUser from "./NewVFUser";
import {connect} from "react-redux";
import { getUser } from "../../../../redux/actions/userManagement/userViewer.action";
import { createUser } from "../../../../redux/actions/userManagement/newUser.action";
import { openAlertPopup, closeAlertPopup } from "../../../../redux/actions/Alert/alert.action";
import { updateUserPermissions } from "../../../../redux/actions/userManagement/updatePermissions.action";
import { redirectTab } from "../../../../redux/actions/userManagement/redirectTab.action";

const mapStateToProps = (state) => {
    return {
        functionalRoles:state.functionalRoles.roles,
        redirect:state.onboardingRequest.redirectTab.redirectTab,
        myRights :state.myRights.myRights,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (payload) => {
            return dispatch(createUser(payload))
        },
        getUser: (type,custID,searchKey,next=null) => {
            return dispatch(getUser(type,custID,searchKey,next))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        },
        closeAlertPopup: () => {
            return dispatch(closeAlertPopup())
        },
        updateUserPermissions: (payload,id) => {
            return dispatch(updateUserPermissions(payload,id))
        },
        redirectTab: (tab) => {
            return dispatch(redirectTab(tab))
        },             
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewVFUser);

