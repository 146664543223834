import {
    BASE_URL,
    PATCH,
    GET_NEW_AM,
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import {getAccessToken} from "../../authActions/authActions";
  
  export const addAM = async(payload) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${GET_NEW_AM}`;
    return fetch(resource, {
      method: PATCH,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
  };

  
  