import { connect } from "react-redux";
import { SubmitComment} from "../../../src/redux/actions/contactUs/contactUs.actions";
import  ContactUs  from "./contactUs";
import { openAlertPopup } from "../../redux/actions/Alert/alert.action";

const mapStateToProps = (state) => {
    return {
      myRights:state.myRights.myRights,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {     
      addComment: (payload) => {
        return dispatch(SubmitComment(payload))
      },
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      }

    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);


