import Login from "./Login";
import {connect} from "react-redux";

const mapStateToProps = null;

const mapDispatchToProps = (dispatch)=>{
    return {
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);


