import styles from "../button/buttons.module.css";
import { Loader } from "../loader/Loader";

export const SubmitButton = ({children, style,...rest}) => {

  const styleClassName = `${style} ${styles.submit}`
    return (
    <>
    <button     
      className={styleClassName}
      {...rest}    
    >{children}</button>       
    </>
    );
}

/** This submit button is only meant to be used with formik forms */
export const Submit = ({
  id,
  isSubmitting,
  title,
  style = "",
  disabled = false,
  className,
  loaderstyle = "",
...props 
}) => {
  if (isSubmitting) {
    return <div className={`${styles.btnLoaderContainer} ${loaderstyle} `} ><Loader style={styles.btnLoader}/></div>;
  }
  return (
    <button
      id={id}
      disabled={disabled}
      type="submit"
      className={`${styles.submit} ${style} ${className}`}
      {...props}
    >
      {title}
    </button>
  );
};

export const BackButton = ({
  title,
  id,
  style = "",
  closePopup
}) => { 
  return (
    <button      
      type="reset"
      id={id}
      className={`${styles.backButton} ${style}`}
      onClick={closePopup}
    >
      {title}
    </button>
  );
};

export const LinkButton = ({ title, onClick, style = "" }) => {
  return (
    <div
    //  className={styles.linkButton} 
     className={`${styles.linkButton} ${style}`} onClick={onClick}>
      {title}
    </div>
  );
};

export const EditButton = ({
  title,
  id,
  style = "",
  onClick
}) => { 
  return (
    <button      
      type="reset"
      id={id}
      className={`${styles.backButton} ${style}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};


