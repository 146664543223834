import { FETCH_DIAMETER_INOUT_MESSAGE,UPDATE_DIAMETER_INOUT_MESSAGE_FILTER} from "../../actionTypes";
import { fetchTotalINOutMessages } from "../../../../api/dashboard/diameter/diameter";

const request = () => ({ type: FETCH_DIAMETER_INOUT_MESSAGE.REQUEST });
const failure = (error) => ({ type: FETCH_DIAMETER_INOUT_MESSAGE.FAILURE, error });
const success = (data) => ({ type: FETCH_DIAMETER_INOUT_MESSAGE.SUCCESS,data});

export const updateTotalInOutFilters= (filters)=>{
    return {
        type: UPDATE_DIAMETER_INOUT_MESSAGE_FILTER,
        filters
    }
}

export const getTotalInOutMessageData = (filter) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchTotalINOutMessages(filter)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
