import { addEntensionExcessRequest } from "../../../api/userManagement/extensionExccessRequest.service"
import { EXTENSION_ACCESS_REQUEST} from "../actionTypes"

const request = () => ({ type: EXTENSION_ACCESS_REQUEST.REQUEST })
const failure = (error) => ({ type: EXTENSION_ACCESS_REQUEST.FAILURE, error })
const success = () => ({ type: EXTENSION_ACCESS_REQUEST.SUCCESS })

export const AddExtensionAccessRequest = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addEntensionExcessRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}
 