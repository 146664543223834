import React from "react";

const Asterisk = () => {
    return (
        <span style={{color: "#BD0000"}}>*</span>
    );
}

export default Asterisk;

