import { FETCH_CUSTOMERS} from "../actionTypes";
import { fetchCustomers } from "../../../api/newService/newService.service";

const request = () => ({ type: FETCH_CUSTOMERS.REQUEST });
const failure = (error) => ({ type: FETCH_CUSTOMERS.FAILURE, error });
const success = (data) => ({ type: FETCH_CUSTOMERS.SUCCESS,data});

export const getCustomers = () => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchCustomers()
      .then((res) => {
        // let _res=  res?.customers?.map((item) => ({ value: item.cpName, label: item.cpName }));
        dispatch(success(res));
        return Promise.resolve(true);

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
