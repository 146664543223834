import {FETCH_SERVICE_DETAIL } from "../actionTypes"
import { fetchServiceDetail } from "../../../api/newService/serviceDetail.service"

const serviceDetailRequest = ()=>({type: FETCH_SERVICE_DETAIL.REQUEST})
const serviceDetailFailure = (error)=>({type: FETCH_SERVICE_DETAIL.FAILURE, error})
const serviceDetailSuccess = (obj)=>({type: FETCH_SERVICE_DETAIL.SUCCESS, obj})

export const getServiceDetail = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(serviceDetailRequest())
        return fetchServiceDetail(requestId)
        .then(response => { 
            dispatch(serviceDetailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(serviceDetailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
