import ServiceStatus from "./ServiceStatus";
import { connect } from "react-redux";
import { updateServiceStatus } from "../../../redux/actions/newService/service.actions";
import { closeServiceStatusForm } from "../../../redux/actions/newService/serviceStatus.actions";
import { getServicesList } from "../../../redux/actions/newService/servicesList.actions";
import { AddComment } from "../../../redux/actions/newService/comment.actions";
import { getServiceDetail } from "../../../redux/actions/newService/serviceDetail.actions";
import { getComments } from "../../../redux/actions/newService/comment.actions";
import { openAlertPopup, closeAlertPopup } from "../../../redux/actions/Alert/alert.action";


const mapStateToProps = (state) => {
  return {
    isServiceStatusFormOpen:state.newService.serviceStatus.isServiceStatusFormOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeServiceStatusForm: () => {
      return dispatch(closeServiceStatusForm())
    },
    updateServiceStatus: (payload, id) => {
      return dispatch(updateServiceStatus(payload, id))
    },
    getServicesList: (status = '', searchKey = '',next=null) => {
      return dispatch(getServicesList(status, searchKey,next))
    },
    addComment: (payload) => {
      return dispatch(AddComment(payload))
    },
    getServiceDetail: (requestId)=>{
      return dispatch(getServiceDetail(requestId))
    },  
    getComments:(requestId,next=null)=>{
      return dispatch(getComments(requestId,next))
    },
    openAlertPopup: (payload) => {
      return dispatch(openAlertPopup(payload))
    },
    closeAlertPopup: () => {
        return dispatch(closeAlertPopup())
    },

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceStatus);


