import React, { useEffect } from "react";
import styles from "./myIncidentViewer.module.css";
import IMAGES from "../../../../assets/images/images";
import { useNavigate } from "react-router-dom";
import AddComment from "../../addComment/addComment.container";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../components/loader/Loader";
import { STATUS_ENUM } from "../../../../utils/constants/incidentConstants";
import moment from 'moment';
import { formatDate, futureDate } from "../../../../utils/arrayUtils";
import { USER_TYPE } from "../../../../utils/constants/userConstants";

const IncidentList = ({ data, myRights }) => {
  const navigate = useNavigate();
  const gotoIncidentDetail = (requestId) => {
    navigate(`/incidentDetail/${requestId}`);
  };
  return (
    <div className={styles.collapsibleContainer}>
      <div className={styles.collapsible}>
        <div className={styles.header}>
          <div>
            <span className={styles.SRid} onClick={() => gotoIncidentDetail(data.id)}>
              {data.incidentId}
            </span>
            <span
              className={`${styles.status} ${styles[data?.status?.value]}`}>
              {data.status.label}
            </span>
          </div>
          <div className={styles.onhoverdiv}>
            {/* {data.status.value === STATUS_ENUM.NEED_MORE_INFORMATION ? (
              <div>
                {" "}
                <div className={styles.tooltip}>
                  {" "}
                  <img className={styles.infoCircle} src={IMAGES.infoCircle} />
                  <span className={styles.tooltiptext}>
                    Respond Within 48 Hours
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )} */}
            <div className={styles.parentdiv}>
               
                {/* <div className={styles.submitedOn}>Last Updated On: <span className={styles.data}>{formatDate(data.modifiedAt)}</span></div> */}
                {myRights?.userType===USER_TYPE.CUSTOMER &&(
          <>
            {data.status.value===STATUS_ENUM.CUSTOMER_FEEDBACK_AWAITED&&(
             <div className={`${styles.warning}`}><span className={styles.warningtext}>Please respond by {futureDate(data?.modifiedAt)} to accept or reject the ticket resolution.</span></div>
            )}
            {data.status.value===STATUS_ENUM.NEED_MORE_INFORMATION&&(
              <div className={`${styles.warning}`}><span className={styles.warningtext}>Respond by {futureDate(data?.modifiedAt)}, or the ticket will be auto closed.</span></div>
            )}
          </>
        )}
              {
                <span className={styles.notificationmain}>
                  {/* {
                    <span className={styles.notification}></span>
                 
                  } */}
                  {data.isServiceDeskCommentReceived && myRights?.userType===USER_TYPE.CUSTOMER &&(
                  <div className=""><span className={styles.commentnotification}></span> <img className={styles.newcomment}src={IMAGES.newcomment} alt="new comment"/></div>
                  )}
                  {/* <AddComment source={"incList"}></AddComment> */}
                </span>
              }
            </div>
          </div>
        </div>
        <div className={styles.summary}>
          {/* <span className={styles.issue}>Title : </span> */}
          <span className={styles.summarydata}><span className={styles.issuetitle}>Title:</span> {data.issueDetails.title}</span>
        </div>
        <div className={styles.titleContainer}>  
          {myRights?.userType===USER_TYPE.INTERNAL &&(
            <>
              <div className={`${styles.DetailsDiv} ${styles.border}`}>Customer Name: <span className={styles.data}>{data?.createdBy?.name}</span></div>
              {/* <div className={styles.border}></div> */}
            </>
          )}
          {/* {data?.status?.value!==STATUS_ENUM.NEW &&(
            <>
              <div className={`${styles.DetailsDiv} ${styles.border} ${myRights?.userType===USER_TYPE.INTERNAL?styles.leftpadd:""}`}>OneITSM ID: <span className={styles.data}>{data?.ticketIdServiceDesk?data?.ticketIdServiceDesk:"---"}</span></div>
               //<div className={styles.border}></div> *
            </>
          )} */}
        {/* <div className={`${styles.DetailsDiv} ${styles.border} ${(data?.status?.value!==STATUS_ENUM.NEW || myRights?.userType===USER_TYPE.INTERNAL)?styles.leftpadd:""}`}> */}
        <div className={`${styles.DetailsDiv} ${styles.border} ${(myRights?.userType===USER_TYPE.INTERNAL)?styles.leftpadd:""}`}>
            {/* Submitted On: <span className={styles.data}>{formatDate(data.createdAt)}</span> */}
            Service Type: <span className={styles.data}>{data.incidentServiceType.label}</span>
          </div>
          {/* <div className={styles.border}></div> */}
          <div className={`${styles.DetailsDiv} ${styles.border} ${styles.leftpadd}`}>
            {/* My Service: <span className={styles.data}>{data.incidentServiceType.label}</span> */}
             Fault Type: <span className={styles.data}> {data.incidentFaultType.label}</span>
          </div>
          {/* <div className={styles.border}></div> */}
          <div className={`${styles.DetailsDiv} ${styles.border} ${styles.leftpadd}`}>
            Created On: <span className={styles.data}>{formatDate(data.createdAt)}</span>
          </div>
          <div className={styles.submitedOn}>Last Updated On: <span className={styles.data}>{formatDate(data.modifiedAt)}</span></div>
          {/* {data?.status?.value!==STATUS_ENUM.NEW&&(
            <>
              <div className={styles.border}></div>
              <div className={styles.DetailsDiv}>
                Remedy Id:
                <span className={styles.data}> {data.ticketIdServiceDesk}</span>
              </div>
            </>
          )} */}
        </div>
        
      </div>
    </div>
  );
};

const MyIncidentViewer = (props) => {
  const data = props.incidentList.data;
  useEffect(() => {
    props.getIncidentList();
  }, []);

  const fetchData = () => {
    if (props.incidentList.next) {
      props.getIncidentList(props.incidentList.next);
    }
  };

  return (
    <div>
      {props.incidentList.isLoading && props.incidentList.data.length == 0 ? (
        <Loader />
      ) : props.incidentList.data.length == 0 ? (
        <div className={styles.Emptydiv}>No Records Found</div> 
      ) : (
        <InfiniteScroll
          dataLength={props.incidentList.data?.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={props.incidentList.next}
          loader={<Loader />}
        >
          {data.map((item) => (
            <IncidentList data={item} myRights={props?.myRights} />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default MyIncidentViewer;
