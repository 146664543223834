import { REDIECT_TAB } from '../../actions/actionTypes';

const initialState = {
  redirectTab: "",
};

const redirectTab = (state = initialState, action) => {
  switch (action.type) {
    case REDIECT_TAB:
      return {
        ...state,
        redirectTab: action.payload,
      };
    default:
      return state;
  }
};

export default redirectTab;