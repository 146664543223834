import { connect } from "react-redux";
import OnboardingDetail from "./onboardingDetail";
import { getOnBoardingApprovalDetail } from "../../../../../../redux/actions/userManagement/onBoardingApprovalDetail.action";
import { clearOnboadingComments, getOnBoardingComments } from "../../../../../../redux/actions/userManagement/onboardingAddComment.action";
import { downloadFiles } from "../../../../../../redux/actions/fileAttachment/fileAttachment.actions";
const mapStateToProps = (state) => {
  return {
    requestDetails : state.onboardingRequest.onBoardingApprovalDetail,
    commentsList :state.onboardingRequest.onBordingCommentList,
    myRights : state.myRights.myRights
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getOnBoardingApprovalDetail: (requestId)=>{
      return dispatch(getOnBoardingApprovalDetail(requestId))
    },
    getOnBoardingComments:(requestId,next=null)=>{
      return dispatch(getOnBoardingComments(requestId,next))
    },
    clearOnboadingComments:()=>{
      return dispatch(clearOnboadingComments())
    },
    downloadFiles:(originalFileName,uniqueFileName)=>{
      return dispatch(downloadFiles(originalFileName,uniqueFileName))
  },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDetail);
