import React, { useState,useEffect, useRef} from "react";
import style from "./sidebar.module.css";
import IMAGES from "../../assets/images/images";


export const Sidebar = ({ children, isOpen, onClose, headerTxt,sideNavStyle=null,scrollToTop, ...rest }) => {
  const sidebarRef = useRef(null);

    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [isOpen]);

    useEffect(() => {
      if (scrollToTop && sidebarRef.current) {
        sidebarRef.current.scrollTop = 0;
      }
    }, [scrollToTop]);
  
  return (
    <>
      {isOpen ?
      <React.Fragment>
      <div role="button" className={style.backdrop} onClick={onClose} />
      <div ref={sidebarRef}  className={`${style.sidenav} ${style.open} ${sideNavStyle}`}>
        <div className={style.header}>
          <div className={style.headerTxt}>{headerTxt}</div>
          <button className={style.closeBtn} onClick={onClose}>
            <img
              width="24px"
              height="24px"
              src={IMAGES.close}
              alt="x mark indicating close icon"
            />
          </button>
        </div>
        <div className={style.sidemenuContainer}>{children}</div>
      </div>
      </React.Fragment>:<></>
      }
    </>
  );
};

export const closeSideBar = () => {};
