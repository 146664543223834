import IMAGES from '../../assets/images/images';

 export const getLogo = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'png':
        return IMAGES.pngLogo;
      case 'jpg':
        return IMAGES.jpgLogo;
      case 'jpeg':
        return IMAGES.jpgLogo;
      case 'pdf':
        return IMAGES.pdfLogo;
      case 'xlsx':
        return IMAGES.xlsxLogo;
      case 'xls':
        return IMAGES.xlsxLogo;
      case 'doc':
        return IMAGES.docLogo;
      case 'docx':
        return IMAGES.docLogo;
      default:
        return IMAGES.attachment;
    }
  };