import React, { useState,useEffect } from 'react'
import { Breadcrumbs } from '../../../../../../components/breadcrumbs/Breadcrumbs';
import { Banner } from '../../../../../../components/banner/Banners';
import IMAGES from '../../../../../../assets/images/images';
import { useParams } from 'react-router-dom';
import { ChildContainer, Container } from '../../../../../../components/Container/Containers';
import styles from './accountAccessRequestDetail.module.css';
import { useNavigate } from 'react-router-dom';
import  AddComment  from '../addComment/addComment.container';
import { Loader } from '../../../../../../components/loader/Loader';
import { formatDate } from '../../../../../../utils/arrayUtils';
import { EXTENSTION_STATUS } from '../../../../../../utils/constants/userManagementConstant';

const AccountAccessRequestList = ({ data }) => {
  const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
    

  const back = () => {
    navigate(`/user-management`);
  }

    return (
     <div>
         <div className={styles.back}>
         <div className={styles.parent}>
          <div>
          <span><img className={styles.backbutton} src={IMAGES.backarrow} onClick={()=>back()} /></span>
          <span className={styles.id}>{data?.requestId}</span><span className={`${styles.status} ${styles[data?.requestStatus?.value]}`}>{data?.requestStatus?.label}</span>
          </div>
          {/* <div className={styles.headnote}><img className={styles.infocircle}src={IMAGES.infoCircle}/>Your request has been sent to the Account Manager. You can edit the customer details after access is granted.</div> */}
        </div>
      </div>
      <div className={styles.container}>
      <div className={styles.requestDetail}>Request Details</div>
      <div className={styles.listContent}>
        <div className={styles.list}>
          <div className={styles.label}>Request Assigned To</div>
            <div className={styles.value}>{data?.requestAssignedTo?.label}</div>
          </div>
          <div className={styles.list}>
            <div className={styles.label}>Request On</div>
            <div className={styles.value}>{formatDate(data?.createdAt)}</div>
          </div>
          {data?.requestStatus?.value===EXTENSTION_STATUS.APPROVED &&(
              <>
                <div className={styles.list}>
                 <div className={styles.label}>Approved By</div>
                 <div className={styles.value}>{data?.approvedBy?.name}</div>
                </div>
                <div className={styles.list}>
                 <div className={styles.label}>Approved Date</div>
                 <div className={styles.value}>{formatDate(data?.approvedAt)}</div>
                </div>
              </>
             )}
      </div>
      {/* <div className={styles.label}>Additional Information</div>
      <div className={styles.value}> useEffect call and refer to those specific props inside useEff</div> */}
     </div>
     </div>
    )
  };

  const AccountAccessDetail = ({ data,onFileDownload,loading,...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
     <div>
      
      <div className={styles.container}>
      <div className={styles.requestDetail}>Customer Details</div>
      <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Orginisation Legal Name</div>
                <div className={styles.value}>{data?.displayName}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Service Type</div>
                <div className={styles.value}>{data?.serviceType?.label}</div>
             </div>
             {data?.cpName!==null&&(
           <div className={styles.list}>
           <div className={styles.label}>TADIG Code</div>
           <div className={styles.value}>{data?.cpName}</div>
           </div>
             )}
             </div>
     </div>
     </div>
    )
  };
 

  const CommentDetail = ({data, onFileDownload}) => {
   
    return (
     <div  className={styles.comment} >
     No Data Found
     </div>
    );
  };


const AccountAccessRequestDetail = (props)=> {
  let { requestId } = useParams();
  useEffect(() => {
    props.getExtensionRequestDetail(requestId);
    window.scrollTo(0,0);
  }, [requestId]);
// console.log("props",props);

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Extension Access Request"}></Banner>
      <ChildContainer>
       {/* {props.requestDetails.map((item) => (
        <CommercialMlist data={item}/>
        ))} */}
        {props?.loading?
           <div className={styles.comentsLoader}>
           <Loader/>
         </div>:
         <>
          <AccountAccessRequestList data={props.requestDetails}/>
          <AccountAccessDetail data={props.requestDetails}/>
         </>
         }
                 {/* <div  className={styles.commentsection}>
                  <div className={styles.comtext}> Comment History</div>
                  <div> <AddComment source={'incDetail'}/></div>
                 </div>
                 <div className={styles.alert}>Request has been created and assigned to Commercial Manager.  </div>
        <CommentDetail/> */}
      </ChildContainer>
    </Container>
  )
};

export default AccountAccessRequestDetail;
