import { MY_INCIDENT_TABS } from "../../../utils/constants/incidentConstants";
import {SET_ACTIVE_TAB_INCIDENT} from "../../actions/actionTypes"

const initialState = {
   incidentActiveTab:MY_INCIDENT_TABS.MY_INCIDENTS,  
};

const reducer = (state = initialState, action) => {  
    switch (action.type) {
        
        case SET_ACTIVE_TAB_INCIDENT:
            return { ...state, incidentActiveTab:action.tab};
        default:
            return state
    }
}

export default reducer;
