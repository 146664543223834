export const MINUTE15 ="15 Minute";
export const MINUTE5 ="5 Minute";
export const HOUR ="Hour";
export const DAY="Day";
export const MONTH="Month";
export const MONTH3="3 Months";
export const MONTH6="6 Months";
export const DAY1="1 Day";
export const DAYS7 ="7 Days";
export const CUSTOM ="Custom";
export const IN_MESSAGE="Total_In_Messages";
export const OUT_MESSAGE="Total_Out_Messages";
export const TOTAL_MESSAGE="Total_Messages";
export const LAST_24_HOURs="Last 24 Hours";
export const MAX_PEAK_IN="Max of Peak_in";
export const MAX_PEAK_OUT="Max of Peak_out";
export const MAX_VOLUME_IN="Max of Volume_in";
export const MAX_VOLUME_OUT="Max of Volume_out";
export const IN_MSUs="Total_In_MSUs";
export const OUT_MSUs="Total_Out_MSUs";
export const TOTAL_MSUs="Total_MSUs";

export const TIME_WINDOW_OPTIONS=[
    { value: LAST_24_HOURs, label: "Yesterday" },
    { value: DAYS7, label: DAYS7 },
    { value: MONTH3, label: MONTH3 },
    { value: MONTH6, label: MONTH6 },
    { value: CUSTOM, label: CUSTOM },
]

