import { SUBMIT_CONTACT_US_DETAILS} from "../../actions/actionTypes";

const initialState = {
  loading: false,
};

const reducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SUBMIT_CONTACT_US_DETAILS.REQUEST:
      return { ...state, loading: true };
    case SUBMIT_CONTACT_US_DETAILS.FAILURE:
      return {...state,isLoading:false};
    case SUBMIT_CONTACT_US_DETAILS.SUCCESS:
        return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
