import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from 'formik';
import styles from "../comment/comment.module.css";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../components/label/Labels";
import { FormTextArea } from "../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../components/button/buttons";
import FileAttachment from "../../../components/fileAttachment/fileAttachment.container";
import { DetailContainer } from "../../../components/comment/comment";
// import { Alert } from "../../../components/alert/alerts";
import Asterisk from "../../../components/asterisk/Asterisk";
import IMAGES from "../../../assets/images/images";
import { Sidebar } from "../../../components/sidebar/Sidebar";
import * as DOMPurify from 'dompurify';

let obj = {
  width: "auto"
}

const FieldWrapper = ({ children }) => {
  return <div  className={styles.fieldWrapper}>{children}</div>;
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
      <Label text={label} optional={optional} /><Asterisk />
      </div>      
      <FormTextArea {...field} {...props} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper >
      <Label text={label} optional={false} />
      <FileAttachment name={props.name} value={value} setFieldValue={setFieldValue} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};


const Comment = (props) => {   
  const [isSubmit, setIsSubmit] = useState(false);
  let [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });
  const serviceDetail = props.serviceData.data; 
  let _initialValues = {
    comment: "",
    files: [],
    serviceId: typeof serviceDetail !== "undefined" && serviceDetail != null ? serviceDetail.id : ''
  };

  const validationSchema = Yup.object({
    comment: Yup.string().max(1000,"Comment must be at most 1000 characters").required("Required"),
    files:Yup.array().max(3)
    
  });

  const closePopup = () => {
    _initialValues = {
      comment: "",
      files: [],
      serviceId: typeof serviceDetail !== "undefined" && serviceDetail != null ? serviceDetail.id : ''
    }   
    props.closeCommentForm();
  }

  const closeCommentsSidebar = () => {
    props.closeCommentForm();
  }

  return (
    <>
     <Sidebar
        isOpen={props.isCommentFormOpen}
        onClose={closeCommentsSidebar}
        headerTxt={"Add Comment"}
      >
    <Formik
      initialValues={_initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (values, { resetForm }) => {       
        setIsSubmit(true);
        let obj = { "files": [] };
        obj.comment = DOMPurify.sanitize(values?.comment);
        obj.files = values?.files;
        obj.serviceId = typeof serviceDetail !== "undefined" && serviceDetail != null ? serviceDetail.id : '';
        obj.uniqueCustomerRefId = props?.serviceData?.data?.uniqueCustomerRefId;
        props.addComment(obj).then((result) => {          
          if (result.status) {
            // setMessage({
            //   message: "Comment Added successfully",
            //   image: IMAGES.success
            // });
            // setIsAlert(true);
            props.openAlertPopup({
              message: "Comment added successfully!",
              image: IMAGES.success
            });
            setIsSubmit(false);            
            resetForm();   
            closeCommentsSidebar();    
            let next=null;     
            props.getComments(obj.serviceId,next);
          } else {
           // setIsAlert(true);
            let error_msg = result?.error?.message ?? "Something went wrong.";
            // setMessage({
            //   message: error_msg,
            //   image: IMAGES.error
            // });
            props.openAlertPopup({
              message: error_msg,
              image: IMAGES.error
            });
            setIsSubmit(false); 
            closeCommentsSidebar();            
          }
        })
          .catch((error) => {
           // setIsAlert(true);
            // setMessage({
            //   message: "Something went wrong.",
            //   image: IMAGES.error
            // });
            props.openAlertPopup({
              message: "Something went wrong.",
              image: IMAGES.error
            });
            setIsSubmit(false);     
            closeCommentsSidebar();        
          });

      }}
    >
      {({ isValid, dirty, values, setFieldValue, resetForm }) => (       
        <Form>
          <div className={styles.commentpart}>       
          {/* {typeof serviceDetail !== "undefined" && serviceDetail != null ?
            <DetailContainer data={serviceDetail} /> : <></>}
          <br /> */}
          
          <TextArea
            label={"Comment"}
            id="description"
            name="comment"
          />
          <div className={styles.commentValidation}>1000 characters</div>
          <br />
          <ImageUpload
            name="files"
            value={values.files}
            setFieldValue={setFieldValue}
            optional={false}
          />
          </div>
          <div className={styles.bottom}>
          <hr className={styles.separator} />
          <ButtonWrapper>
            <BackButton className={styles.backButton}
              style={styles.submit}
              title={"Back"} closePopup={closePopup} 
            />
            <Submit
              id="saveButton"
              className={styles.saveButton}
              style={styles.submit}
              title={"Save"}
              isSubmitting={isSubmit}
              //disabled={!isValid || !dirty}
            />
          </ButtonWrapper>
          </div>        
        </Form>
      )}
    </Formik>
    </Sidebar>
    {/* {isAlert && (
                  <div
                    style={{
                      zIndex: 20,
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                    }}
                  >
                    <Alert
                      image={message.image}
                      message={message.message}
                      onClose={setIsAlert}
                    />
                  </div>
                )} */}
    </>
  )
};


export default Comment;

