import { BASE_URL, GET, ON_BORDING_REQUEST, } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from '../../authActions/authActions';


export const fetchOnboardingRequestList = async (next) => {
  const token = await getAccessToken();
  let url = `${BASE_URL}${ON_BORDING_REQUEST}`;

let array = [
  {
    key:"next",
    value:next
  } 
]  

let queryString=``;
array.forEach((item)=>{
  if(item.value){
    if(queryString){
      queryString=queryString+`&${item.key}=${item.value}`
    }else{
      queryString=`${item.key}=${item.value}`
    }
  }
})
if(queryString){
  url=`${url}?${queryString}`;
  url = url.replace ("filter=&", "");
}
  return fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userAuth: `bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};
