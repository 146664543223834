import { FETCH_LIVE_SERVICES, FETCH_LIVE_SERVICES_FILTER} from "../../actions/actionTypes";

const initialState = {
    isLoading:false,
    data:[],
    locations:[],
    filters:{},
    isFilterLoading:false
};

const reducer = (state=initialState, action)=>{    
    switch(action.type){
        case FETCH_LIVE_SERVICES.REQUEST:
            return {...state,data:[],locations:[], isLoading:true}
        case FETCH_LIVE_SERVICES.FAILURE:
            return {...state,data:[],locations:[], isLoading:false}
        case FETCH_LIVE_SERVICES.SUCCESS:           
            return {...state, data:action.data?.liveServices,locations:action.data?.locations,isLoading:false}  
        case FETCH_LIVE_SERVICES_FILTER.REQUEST:
            return {...state, filters:{}, isFilterLoading:true}
        case FETCH_LIVE_SERVICES_FILTER.FAILURE:
            return {...state, filters:{}, isFilterLoading:false}
        case FETCH_LIVE_SERVICES_FILTER.SUCCESS:          
            return {...state, filters:action.data, isFilterLoading:false}                 
        default:
            return state
    }   
}

export default reducer;
