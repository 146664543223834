import { FETCH_SIG_FINALTYPE_FINALDIST } from "../../../actions/actionTypes";

const initialState = {
    isLoading:false,
    finalType_finalDist:[]
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_SIG_FINALTYPE_FINALDIST.REQUEST:
            return {...state,finalType_finalDist:[], isLoading:true}
        case FETCH_SIG_FINALTYPE_FINALDIST.FAILURE:
            return {...state,finalType_finalDist:[], isLoading:false}
        case FETCH_SIG_FINALTYPE_FINALDIST.SUCCESS:           
            return {...state, finalType_finalDist: [...action.data],isLoading:false}        
        default:
            return state
    }   
}

export default reducer;
