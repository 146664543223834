import { LIST_CUSTOMER } from "../../../api/constants";
import { FETCH_CUSTOMER_LIST } from "../../actions/actionTypes";

const initialState = {
  loading: false,
  list: [],
  next: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LIST.REQUEST:
      return { ...state, loading: true };
    case FETCH_CUSTOMER_LIST.FAILURE:
      return { ...state, loading: false };
    case FETCH_CUSTOMER_LIST.SUCCESS:
      return {
        ...state,
        list: action.isLoadMore ? [...state.list,...action.list] : action.list,
        next: action.next,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
