import { connect } from "react-redux"
import FileAttachment from "./fileAttachment"
import {uploadAttachment} from "../../redux/actions/fileAttachment/fileAttachment.actions"


const mapStateToProps = (state)=>{
    return {
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        upload : (payload)=>{
            return dispatch(uploadAttachment(payload))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileAttachment);