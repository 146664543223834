import { FETCH_EXTENSION_REQUEST_DETAIL } from "../actionTypes"
import { fetchExtensionRequestDetail } from "../../../api/userManagement/extensionRequestDetail.service"

const detailRequest = ()=>({type: FETCH_EXTENSION_REQUEST_DETAIL.REQUEST})
const detailFailure = (error)=>({type: FETCH_EXTENSION_REQUEST_DETAIL.FAILURE, error})
const detailSuccess = (obj)=>({type: FETCH_EXTENSION_REQUEST_DETAIL.SUCCESS, obj})

export const getExtensionRequestDetail = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(detailRequest())
        return fetchExtensionRequestDetail(requestId)
        .then(response => {
            // console.log("response",response)
            dispatch(detailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(detailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
