import { VERIFY_EMAIL } from "../../actions/actionTypes";

const initialState = {
    isLoading:false
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case VERIFY_EMAIL.REQUEST:
            return {...state,isLoading:true}
        case VERIFY_EMAIL.FAILURE:
            return {...state,isLoading:false}
        case VERIFY_EMAIL.SUCCESS:           
            return {...state,isLoading:false}        
        default:
            return state
    }   
}

export default reducer;
