import styles from "./containers.module.css"

export const Container = ({children})=>{
    return (
        <div className={styles.container}>
            {children}
        </div>
    );
};

export const AppContainer = ({children})=>{
    return (
        <div className={styles.AppContainer}>
            {children}
        </div>
    );
};

export const ChildContainer = ({children})=>{
    return (
        <div className={styles.subContainer}>
            {children}
        </div>
    );
}

export const LoginContainer = ({children})=>{
    return (
        <div className={styles.loginContainer}>
            {children}
        </div>
    );
}

export const LoginChildContainer = ({children})=>{
    return (
        <div className={styles.loginSubContainer}>
            {children}
        </div>
    );
}

