import AddComment from "./addComment";
import { connect } from "react-redux";
import { AddOnboardingComment, getOnBoardingComments, onBoardingApproveOrReject } from "../../../../../../redux/actions/userManagement/onboardingAddComment.action"
import { openAlertPopup } from "../../../../../../redux/actions/Alert/alert.action";
import { getOnBoardingApprovalDetail } from "../../../../../../redux/actions/userManagement/onBoardingApprovalDetail.action";

const mapStateToProps = (state) => {
    return { 
    
    } 
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      AddComment: (payload)=>{
          return dispatch(AddOnboardingComment(payload))
      },
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      },
      getOnBoardingComments:(requestId,next=null)=>{
        return dispatch(getOnBoardingComments(requestId,next))
      },
      onBoardingApproveOrReject: (payload,requestId)=>{
        return dispatch(onBoardingApproveOrReject(payload,requestId))
      },
      getOnBoardingApprovalDetail: (requestId)=>{
        return dispatch(getOnBoardingApprovalDetail(requestId))
      },
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddComment);


