import {SET_INCIDENT_PREVIEW_MODE} from "../../actions/actionTypes"

const initialState = {
    isIncidentPreviewModeOn: false,  
};

const reducer = (state = initialState, action) => {  
    switch (action.type) {
        case SET_INCIDENT_PREVIEW_MODE:
            return { ...state, isIncidentPreviewModeOn:action.payload};    
        default:
            return state
    }
}

export default reducer;