import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from 'formik';
import styles from "./addComment.module.css";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../components/label/Labels";
import { FormTextArea } from "../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../components/button/buttons";
import FileAttachment from "../fileAttachment/fileAttachment.container"
import { DetailContainer } from "../../../components/comment/comment";
import Asterisk from "../../../components/asterisk/Asterisk";
import IMAGES from "../../../assets/images/images";
import { Sidebar } from "../../../components/sidebar/Sidebar";
import * as DOMPurify from 'dompurify';
import { COMMENT_SOURCE_TYPE } from "../../../utils/constants/incidentConstants";

let obj = {
  width: "auto"
}

const FieldWrapper = ({ children }) => {
  return <div  className={styles.fieldWrapper}>{children}</div>;
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
      <Label text={label} optional={optional} style={styles.labelcompLevel}/><Asterisk />
      </div>      
      <FormTextArea {...field} {...props}  style={styles.additionalStyle}/>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper >
      <Label text={label} optional={false} style={styles.labelcompLevel}/>
      <FileAttachment name={props.name} value={value} setFieldValue={setFieldValue} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};


const AddComment = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [show, setShow] = useState(false);
  const close = () => setShow(false);

  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  let _initialValues = {
    comment: props.source===COMMENT_SOURCE_TYPE.NOT_RESOLVED?"Not Resolved":props.source===COMMENT_SOURCE_TYPE.ACCEPTED?"Yes, I Accept":"",
    files: [],
  
  };

  const validationSchema = Yup.object({
    comment: Yup.string().max(1000,"Comment must be at most 1000 characters").required("Required"),
    files:Yup.array().max(15)
    
  });

 
 

  return (
    <>{props.source ===COMMENT_SOURCE_TYPE.REGULAR_COMMENT ? <button onClick={() => setShow(true)} className={styles.addcomment}>Add Comment</button> : props.source===COMMENT_SOURCE_TYPE.PROVIDE_INFORMATION?
        <button onClick={() => setShow(true)} className={`${styles.provideinfobtn} ${styles.commonbtncss}`}>Provide Information</button>:props.source===COMMENT_SOURCE_TYPE.NOT_RESOLVED?
        <button onClick={() => setShow(true)} className={`${styles.notresolved} ${styles.commonbtncss}`}>Not Resolved</button>:props.source===COMMENT_SOURCE_TYPE.ACCEPTED?
        <button onClick={() => setShow(true)} className={`${styles.accepted} ${styles.commonbtncss}`}>Yes, I Accept</button>:
      <div><span className={styles.notificationmain}>
        <img onClick={() => setShow(true)} className={styles.comment}src={IMAGES.comment}/></span> 
      </div>}
      {show && (
     <Sidebar isOpen={show} headerTxt={`${props.source===COMMENT_SOURCE_TYPE.PROVIDE_INFORMATION?"Provide More Information":(props.source===COMMENT_SOURCE_TYPE.NOT_RESOLVED||props.source===COMMENT_SOURCE_TYPE.ACCEPTED)?"Ticket Resolution":"Add New Comment"}`} onClose={close} sideNavStyle={styles.sideNavStyle}>
    <Formik
      initialValues={_initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (values, { resetForm }) => {       
        setIsSubmit(true);
        let obj = {
          ticketIdPortal: props?.data?.incidentDetail?.incidentId,
          referenceUniqueId: props?.data?.incidentDetail?.id,
          commentMessage: values?.comment,
          commentAttachments: values?.files,
          commentAction:props.source===COMMENT_SOURCE_TYPE.NOT_RESOLVED?"rejected":props.source===COMMENT_SOURCE_TYPE.ACCEPTED?"accepted":null
        };
        props.incidentAddComment(obj).then((result) => {          
          if (result.status) {
           
            props.openAlertPopup({
              message: props.source===COMMENT_SOURCE_TYPE.PROVIDE_INFORMATION?"Information submitted successfully!":(props.source===COMMENT_SOURCE_TYPE.NOT_RESOLVED||props.source===COMMENT_SOURCE_TYPE.ACCEPTED)?"Feedback submitted successfully":"Comment submitted successfully!",
              image: IMAGES.success
            });
            setIsSubmit(false);
            close();           
            resetForm();   
           
            let next=null;     
            props.getIncidentComments(obj.referenceUniqueId,next);
            if(props.source===COMMENT_SOURCE_TYPE.PROVIDE_INFORMATION||props.source===COMMENT_SOURCE_TYPE.NOT_RESOLVED||props.source===COMMENT_SOURCE_TYPE.ACCEPTED){
              props.getIncidentDetail(obj.referenceUniqueId);
            }
          } else {
            let error_msg = result?.error?.message ?? "Something went wrong.";
            props.openAlertPopup({
              message: error_msg,
              image: IMAGES.error
            });
            setIsSubmit(false); 
            close()
          }
        })
          .catch((error) => {
         
            props.openAlertPopup({
              message: "Something went wrong.",
              image: IMAGES.error
            });
            setIsSubmit(false);     
            close()
          });

      }}
    >
      {({ values, setFieldValue, resetForm }) => (  
        <>
        <Form 
        className={styles.form}
         id="id_comment_form"
        >          
          <TextArea
            label={"Comment"}
            id="description"
            name="comment"
          />
          <div className={styles.commentValidation}>1000 characters</div>
          <br />
          <ImageUpload
            name="files"
            value={values.files}
            setFieldValue={setFieldValue}
            optional={false}
          />        
        </Form>
        <div className={styles.footer}>
        <hr className={styles.separator} />
        <ButtonWrapper>
          <BackButton className={styles.backButton}
            style={styles.submit}
            title={"Back"} 
            closePopup={close} 
          />
          <Submit
            id="saveButton"
            className={styles.saveButton}
            style={styles.submit}
            title={"Submit"}
            form="id_comment_form"
            isSubmitting={isSubmit}
          />
        </ButtonWrapper>
        </div>
      </>
      )}
    </Formik>
    </Sidebar>
   
        )}
    </>
  )
};


export default AddComment;

