import { BASE_URL, GET, SERVICE_DETAIL } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchServiceDetail = async (requestId) => {
    const token = await getAccessToken();   
    const url = `${BASE_URL}${SERVICE_DETAIL}/${requestId}`;   
    return fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userauth" : `bearer ${token}`
        },
      })
      .then(statusParsing)
      .then(jsonParsing);     

};