import { FETCH_FUNCTIONAL_ROLES} from "../actionTypes";
import { fetchPredefinedRoles } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: FETCH_FUNCTIONAL_ROLES.REQUEST });
const failure = (error) => ({ type: FETCH_FUNCTIONAL_ROLES.FAILURE, error });
const success = (data) => ({ type: FETCH_FUNCTIONAL_ROLES.SUCCESS,data});



export const getPredefinedRoles= (filter) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchPredefinedRoles(filter)
      .then((res) => {
        dispatch(success(res.roles));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
