import { FETCH_LIVE_SERVICES, FETCH_LIVE_SERVICES_FILTER,DOWNLOAD_LIVE_SERVICES_LIST} from "../actionTypes";
import { fetchLiveServices, fetchLiveServicesFilterValues,downloadLiveServiceList } from "../../../api/liveServices/liveServices";

const request = () => ({ type: FETCH_LIVE_SERVICES.REQUEST });
const failure = (error) => ({ type: FETCH_LIVE_SERVICES.FAILURE, error });
const success = (data) => ({ type: FETCH_LIVE_SERVICES.SUCCESS,data});
const filterRequest = () => ({ type: FETCH_LIVE_SERVICES_FILTER.REQUEST });
const filterFailure = (error) => ({ type: FETCH_LIVE_SERVICES_FILTER.FAILURE, error });
const filterSuccess = (data) => ({ type: FETCH_LIVE_SERVICES_FILTER.SUCCESS,data});

const downloadLiveServicesListRequest = ()=>({type: DOWNLOAD_LIVE_SERVICES_LIST.REQUEST})
const downloadLiveServicesListFailure = (error)=>({type: DOWNLOAD_LIVE_SERVICES_LIST.FAILURE, error})
const downloadLiveServicesListSuccess = (obj)=>({type: DOWNLOAD_LIVE_SERVICES_LIST.SUCCESS, obj})


export const getLiveServices= (cp_name,searchKey, filter) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchLiveServices(cp_name,searchKey, filter)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};


export const getLiveServicesFiltersValue = () => {
  return (dispatch, getState) => {
    dispatch(filterRequest());
    return fetchLiveServicesFilterValues()
      .then((res) => {
        dispatch(filterSuccess(res?.serviceDetailsFilters));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(filterFailure(error?.message));
        return Promise.resolve(false);
      });
  };
};

export const downloadLiveServicesList = (filter, searchKey)=>{
  return (dispatch, getState)=>{        
      dispatch(downloadLiveServicesListRequest())
      return downloadLiveServiceList(filter, searchKey)
      .then(response => { 
        console.log('response',response);                 
          let obj={
              'downloadedData':response.liveServices           
          }    
          dispatch(downloadLiveServicesListSuccess(obj))
          return Promise.resolve(obj)
      })
      .catch(error => {
          dispatch(downloadLiveServicesListFailure(error?.message))
          return Promise.resolve(false)
      })
  }
}