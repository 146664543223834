  import {
    BASE_URL, 
    POST,  
    CONTACT_US,
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";
  
  export const addContactUsRequest = async (payload) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${CONTACT_US}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth": `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
      .then(statusParsing)
      .then(jsonParsing);
  };
  
  