import { FETCH_SERVICE_LIST } from "../actionTypes";
import { fetchServiceList } from "../../../api/incidentManagement/serviceList.service";

const serviceListRequest = ()=>({type: FETCH_SERVICE_LIST.REQUEST})
const serviceListFailure = (error)=>({type: FETCH_SERVICE_LIST.FAILURE, error})
const serviceListSuccess = (obj)=>({type: FETCH_SERVICE_LIST.SUCCESS, obj})


export const getServiceList = (next=null)=>{
    // console.log("sddsfdf")
    return (dispatch, getState)=>{        
        dispatch(serviceListRequest())
        return fetchServiceList(next)
        .then(response => {         
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(serviceListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(serviceListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}