import Select from 'react-select'

const dropdownStyle = {
    control: (provided, state)=> {
        const {selectProps: {hasError}, isDisabled} = state
       
        return (
            {...provided, backgroundColor: isDisabled ? "#999" : "#efefef", borderRadius: 5, height: 48, borderWidth: 1, borderColor: hasError? "#CD3500" : "#C5C1C8"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#100045", fontFamily: "VodafoneRg-Regular", fontSize: 13}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#837B8B", fontFamily:"VodafoneRg-Regular", fontSize: 13}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    //container: (provided, state)=> ({...provided, width: 500,}),
    container: (provided, state)=> {
        const {selectProps: {customStyles}} = state
        return (
            {...provided, ...customStyles}
        );
    },
}
let obj={
    width:500 
}
const DropDown = ({options,placeholder, onChange,value, hasError,customStyles=obj })=>{
    
    return (
        <Select 
            placeholder={placeholder}
            options={options}
            isMulti={true}
            onChange={onChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={value}
            customStyles={customStyles}
           />
    );
}

export default DropDown;