import {
    BASE_URL,
    GET,
    IPX_POPS
} from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchGenericInfo = async (tadigID) => {
    const token = await getAccessToken();
    let resource = "";
    if(tadigID){
        resource=`${BASE_URL}${IPX_POPS}?cp_name=${tadigID}`
    }else{
        resource=`${BASE_URL}${IPX_POPS}`;
    }
    
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth": `Bearer ${token}`
        },
    })
        .then(statusParsing)
        .then(jsonParsing);

};
