import { FETCH_SERVICES} from "../../actions/actionTypes";

const initialState = {
   services:[],
   loading:false
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_SERVICES.REQUEST:
            return { ...state, services: [], loading: true };
        case FETCH_SERVICES.FAILURE:
            return { ...state, services: [], loading: false };
        case FETCH_SERVICES.SUCCESS:
            return {...state,services: action.data,loading: false};
        default:
            return state
    }
}

export default reducer;