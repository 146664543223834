import { CLEAR_USERS_LIST, FETCH_USERS_LIST } from "../../actions/actionTypes";

const initialState = {
    isLoading:false,
    data:[],
    next:null
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_USERS_LIST.REQUEST:
            return {...state, isLoading:true}
        case FETCH_USERS_LIST.FAILURE:
            return {...state,data:[], isLoading:false}
        case FETCH_USERS_LIST.SUCCESS:           
            return {
              ...state,
              data: action.isLoadMore
                ? [...state.data, ...action.data]
                : action.data,
              isLoading: false,
              next: action.next,
            };
        case CLEAR_USERS_LIST:
            return initialState;        
        default:
            return state
    }   
}

export default reducer;
