import { SET_ACTIVE_PRIMARY_TAB, SET_ACTIVE_SECONDARY_TAB } from "../actionTypes";


export const setActivePrimaryTab = (tab) => ({
    type: SET_ACTIVE_PRIMARY_TAB,
    tab,
});

export const setActiveSecondaryTab = (tab) => ({
    type: SET_ACTIVE_SECONDARY_TAB,
    tab,
});