import {FETCH_ONBOARDING_COMMENTS, ONBOARDING_ADD_COMMENT_FORM, RESET_ONBOARDING_COMMENTS, ONBOARDING_APPROVE_REJECT} from "../actionTypes"
import { addRequest, fetchOnBoardingApproveOrReject, fetchOnBoardingComments  } from "../../../api/userManagement/onboardingAddComment.service";

const request = () => ({ type: ONBOARDING_ADD_COMMENT_FORM.REQUEST })
const failure = (error) => ({ type: ONBOARDING_ADD_COMMENT_FORM.FAILURE, error })
const success = () => ({ type: ONBOARDING_ADD_COMMENT_FORM.SUCCESS })
const commentRequest = () => ({ type: FETCH_ONBOARDING_COMMENTS.REQUEST })
const commentfailure = (error) => ({ type: FETCH_ONBOARDING_COMMENTS.FAILURE, error })
const commentSuccess = (obj)=>({type: FETCH_ONBOARDING_COMMENTS.SUCCESS, obj})
const resetComments = () => ({ type: RESET_ONBOARDING_COMMENTS });
const onBordingRequest = () => ({ type: ONBOARDING_APPROVE_REJECT.REQUEST })
const onBordingFailure = (error) => ({ type: ONBOARDING_APPROVE_REJECT.FAILURE, error })
const onBordingSuccess = () => ({ type: ONBOARDING_APPROVE_REJECT.SUCCESS })

export const AddOnboardingComment = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}
 

export const getOnBoardingComments = (requestId,next=null)=>{
  return (dispatch, getState)=>{        
      dispatch(commentRequest())
      return fetchOnBoardingComments(requestId,next)
      .then(response => {
          let obj={
              "data":response?.data,
              "next":response?.next,
              "isLoadMore": next?true:false
          }
          dispatch(commentSuccess(obj))
          return Promise.resolve(true)
      })
      .catch(error => {
          dispatch(commentfailure(error?.message))
          return Promise.resolve(false)
      })
  }
}

export const clearOnboadingComments = () => {
  return (dispatch) => {
    dispatch(resetComments());
  };
}

export const onBoardingApproveOrReject = (payload,requestId) => {
  return (dispatch, getState) => {
    dispatch(onBordingRequest());
    return fetchOnBoardingApproveOrReject(payload,requestId)
      .then((res) => {
        dispatch(onBordingSuccess(res));
        return Promise.resolve({res:res,status:true});
      })
      .catch((error) => {
        dispatch(onBordingFailure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}