import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from 'formik';
import styles from "./addextension.module.css";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../../components/label/Labels";
import { FormTextArea } from "../../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../../components/button/buttons";
import FileAttachment from "../../../../../components/fileAttachment/fileAttachment.container";
import Asterisk from "../../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../../assets/images/images";
import { Sidebar } from "../../../../../components/sidebar/Sidebar";
import * as DOMPurify from 'dompurify';
import { connect } from "react-redux";
import { AddOnboardingComment } from "../../../../../redux/actions/userManagement/onboardingAddComment.action";
import { closeAlertPopup, openAlertPopup } from "../../../../../redux/actions/Alert/alert.action";


let obj = {
  width: "auto"
}

const FieldWrapper = ({ children }) => {
  return <div  className={styles.fieldWrapper}>{children}</div>;
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
      <Label text={label} optional={optional} style={styles.labelcompLevel}/><Asterisk />
      </div>      
      <FormTextArea {...field} {...props}  style={styles.additionalStyle}/>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const AddExtensionRequest = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [show, setShow] = useState(false);
  const close = () => setShow(false);

  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  let _initialValues = {
    comment: "",
    files: [],
  
  };

  const validationSchema = Yup.object({
    comment: Yup.string().max(1000,"Comment must be at most 1000 characters").required("Required"),
  //  files:Yup.array().max(3)
    
  });

 
 

  return (
    <>
    <div>
    <button onClick={() => setShow(true)} className={styles.commonbtncss}>Extension Access Request</button>
    </div>
      {show && (
     <Sidebar isOpen={show} 
     headerTxt={
        "Request Access"
      } 
     onClose={close} sideNavStyle={styles.sideNavStyle}>
    <Formik
      initialValues={_initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (values, { resetForm }) => {       
        setIsSubmit(true);
        let obj = {
              comment: values.comment,
         
        };
        props.AddComment(obj).then((result) => {          
          if (result.status) {
           
            props.openAlertPopup({
              message: "Account access request sent successfully!",
              image: IMAGES.success
            });
            setIsSubmit(false);
            close();           
            resetForm();   
           
            let next=null;     
          //  props.getIncidentComments(obj.referenceUniqueId,next);
          } else {
            let error_msg = result?.error?.message ?? "Something went wrong.";
            props.openAlertPopup({
              message: error_msg,
              image: IMAGES.error
            });
            setIsSubmit(false); 
            close()
          }
        })
          .catch((error) => {
         
            props.openAlertPopup({
              message: "Something went wrong.",
              image: IMAGES.error
            });
            setIsSubmit(false);     
            close()
          });

      }}
    >
      {({ values, setFieldValue, resetForm }) => (  
        <>
        <Form 
        className={styles.form}
         id="id_comment_form"
        >          
          <TextArea
            label={"Add Comment"}
            id="description"
            name="comment"
          />
          <div className={styles.commentValidation}>1000 characters</div>    
        </Form>
        <div className={styles.footer}>
        <hr className={styles.separator} />
        <ButtonWrapper>
          <BackButton className={styles.backButton}
            style={styles.submit}
            title={"Back"} 
            closePopup={close} 
          />
          <Submit
            id="saveButton"
            className={styles.saveButton}
            style={styles.submit}
            title={"Submit"}
            form="id_comment_form"
            isSubmitting={isSubmit}
          />
        </ButtonWrapper>
        </div>
      </>
      )}
    </Formik>
    </Sidebar>
   
        )}
    </>
  )
};



const mapStateToProps = (state) => {
    return { 
    
    } 
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      AddComment: (payload)=>{
           return dispatch(AddOnboardingComment(payload))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        },
        closeAlertPopup: () => { 
            return dispatch(closeAlertPopup())
        },
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddExtensionRequest);


