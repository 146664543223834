import { initializeApp } from "firebase/app";

const isLocalhost = window.location.hostname === 'localhost';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: isLocalhost ? process.env.REACT_APP_AUTHDOMAIN_LOCAL : process.env.REACT_APP_AUTHDOMAIN,
};

export const app = initializeApp(firebaseConfig);
