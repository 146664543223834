import {
    BASE_URL,
    GET,
    LOCATIONS,
    POST,
    PATCH,
    RIGHTS_ME,
    SERVICE,
    SERVICES,
    FETCH_CUSTOMERS_DROPDOWN
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import {getAccessToken} from "../../authActions/authActions";
  
  export const SubmitServiceRequest = async(payload) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${SERVICE}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing);
  };

  export const fetchLocations= async() => {
    const token = await getAccessToken();  
    let url = `${BASE_URL}${LOCATIONS}`;

    return fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userAuth" : `bearer ${token}`
        },
      })
      .then(statusParsing)
      .then(jsonParsing); 
  };
  

  export const fetchCustomers= async() => {
    const token = await getAccessToken();
    let url = `${BASE_URL}${FETCH_CUSTOMERS_DROPDOWN}`;

    return fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userAuth" : `bearer ${token}`
        },
      })
      .then(statusParsing)
      .then(jsonParsing);
      
  };

  export const updateServicesStatus = async(payload, serviceId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${SERVICE}/${serviceId}`
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const fetchServices= async() => {  
  const token = await getAccessToken();
  let url = `${BASE_URL}${SERVICES}`;

  return fetch(url, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userAuth" : `bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);      
};
  
  