//import React from 'react';
import styles from './IdleTimer.module.css';
import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { logOut } from "../authActions/authActions";



const IdleTimer = ({isUserLoggedIn,...props}) => {
    const [showWarningPopup, setShowWarningPopup] = useState(false);
    const [showExpiryPopup, setShowExpiryPopup] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    const warningTime = 5; // before expiry in minute
    const expiryTime =30; //logout post minute

    const onIdle = () => {
        setShowExpiryPopup(true);
        onLogout();
    }

    const onPrompt = () => {
        setShowWarningPopup(true); // show warning post 25min
    }

    const onLogoutActions = () => {
        setShowWarningPopup(false);
        logOut();
    }
    const onContinueActions = () => {
        setShowWarningPopup(false);
        reset(); // reset timer 
        start(); // start timer
    }

    const onLogout = () => {
        message("onlogout");
        onLogoutActions();

    }
    const onContinue = () => {
        message("onContinue");
        onContinueActions();

    }

    const closeAllPopup=()=>{
        setShowWarningPopup(false);
        setShowExpiryPopup(false);
    }

    const onMessage = (data) => {
        if (data == "onContinue") {
            onContinueActions();
        } else if (data == "onlogout") {
            onLogoutActions();
        }else if(data == "okBtn"){
            setShowExpiryPopup(false);
        }else if(data=="onLoad"){
            closeAllPopup();
        }
    }


    const { getRemainingTime,getElapsedTime, start, reset, message,pause } = useIdleTimer({
        timeout: expiryTime * 60 * 1000,
        promptBeforeIdle: warningTime * 60 * 1000,
        onIdle,
        onPrompt,
        onMessage,
        throttle: 500,
        crossTab: true,
        syncTimers: 200
    })

    useEffect(()=>{
        if(isUserLoggedIn){
            closeAllPopup();
            start();
            message("onLoad");
        }else{
            pause();
        }
        
    },[isUserLoggedIn])

    const CountdownTimer = (props) => {
        const startTime = (Math.floor((props.remainingTime / 1000 / 60) % 60)) > 0 ? Math.floor((props.remainingTime / 1000 / 60) % 60) - 1 : 0
        const [minute, setMinute] = useState(startTime);
        const [sec, setSec] = useState(60);
      
        useEffect(() => {
            const interval = setInterval(() => {
            setSec((prevSec) => {
              if (prevSec === 1) {
                setMinute((prevMinute) => {
                  if (prevMinute === 0) {
                    clearInterval(interval); 
                    return prevMinute;
                  } else {
                    return prevMinute - 1;
                  }
                });
                return 60;
              } else {
                return prevSec - 1;
              }
            });
          }, 1000);
      
          return () => clearInterval(interval);
        }, [props.remainingTime]);
      
        return (
             <span>{`${minute}:${sec < 10 ? `0${sec}` : sec}`}</span>
        );
      };

    return (
        <React.Fragment>
            {
                showWarningPopup ?
                    <div className={styles.warningpopup}>
                        <div className={styles.warningcontent}>
                            <div className={styles.head}>Session Expiry Warning</div>
                            <div className={styles.headcontent}>Your session will expire soon. Do you want to continue?</div>
                            {/*<div className={styles.headcontent}>Your session will expire in <span><CountdownTimer remainingTime={getRemainingTime()}/></span> minutes. Do you want to continue?</div>*/}
                            <div className={styles.buttons}>
                                <button className={styles.logout} onClick={onLogout}>No, logout</button>
                                <button className={styles.continue} onClick={onContinue}>Yes, continue</button>
                            </div>
                        </div>
                    </div> : showExpiryPopup ?
                        <div className={styles.warningpopup}>
                            <div className={styles.warningcontent}>
                                <div className={styles.head}>Session Expired</div>
                                <div className={styles.headcontent}>Your session has expired due to inactivity.</div>
                                <div className={styles.buttons}>
                                    <button
                                        className={styles.okBtn}
                                        onClick={() => {
                                            message("okBtn");
                                            setShowExpiryPopup(false)
                                        }
                                        }>
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div> : <></>
            }
        </React.Fragment>

    );
};

export default IdleTimer;