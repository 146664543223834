import {FETCH_SERVICE_DETAIL} from "../../actions/actionTypes"

const initialState = {  
    serviceDetail: {},
    loading: false,
};

const reducer = (state = initialState, action) => {
    
    switch (action.type) {    
        case FETCH_SERVICE_DETAIL.REQUEST:
            return {...state,loading: true}
        case FETCH_SERVICE_DETAIL.FAILURE:
            return {...state, loading: false}
        case FETCH_SERVICE_DETAIL.SUCCESS:             
            return {...state, serviceDetail: action.obj.details, loading: false}    
        default:
            return state
    }
}

export default reducer;