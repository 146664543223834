import { FETCH_INCIDENT_DETAIL } from "../actionTypes"
import { fetchIncidentDetail } from "../../../api/incidentManagement/incidentDetail.service"

const incidentDetailRequest = ()=>({type: FETCH_INCIDENT_DETAIL.REQUEST})
const incidentDetailFailure = (error)=>({type: FETCH_INCIDENT_DETAIL.FAILURE, error})
const incidentDetailSuccess = (obj)=>({type: FETCH_INCIDENT_DETAIL.SUCCESS, obj})

export const getIncidentDetail = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(incidentDetailRequest())
        return fetchIncidentDetail(requestId)
        .then(response => {
            dispatch(incidentDetailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(incidentDetailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
