import { FETCH_DIA_FINALTYPE_FINALDIST} from "../../actionTypes";
import { fetchDiaFinalTypeandFinalDist } from "../../../../api/dashboard/diameter/diameter";

const request = () => ({ type: FETCH_DIA_FINALTYPE_FINALDIST.REQUEST });
const failure = (error) => ({ type: FETCH_DIA_FINALTYPE_FINALDIST.FAILURE, error });
const success = (data) => ({ type: FETCH_DIA_FINALTYPE_FINALDIST.SUCCESS,data});


export const getDiaFinalTypeandFinalDist = (cp_name) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchDiaFinalTypeandFinalDist(cp_name)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
