import { FETCH_LOCATIONS} from "../../actions/actionTypes";

const initialState = {
   locations:[],
   loading:false
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_LOCATIONS.REQUEST:
            return { ...state, locations: [], loading: true };
        case FETCH_LOCATIONS.FAILURE:
            return { ...state, locations: [], loading: false };
        case FETCH_LOCATIONS.SUCCESS:
            return {...state,locations: action.data,loading: false};
        default:
            return state
    }
}

export default reducer;