import { FETCH_IPX_POPS} from "../actionTypes";
import { fetchGenericInfo } from "../../../api/genericInformation/genericInformation";

const request = () => ({ type: FETCH_IPX_POPS.REQUEST });
const failure = (error) => ({ type: FETCH_IPX_POPS.FAILURE, error });
const success = (data) => ({ type: FETCH_IPX_POPS.SUCCESS,data});



export const getGenericInfo= (tadigID) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchGenericInfo(tadigID)
      .then((res) => {
        dispatch(success(res?.globalLocations));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
