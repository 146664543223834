import {
    BASE_URL,
    GET,
    RESET_PASSWORD
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import {getAccessToken} from "../../authActions/authActions";
  
  export const resetPassword = async(emailAddress) => {
    const token = await getAccessToken();
    let resource=`${BASE_URL}${RESET_PASSWORD}?email=${emailAddress}`;  
    return fetch(resource, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      }
    })
    .then(statusParsing)
    .then(jsonParsing);
  };

  
  