import React, { useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import styles from './toolTip.module.css';
import IMAGES from '../../assets/images/images';
const Tooltip = ({ text }) => {
    let [isPopoverOpen, setIsPopoverOpen] = useState(false);
    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={['top']} // preferred positions by priority
            containerClassName={styles.popoverContainer}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#f5f3f3'}
                    arrowSize={10}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className={styles.tooltipBody}
                    >
                        {text}
                    </div>
                </ArrowContainer>
            )}


        >
            <img className={styles.circle}src={IMAGES.infoCircle}
                onMouseLeave={() => setIsPopoverOpen((isPopoverOpen = false))}
                onMouseOver={() => setIsPopoverOpen((isPopoverOpen = true))}
            />

        </Popover>
    );
}

export default Tooltip;