import {INCIDENT_ADD_COMMENT_FORM,FETCH_INCIDENT_COMMENTS,RESET_INCIDENT_COMMENTS } from "../actionTypes"
import { addIncidentCommentRequest, fetchIncidentComments } from "../../../api/incidentManagement/incidentComment.service"

const request = () => ({ type: INCIDENT_ADD_COMMENT_FORM.REQUEST })
const failure = (error) => ({ type: INCIDENT_ADD_COMMENT_FORM.FAILURE, error })
const success = () => ({ type: INCIDENT_ADD_COMMENT_FORM.SUCCESS })
const commentRequest = () => ({ type: FETCH_INCIDENT_COMMENTS.REQUEST })
const commentfailure = (error) => ({ type: FETCH_INCIDENT_COMMENTS.FAILURE, error })
const commentSuccess = (obj)=>({type: FETCH_INCIDENT_COMMENTS.SUCCESS, obj})
const resetComments = () => ({ type: RESET_INCIDENT_COMMENTS });

export const IncidentAddComment = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addIncidentCommentRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}

export const getIncidentComments = (requestId,next=null)=>{
  return (dispatch, getState)=>{        
      dispatch(commentRequest())
      return fetchIncidentComments(requestId,next)
      .then(response => {
          let obj={
              "data":response?.data,
              "next":response?.next,
              "isLoadMore": next?true:false
          }
          dispatch(commentSuccess(obj))
          return Promise.resolve(true)
      })
      .catch(error => {
          dispatch(commentfailure(error?.message))
          return Promise.resolve(false)
      })
  }
}

export const clearIncidentComments = () => {
  return (dispatch) => {
    dispatch(resetComments());
  };
}