import {
    BASE_URL, 
    POST,  
    GET,
    GET_ALL_CUSTOMER_LIST,
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";
  

export const fetchAllCustomerList = async (requestId) => {
    const token = await getAccessToken();
    const url = `${BASE_URL}${GET_ALL_CUSTOMER_LIST}`;
    return fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userauth" : `bearer ${token}`
        },
      })
      .then(statusParsing)
      .then(jsonParsing);
  };
  