import { VERIFY_EMAIL} from "../actionTypes";
import { verifyEmail } from "../../../api/userManagement/verifyEmail.service";

const request = () => ({ type: VERIFY_EMAIL.REQUEST });
const failure = (error) => ({ type: VERIFY_EMAIL.FAILURE, error });
const success = (data) => ({ type: VERIFY_EMAIL.SUCCESS,data});


export const verifyEmailAddress= () => {
  return (dispatch, getState) => {
    dispatch(request());
    return verifyEmail()
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res, status:true});
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      });
  };
};
