import AddComment from "./addComment";
import { connect } from "react-redux";
//import { AddOnboardingComment } from "../../../../../redux/actions/userManagement/onboardingAddComment.action";

const mapStateToProps = (state) => {
    return { 
    
    } 
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      // AddComment: (payload)=>{
      //      return dispatch(AddOnboardingComment(payload))
      //   },
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddComment);


