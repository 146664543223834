import { FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node,UPDATE_SIGTRAN_INOUT_MESSAGE_BY_Node_FILTER } from "../../../actions/actionTypes";

const initialState = {
    isLoading:false,
    filters:{},
    data:[]
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node.REQUEST:
            return {...state,data:[], isLoading:true}
        case FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node.FAILURE:
            return {...state,data:[], isLoading:false}
        case FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node.SUCCESS:           
            return {...state, data: [...action.data],isLoading:false} 
        case UPDATE_SIGTRAN_INOUT_MESSAGE_BY_Node_FILTER:   
        return {...state, filters:{...state.filters,...action.filters}}         
        default:
            return state
    }   
}

export default reducer;
