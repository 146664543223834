import { SUBMIT_CUSTOMER_DETAILS} from "../../actions/actionTypes";
import { getAuth } from "firebase/auth";
const initialState = {
  loading: false,
};

const reducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SUBMIT_CUSTOMER_DETAILS.REQUEST:
      return { ...state, loading: true };
    case SUBMIT_CUSTOMER_DETAILS.FAILURE:
    case SUBMIT_CUSTOMER_DETAILS.SUCCESS:
        return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
