
import { FETCH_ALL_CUSTOMER_LIST} from "../actionTypes";
import { fetchAllCustomerList } from "../../../api/userManagement/getAllCustomerList.service";


const requestListRequest = ()=>({type: FETCH_ALL_CUSTOMER_LIST.REQUEST})
const requestListFailure = (error)=>({type: FETCH_ALL_CUSTOMER_LIST.FAILURE, error})
const requestListSuccess = (obj)=>({type: FETCH_ALL_CUSTOMER_LIST.SUCCESS, obj})

export const getAllCustomerList = (next=null)=>{
    return (dispatch, getState)=>{        
        dispatch(requestListRequest()) 
        return fetchAllCustomerList(next)  
        .then(response => {            
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(requestListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(requestListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}