import React, { useState } from "react";
import styles from "./overlay.module.css";
import IMAGES from "../../../../assets/images/images";


const Overlay = React.forwardRef((props, ref)  => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  React.useImperativeHandle(ref, () => ({
    handleClick
  }));
  const styleClassName = `${styles.applybtn} ${props.className} `

  return (
    <div>
      <button className={styleClassName} onClick={handleClick}>
        Apply Filter
        <span>
          <img className={styles.filterIcon} src={IMAGES.filter} alt="checkIcon"></img>
        </span>
      </button>

        {isOpen && (
          <div className={styles.filterbox}>
            <div className={styles.headerContainer}>
                <div className={styles.header}>Apply Filter</div>
                <div className={styles.close} >
                  <img src={IMAGES.close} onClick={handleClick} height={"24"} width={"24"}/>
                </div>
            </div>
           
            {props.children}
          </div>
        )}

    </div>

  );
});

export default Overlay;
