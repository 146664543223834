import React, { useRef, useEffect, useState } from "react";
import styles from "./alerts.module.css";


export const Alert = (props) => {

  const alertRef = useRef(null);  
  useEffect(() => {
    if (props.isAlertPopupOpen) {    
      setTimeout(() => {       
        // After 8 seconds set the show value to false
        props.onClose();
      }, props.messageTimeout);
    }
  }, [props.isAlertPopupOpen]);
  const styleClassName = `${props.isFormOpen ? styles.form : styles.container} ${styles.animateToast}`;
  //const styleClassName = ` ${styles.container} ${styles.animateToast}`
  return (
    <>
      <div
        style={{
          zIndex: props.isFormOpen ? 9999 : 20,
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",    
        }}
      ><div className={styleClassName} ref={alertRef}>
          <div className={styles.subcontainer}>
            <div className={styles.subcontainerleft}>

              <img className={styles.imageIcon} src={props.image} alt="checkIcon"></img>
              <div>{props.message}</div>
            </div>            
          </div>
        </div></div>
    </>

  );
};