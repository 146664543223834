import Select from 'react-select'

import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en'


let countryList = getCountries().map((country) => ({ code: country, name: en[country] }));
// Sorting this array alphabetically since the getCountries() returns 
// all the country code in alphabetical order but not the country name
countryList.sort(function (a, b) {if (a.name < b.name) {return -1;}if (a.name > b.name) {return 1;}return 0;});

const dropdownStyle = {
    control: (provided, state)=> {
        const {selectProps: {hasError}, isDisabled} = state
       
        return (
            {...provided, backgroundColor: isDisabled ? "#fff" : "#fff",cursor: 'pointer', borderRadius: 5, height: 48,borderWidth: 1, borderColor: hasError? "#CD3500" : "#979797"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#333333", fontFamily: "VodafoneRg-Regular", fontSize:18}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#333333", fontFamily: "VodafoneRg-Regular", fontSize:18}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    //container: (provided, state)=> ({...provided, width: 500,}),
    container: (provided, state)=> {
        const {selectProps: {customStyles}} = state
        return (
            {...provided, ...customStyles}
        );
    },
}
let obj={
    width:500 
}
const CountryCode = ({ value, onChange, labels, ...rest }) => ( 
    <select
    styles={dropdownStyle}
      {...rest}
      value={value}
      onChange={event => onChange(event.target.value || undefined)}>
      <option value="">
       Country
      </option>      
      {countryList.map(country => (
        <option key={country.name} value={country.code}>  
          {country.name} +{getCountryCallingCode(country.code)}
        </option>
      ))}
    </select>
  )

export default CountryCode;