import NewUser from "./NewUser";
import {connect} from "react-redux";
import { getUser } from "../../../../redux/actions/userManagement/userViewer.action";
import { createUser } from "../../../../redux/actions/userManagement/newUser.action";
import { openAlertPopup } from "../../../../redux/actions/Alert/alert.action";
import { updateUserPermissions } from "../../../../redux/actions/userManagement/updatePermissions.action";

const mapStateToProps = (state) => {
    return {
        functionalRoles:state.functionalRoles.roles,
        myRights :state.myRights.myRights,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (payload) => {
            return dispatch(createUser(payload))
        },
        getUser: (type,custID,searchKey,next=null) => {
            return dispatch(getUser(type,custID,searchKey,next))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        },
        updateUserPermissions: (payload,id) => {
            return dispatch(updateUserPermissions(payload,id))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewUser);

