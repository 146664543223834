import {
    BASE_URL,
    GET,
    POST,
    GET_WEATHERMAP_DATA,
    GET_WEATHERMAP_FILTER_VALUES
} from "../../constants";
import { statusParsing, jsonParsing } from "../../utils";
import { getAccessToken } from "../../../authActions/authActions";


export const fetchPeakInOutByRouter = async(filter) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${GET_WEATHERMAP_DATA}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
      body: JSON.stringify(filter)
    })
    .then(statusParsing)
    .then(jsonParsing);
};
export const fetchVolumeInOutByRouter = async(filter) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${GET_WEATHERMAP_DATA}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
      body: JSON.stringify(filter)
    })
    .then(statusParsing)
    .then(jsonParsing);
  };

export const fetchWeatherMapFilterValues = async(cp_name) => {
    const token = await getAccessToken();
    let resource = `${BASE_URL}${GET_WEATHERMAP_FILTER_VALUES}`;
    /*if(cp_name){
        resource=`${BASE_URL}${GET_WEATHERMAP_FILTER_VALUES}?cp_name=${cp_name}`
    }else{
        resource=`${BASE_URL}${GET_WEATHERMAP_FILTER_VALUES}`;
    }*/
    return fetch(resource, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);
  };

  