import { FETCH_WEATHERMAP_FILTER_VALUES } from "../../../actions/actionTypes";

const initialState = {
    isLoading:false,
    filters:{}
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_WEATHERMAP_FILTER_VALUES.REQUEST:
            return {...state,filters:{}, isLoading:true}
        case FETCH_WEATHERMAP_FILTER_VALUES.FAILURE:
            return {...state,filters:{}, isLoading:false}
        case FETCH_WEATHERMAP_FILTER_VALUES.SUCCESS:           
            return {...state, filters:action.data,isLoading:false}        
        default:
            return state
    }   
}

export default reducer;
