import { FETCH_SERVICES} from "../actionTypes";
import { fetchServices } from "../../../api/newService/newService.service";

const request = () => ({ type: FETCH_SERVICES.REQUEST });
const failure = (error) => ({ type: FETCH_SERVICES.FAILURE,error });
const success = (data) => ({ type: FETCH_SERVICES.SUCCESS,data});

export const getServices = () => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchServices()
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve(true);

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
