import {CLOSE_ALERT_POPUP,OPEN_ALERT_POPUP} from "../../actions/actionTypes"

const initialState = {
    isAlertPopupOpen: false,  
    alertDetails : {} 
};

const reducer = (state = initialState, action) => {  
    switch (action.type) {
        case CLOSE_ALERT_POPUP:
            return { ...state, isAlertPopupOpen:false, alertDetails: {}};
        case OPEN_ALERT_POPUP:         
            return { ...state, isAlertPopupOpen:true, alertDetails:action.payload};       
        default:
            return state
    }
}

export default reducer;