import { FETCH_CUSTOMER_DETAILS,  } from "../../actions/actionTypes";

const initialState = {
  loading: false,
 customer:null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_DETAILS.REQUEST:
      return { ...state, loading: true };
    case FETCH_CUSTOMER_DETAILS.FAILURE:
      return { ...state, loading: false };
    case FETCH_CUSTOMER_DETAILS.SUCCESS:
      return {
        ...state,
        customer: action.customer,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
