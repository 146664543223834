import AddServiceRequest from "./addServiceRequest";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    myRights : state.myRights.myRights
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceRequest);
