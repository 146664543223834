import React from "react";
import styles from "./banners.module.css"


export const Banner=(props)=>{
    return(
        <div className={styles.bannerContainer}>
            <img src={props.image} className={styles.image} />
            <div className={styles.text}>{props.text}</div>
        </div>
    )
}