import moment from 'moment-timezone';

export const customGroupBy = (array,key) => {
    return array.reduce((acc, currentValue) => {
        let groupKey = currentValue[key];
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
    }, {});
}

export const getLabelFromValue=(array,value)=>{
    var result = array.find(item => item.value === value);
    return result?.label;
}

export const formatDate = (unixTimestamp) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.unix(unixTimestamp).tz(userTimezone).format('DD MMM YYYY HH:mm');
}
export const futureDate = (unixTimestamp) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const futureTimestamp = moment.unix(unixTimestamp).add(48, 'hours');
    return futureTimestamp.tz(userTimezone).format('DD MMM');
}

export const dueDate = (unixTimestamp) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const futureTimestamp = moment.unix(unixTimestamp).add(10, 'days');
    return futureTimestamp.tz(userTimezone).format('DD MMM');
}

export const convertToTimestampObject = (dateTimeString) => {
    if (!dateTimeString) {
        return null;
    }
    const timezone = moment.tz.guess();
    const timestamp = moment(dateTimeString)?.unix();
    return {
      timestamp: timestamp,
      timezone: timezone
    };
  };