import React from "react";
import styles from "./InactiveUser.module.css";
import {
  LoginChildContainer,
  LoginContainer,
} from "../../components/Container/Containers";
import IMAGES from "../../assets/images/images";
import { LinkButton } from "../../components/button/buttons";
import { logOut } from "../../authActions/authActions";

const InactiveUser = () => {
  const Logo = () => {
    return (
      <div className={styles.headerLogo}>
        <img className={styles.logo} src={IMAGES.logo} alt="Logo" />
      </div>
    );
  };

  const Header = () => {
    return <div className={styles.headerText}>IPXplore</div>;
  };

  return (
    <>
      <LoginContainer>
        <LoginChildContainer>
          <Logo />
          <Header />
          <div className={styles.inactiveUserContainer}>
             Your account is inactive since you haven't logged in to the Customer Portal for past 90 days. Please contact utsav.burman@vodafone.com to re-activate your account.
          </div>
          <div className={styles.linkbtn}>
          <LinkButton className={styles.resetLink}
                    title={"Click here to sign in with a different user"}
                    onClick={() => {
                      logOut();
                    }}
                />
          </div>
        </LoginChildContainer>
      </LoginContainer>
    </>
  );
};
export default InactiveUser;
