import { BASE_URL, UPLOAD_ATTACHMENT, GET, POST,DOWNLOADS,COMMENT, INCIDENT_REQUEST, SERVICE_LIST } from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";

export const uploadAttachments = async({file, name})=>{
   
    const token = await getAccessToken();
    const resource = `${BASE_URL}${UPLOAD_ATTACHMENT}`
    var formdata = new FormData();
    formdata.append("file", file);
    var requestOptions = {
        method: POST,
        body: formdata,
        redirect: 'follow',
        headers:{
            "userauth":`bearer ${token}`
        }
    };

    return fetch(resource, requestOptions)
    .then(statusParsing)
    .then(jsonParsing)
}


/*export const downloadFile = async (originalFileName,uniqueFileName) => {
    const token = await getAccessToken();    
    const url = `${BASE_URL}/${DOWNLOADS}?originalFileName=${originalFileName}&uniqueFileName=${uniqueFileName}`;  
    const aa = await fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userauth" : `bearer ${token}`
        },
      })
      var response = await aa.json(); 
      return  response?.fileData;
};*/

  export const downloadFile = async (originalFileName,uniqueFileName) => {
        const token = await getAccessToken();
        const url = `${BASE_URL}/${DOWNLOADS}?originalFileName=${originalFileName}&uniqueFileName=${uniqueFileName}`;  
        fetch(url, {
            method: GET,
            headers: {
                "userauth": `bearer ${token}`
            },
        })
        .then(response => response.blob())
        .then(blob => {
            let fileExtension=originalFileName.split('.').pop();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            //link.setAttribute('download', 'file.'+fileExtension); //or any other extension
            link.setAttribute('download', originalFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            return Promise.resolve("Success");
        }).catch((error) => {
            return Promise.reject(error?.message);
        })
    };

    export const incidentDownloadFile = async (originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId) => {
        const token = await getAccessToken();
        const url = `${BASE_URL}${INCIDENT_REQUEST}/download-attachment?attachmentId=${attachmentId}&graphInternetMessageId=${graphInternetMessageId}&messageId=${messageId}&originalFileName=${originalFileName}&uniqueFileName=${uniqueFileName}`;
        fetch(url, {
            method: GET,
            headers: {
                "userauth": `bearer ${token}`
            },
        })
        .then(response => response.blob())
        .then(blob => {
            let fileExtension=originalFileName.split('.').pop();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            //link.setAttribute('download', 'file.'+fileExtension); //or any other extension
            link.setAttribute('download', originalFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            return Promise.resolve("Success");
        }).catch((error) => {
            return Promise.reject(error?.message);
        })
    };

    export const serviceRequestDownloadFile = async (originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId) => {
        const token = await getAccessToken();
        const url = `${BASE_URL}${SERVICE_LIST}/download-attachment?attachmentId=${attachmentId}&graphInternetMessageId=${graphInternetMessageId}&messageId=${messageId}&originalFileName=${originalFileName}&uniqueFileName=${uniqueFileName}`;
        fetch(url, {
            method: GET,
            headers: {
                "userauth": `bearer ${token}`
            },
        })
        .then(response => response.blob())
        .then(blob => {
            let fileExtension=originalFileName.split('.').pop();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            //link.setAttribute('download', 'file.'+fileExtension); //or any other extension
            link.setAttribute('download', originalFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            return Promise.resolve("Success");
        }).catch((error) => {
            return Promise.reject(error?.message);
        })
    };