import React, { useId,useEffect, useState } from "react";
import styles from "./GenericInformation.module.css";
import {
  ChildContainer,
  Container,
} from "../../components/Container/Containers";
import { Banner } from "../../components/banner/Banners";
import IMAGES from "../../assets/images/images";
import { Map,Map1 }  from "../../components/map/Map";
import {USER_TYPE} from "../../utils/constants/userConstants";
import {Breadcrumbs} from "../../components/breadcrumbs/Breadcrumbs";
import { Field, Form, Formik,useField,ErrorMessage } from "formik";
import { Loader } from "../../components/loader/Loader";
import { GenericInfoEnums } from "../../utils/constants/genericInfoConstant";
import { Popover,ArrowContainer } from 'react-tiny-popover'
import { Incident_Service_Type_Values } from "../../utils/constants/incidentConstants";

const Tooltip = ({children,text})=>{
  let [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'right']} // preferred positions by priority

      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#ffffff'}
          arrowSize={10}
          className='popover-arrow-container'
          arrowClassName='popover-arrow'
        >
         <div
        className={styles.tooltipBody}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
       {text}
      </div>
        </ArrowContainer>
      )}
      
     
    >
      <div
        onMouseLeave={() => setIsPopoverOpen((isPopoverOpen = false))}
        onMouseOver={() => setIsPopoverOpen((isPopoverOpen = true))} 
      >
        {children}
      </div> 
    </Popover>
  );
}

const Title = ({title}) => {
  return <div className={styles.title}>{title}</div>;
};

const LegendItem=({icon,label,...props})=>{
  return(
    <div className={styles.legendItem}>
      <img className={styles.legendIcon} src={icon} />
      <div className={styles.legendlabel}>{label}</div>
    </div>
  )
}

const  Legends = ({userType,availedService,defaultTierfiltersInternal,defaultFiltersCustomer}) => {  
  return (  
    <div className={styles.legendContainer}>
      {
        userType===USER_TYPE.CUSTOMER && availedService && availedService.includes(Incident_Service_Type_Values.IPX_Service) ? defaultFiltersCustomer.map((item,index)=>{
          return(
            <LegendItem key={index} icon={item.icon} label={item.label}/>
          )
        }):userType===USER_TYPE.CUSTOMER && availedService && availedService.includes(Incident_Service_Type_Values.IVC_Service) ? 
        <LegendItem icon={IMAGES.otherTierIcon} label={"Vodafone MPLS"}/>
        :defaultTierfiltersInternal.map((item,index)=>{
          return(
            <LegendItem key={index} icon={item.icon} label={item.label}/>
          )
        })
      }
     
    </div>  
  );
}

const GenericInformationMap = ({pops,showMyConnectivity,...props}) => {
  return (
    <React.Fragment>
        <Map markers={pops} showMyConnectivity={showMyConnectivity}/>
    </React.Fragment>
      
  );
};

const InfoGraphicDetails = ({PoPCount,...props}) => {
  return (
    <div className={styles.InfoGraphicDetailsContainer}>
      <div className={styles.InfoGraphicDetails_Item}>
        <div className={styles.InfoGraphicDetails_Lable}>Total PoPs</div>
        <div className={styles.InfoGraphicDetails_Count}>{PoPCount}</div>
      </div>
    </div>
  )
}

const MapFilters = ({ uniqueTiers,availedService, applyCustomerFilter,applyInternalFilter, myRights, isLoading,defaultTierfiltersInternal,defaultFiltersCustomer,initialValues, ...props }) => {

  const [activeIndex,setActiveIndex]=useState(-1);

  const handleSubmit = (values) => {
    //console.log("handleSubmit", values);

    if (myRights.userType === USER_TYPE.CUSTOMER) {
      applyCustomerFilter(values?.customerFilters)
    } else {
      applyInternalFilter(values?.internalFilters)
    }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, values }) => (<Form>
          <div className={styles.mapFilterChipsContainer}>
                <>
                  {
                    myRights.userType === USER_TYPE.CUSTOMER ?  defaultFiltersCustomer?.map((filter, index1) => (
                      <>
                        {
                          availedService && availedService.includes(Incident_Service_Type_Values.IPX_Service) ?
                            <Tooltip text={filter.label}>
                              <div className={styles.mapCheckboxChips}>
                                <Field
                                  id={index1}
                                  type="checkbox"
                                  name="customerFilters"
                                  className={styles.radioInput}
                                  value={filter.value}
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleSubmit()
                                  }}
                                />
                                <label
                                  htmlFor={index1}
                                  className={styles.radioLabel}
                                  style={{
                                    backgroundColor: values.customerFilters.includes(filter.value) ?
                                      filter.activeColor : activeIndex == index1 ? filter.onHover : '#ffffff'
                                  }}
                                  onMouseLeave={() => setActiveIndex(-1)}
                                  onMouseOver={() => setActiveIndex(index1)}
                                >
                                  {filter.label}
                                </label>
                              </div>
                            </Tooltip> : null
                        }
                      </>
                    )):
                    defaultTierfiltersInternal?.map((filter, index) => (
                      <Tooltip text={filter.label}>
                        <div className={styles.mapCheckboxChips}>
                          <Field
                            id={index}
                            type="checkbox"
                            name="internalFilters"
                            className={styles.radioInput}
                            value={filter.value}
                            onChange={(e) => {
                              handleChange(e)
                              handleSubmit()
                            }}
                          />
                          <label
                            htmlFor={index}
                            className={styles.radioLabel}
                            style={{ 
                              backgroundColor: values.internalFilters.includes(filter.value) ? 
                                                filter.activeColor : activeIndex==index?filter.onHover:'#ffffff'
                            }}
                            onMouseLeave={() => setActiveIndex(-1)}
                            onMouseOver={() => setActiveIndex(index)}
                          >
                            {filter.label}
                          </label>
                        </div>
                        </Tooltip>
                      ))
                  }
                </>
               
          </div>
        </Form>)}
      </Formik>
    </React.Fragment>
  );
};

const GenericInformation = (props) => {
  let [markers,setMarkers]=useState([]);
  let [uniqueTiers, setUniqueTiers]=useState([]);
  let [filteredMarkers, setFilteredMarkers]=useState([]);
  let [showMyConnectivity, setShowMyConnectivity]=useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const {pops,myRights,loadingPOPs} =props;

  let availedService =props?.myRights?.customerUserMetaInfo?.myServices?.map((service) => service?.serviceType?.value);
  // set default filters data=============
    const defaultTierfiltersInternal = [{
      label: 'Vodafone IPX',
      value: GenericInfoEnums.Tier1,
      icon: IMAGES.tier1Icon,
      activeColor:'#00B0CA',
      onHover:'#91CFD8'
    },
    {
      label: 'IPX Connectivity',
      value: GenericInfoEnums.Tier2,
      icon: IMAGES.tier2Icon,
      activeColor:'#8AB400',
      onHover:'#C1D091'
    },
    {
      label: 'Other',
      value: GenericInfoEnums.OtherTiers,
      icon: IMAGES.otherTierIcon,
      activeColor:'#E60000',
      onHover:'#E29191'
    }
    ]

    const defaultFiltersCustomer = [{
      label: 'My Connectivity',
      value: GenericInfoEnums.MyConnectivity,
      icon: IMAGES.availedTierIcon,
      activeColor:'#FECB00',
      onHover:'#EAD891'
    },
    {
      label: 'Vodafone MPLS',
      value: GenericInfoEnums.All,
      icon: IMAGES.otherTierIcon,
      activeColor:'#E60000',
      onHover:'#E29191'
    }]

    let defaultInitialValues = {}    

    if (myRights.userType === USER_TYPE.CUSTOMER) {  // add on for customer
      defaultInitialValues.customerFilters = [GenericInfoEnums.MyConnectivity,GenericInfoEnums.All]
      defaultInitialValues.internalFilters = []
    }else{
      defaultInitialValues.customerFilters = []
      defaultInitialValues.internalFilters = [GenericInfoEnums.Tier1, GenericInfoEnums.Tier2, GenericInfoEnums.OtherTiers]
    }


  //=========================================

  // const breadcrumbsOption = [{label:"Generic Information",link:""}];
  useEffect(() => {
    let cp_name = props.myRights?.cpName;
    props.getGenericInfo(cp_name);
  }, [])

  useEffect(()=>{
    setUniqueTiers(uniqueTiers=[...new Set(pops.map(item => item.tier))])

    // add marker icons as per tier 
    let popArray = [];
    if(myRights.userType==USER_TYPE.INTERNAL){
      popArray=pops?.map(item => {
        if (item.tier == GenericInfoEnums.Tier1) {
          return { ...item, marker: IMAGES.tier1Icon }
        } else if (item.tier == GenericInfoEnums.Tier2) {
          return { ...item, marker: IMAGES.tier2Icon }
        } else {
          return { ...item, marker: IMAGES.otherTierIcon }
        }
      })
   }else{
      popArray=pops?.map(item => {
          return { ...item, marker: IMAGES.otherTierIcon }
      })
   }

    setMarkers(markers=popArray); // to keep original copy of markers

    if(myRights.userType==USER_TYPE.INTERNAL){
      setShowMyConnectivity(showMyConnectivity=false);
      applyInternalFilter(defaultInitialValues.internalFilters);
    }else{
      setShowMyConnectivity(showMyConnectivity=true);
      applyCustomerFilter(defaultInitialValues.customerFilters);
    }

  },[pops])

  const applyCustomerFilter=(customerFiters)=>{
    // processing tier filter to replace others  by actual tiers
    let updated_TierFilters= [];
      let _index = customerFiters.indexOf(GenericInfoEnums.All);
      if (_index !== -1) {
        updated_TierFilters = [...uniqueTiers]
      }
    
    //==============================
    let newMarkers= []; 
    let _index1 = customerFiters.indexOf(GenericInfoEnums.MyConnectivity);
      if(_index1 !== -1){
        setShowMyConnectivity(showMyConnectivity=true);
      }else{
        setShowMyConnectivity(showMyConnectivity=false);
      }
      newMarkers= markers.filter(function(marker) {
        return updated_TierFilters.includes(marker?.tier) || (_index1 !== -1 && marker?.isAvailedService==true) ;
      });
    
    setFilteredMarkers(filteredMarkers=newMarkers);
  }

  const applyInternalFilter=(tierFilters)=>{

    // processing tier filter to replace others  by actual tiers
    let updated_TierFilters= [];
    if (tierFilters.length) {
      const otherTiersValues = uniqueTiers.filter(item => ![GenericInfoEnums.Tier1, GenericInfoEnums.Tier2].includes(item));
      updated_TierFilters = JSON.parse(JSON.stringify(tierFilters)) // deep copy
      let _index = updated_TierFilters.indexOf(GenericInfoEnums.OtherTiers);
      if (_index !== -1) {
        updated_TierFilters.splice(_index, 1);
        updated_TierFilters = [...updated_TierFilters, ...otherTiersValues]
      }
    }
    //==============================
    let newMarkers= []; 
      newMarkers= markers.filter(function(marker) {
        return updated_TierFilters.includes(marker?.tier);
      });
    
    setFilteredMarkers(filteredMarkers=newMarkers);
  }

  return (
    <>
      {/*<Container>*/}
      {/*<Banner image={IMAGES.banner} text={"Dashboard"}></Banner>*/}
      {/* <ChildContainer>
      <Breadcrumbs options={breadcrumbsOption}/>
      </ChildContainer> */}
      {/*<ChildContainer> */}
      {/*  <Title  title={props.myRights?.userType===USER_TYPE.INTERNAL?"Vodafone Point Of Presence":"Vodafone Point Of Presence"}/>*/}
      <MapFilters availedService={availedService} uniqueTiers={uniqueTiers} applyCustomerFilter={applyCustomerFilter} applyInternalFilter={applyInternalFilter} myRights={myRights} defaultTierfiltersInternal={defaultTierfiltersInternal} defaultFiltersCustomer={defaultFiltersCustomer} initialValues={defaultInitialValues} />
      <GenericInformationMap pops={filteredMarkers} showMyConnectivity={showMyConnectivity} isLoadingPOPs={loadingPOPs} />
      <Legends availedService={availedService} userType={props.myRights?.userType} defaultTierfiltersInternal={defaultTierfiltersInternal} defaultFiltersCustomer={defaultFiltersCustomer}/>
      <InfoGraphicDetails PoPCount={filteredMarkers?.length?filteredMarkers.length:'0'}/>
      {/*</ChildContainer> */}
      {/*<Container>*/}
    </>
  );
};

export default GenericInformation;
