import { SUBMIT_SERVICE_REQUESTS} from "../actionTypes";
import { addserviceRequest } from "../../../api/incidentManagement/raiseServiceRequest.service";

const request = () => ({ type: SUBMIT_SERVICE_REQUESTS.REQUEST });
const failure = (error) => ({ type: SUBMIT_SERVICE_REQUESTS.FAILURE, error });
const success = () => ({ type: SUBMIT_SERVICE_REQUESTS.SUCCESS });

export const SubmitService = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addserviceRequest(payload)
      .then((res) => {
        dispatch(success(res));
       // return Promise.resolve(true);
        return Promise.resolve({res:res,status:true});
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        //return Promise.resolve(false);
        return Promise.resolve({error:error?.message,status:false});
      });
  };
};