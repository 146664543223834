import React from "react";
import {Container} from "../../components/Container/Containers";
import NoAccess from "../../modules/noAccess/noAccess";


const RouteGuard = ({ children, canAccess, ...props }) => {
  if (canAccess) {
    return children;
  } else {
    return <NoAccess>
              You do not have access.
           </NoAccess>;
  }
};
export default RouteGuard;
