import IncidentDetail from "./incidentDetail";
import {connect} from "react-redux";
import { getIncidentDetail } from "../../../../redux/actions/incidentManagement/incidentDetail.action";
import { setIncidentPreviewMode } from "../../../../redux/actions/incidentManagement/raiseIncident.action";
import { clearIncidentComments, getIncidentComments } from "../../../../redux/actions/incidentManagement/incidentComment.action";
import { incidentDownloadFiles } from "../../../../redux/actions/fileAttachment/fileAttachment.actions";

const mapStateToProps = (state) => {
    return {
        incidentDetail: state.incidentDetail,
        isIncidentPreviewModeOn:state.IncidentManagement.isIncidentPreviewModeOn,
        next: state.incidentComment.next,
        comments: state.incidentComment.comments,
        isCommentFormOpen:state.incidentComment.isCommentFormOpen,
        isCommentsLoading:state.incidentComment.isLoading,
        myRights : state.myRights.myRights
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getIncidentDetail: (requestId)=>{
            return dispatch(getIncidentDetail(requestId))
          },
        setIncidentPreviewMode: (payload) => {
            return dispatch(setIncidentPreviewMode(payload))
        },
        getIncidentComments:(requestId,next=null)=>{
            return dispatch(getIncidentComments(requestId,next))
        },
        incidentDownloadFile:(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId)=>{
            return dispatch(incidentDownloadFiles(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId))
        },
        clearIncidentComments:()=>{
            return dispatch(clearIncidentComments())
        },
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetail);
