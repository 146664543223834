
export const SIGN_IN_STATUS = {
    SUCCESS : "SUCCESS",
    INVALID_CREDENTIALS : "INVALID_CREDENTIALS",
    MFA_DISABLED : "MFA_DISABLED",
    UNVERIFIED_EMAIL: "UNVERIFIED_EMAIL",
    TOO_MANY_REQUEST : "TOO_MANY_REQUEST",
    INVALID_VERIFICATION_CODE:"INVALID_VERIFICATION_CODE",
    MISSING_VERIFICATION_CODE:"MISSING_VERIFICATION_CODE",
    RECAPTCHA_ISSUE:"RECAPTCHA_ISSUE",
    REQUIRES_RECENT_LOGIN:"REQUIRES_RECENT_LOGIN"
}

export const SIGN_IN_ERROR_MESSAGES = {
    SUCCESS : "success",
    INVALID_CREDENTIALS : "Invalid email or password",
    MFA_DISABLED : "Multifactor authentication disabled",
    UNVERIFIED_EMAIL: "Please verify your email",
    TOO_MANY_REQUEST : "Please wait few minutes before you try again",
    INVALID_VERIFICATION_CODE:"Enter the correct verification code sent on your registered mobile number",
    MISSING_VERIFICATION_CODE:"Enter the verification code sent on your registered mobile number",
    RECAPTCHA_ISSUE:"Somthing went wrong. Please refresh the page and try again",
    INVALID_PHONE_NUMBER:"Invalid phone number",
    ALREADY_2FA_ENROLLED:"Phone number is already enrolled for second factor authentication for this account",
    REQUIRES_RECENT_LOGIN:"Too many wrong attempts. Please login again",
    INVALID_APP_CREDENTIALS:"Invalid phone number. Please check and try again."
}
