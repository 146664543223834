import { FETCH_IPX_POPS } from "../../actions/actionTypes";

const initialState = {
    isLoading:false,
    data:[]
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_IPX_POPS.REQUEST:
            return {...state,data:[], isLoading:true}
        case FETCH_IPX_POPS.FAILURE:
            return {...state,data:[], isLoading:false}
        case FETCH_IPX_POPS.SUCCESS:           
            return {...state, data:action.data,isLoading:false}        
        default:
            return state
    }   
}

export default reducer;
