import {
    BASE_URL,
    GET,
    IAM_RIGHTS
} from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchUserData = async (type,custID,searchKey,next="") => {
    const token = await getAccessToken();
   let resource=`${BASE_URL}${IAM_RIGHTS}?type=${type}&custID=${encodeURIComponent(custID)}`;  
   
   if(searchKey){
         resource=`${resource}&searchkey=${encodeURIComponent(searchKey)}`;
    }

   if(next){
         resource=`${resource}&next=${next}`;
   }
    
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth": `Bearer ${token}`
        },
    })
        .then(statusParsing)
        .then(jsonParsing);
      
};
