import { FETCH_EXTENSION_APPROVAL_DETAIL, AM_GRANT_ACCESS } from "../actionTypes"
import { fetchExtensionApprovalDetail, extensionApprovalGrantAccess } from "../../../api/userManagement/extensionApprovalDetail.service"

const detailRequest = ()=>({type: FETCH_EXTENSION_APPROVAL_DETAIL.REQUEST})
const detailFailure = (error)=>({type: FETCH_EXTENSION_APPROVAL_DETAIL.FAILURE, error})
const detailSuccess = (obj)=>({type: FETCH_EXTENSION_APPROVAL_DETAIL.SUCCESS, obj})

const grantAccessRequest = () => ({ type: AM_GRANT_ACCESS.REQUEST })
const grantAccessFailure = (error) => ({ type: AM_GRANT_ACCESS.FAILURE, error })
const grantAccessSuccess = () => ({ type: AM_GRANT_ACCESS.SUCCESS })

export const getExtensionApprovalDetail = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(detailRequest())
        return fetchExtensionApprovalDetail(requestId)
        .then(response => {
            dispatch(detailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(detailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}


export const extensionGrantAccess = (payload,requestId) => {
    return (dispatch, getState) => {
      dispatch(grantAccessRequest());
      return extensionApprovalGrantAccess(payload,requestId)
        .then((res) => {
          dispatch(grantAccessSuccess(res));
          return Promise.resolve({res:res,status:true});
        })
        .catch((error) => {
          dispatch(grantAccessFailure(error?.message));
          return Promise.resolve({error:error?.message,status:false});
        })
    }
  }