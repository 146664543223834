import { FETCH_SERVICE_DETAILS } from "../actionTypes"
import { fetchServiceDetail } from "../../../api/incidentManagement/serviceDetails.service"

const serviceDetailRequest = ()=>({type: FETCH_SERVICE_DETAILS.REQUEST})
const serviceDetailFailure = (error)=>({type: FETCH_SERVICE_DETAILS.FAILURE, error})
const serviceDetailSuccess = (obj)=>({type: FETCH_SERVICE_DETAILS.SUCCESS, obj})

export const getServiceDetail = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(serviceDetailRequest())
        return fetchServiceDetail(requestId)
        .then(response => {
            dispatch(serviceDetailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(serviceDetailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
