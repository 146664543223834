import React, { Component } from "react";
import { useState, useEffect } from "react";
import { SubmitButton, LinkButton } from "../../components/button/buttons";
import { useNavigate } from "react-router-dom";
import styles from "./validateEmail.module.css";
import { sendEmailVerification } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { app } from '../../configuration/gcp-identity-platform-config';
import { Loader } from "../../components/loader/Loader";
import { ChildContainer, Container } from "../../components/Container/Containers";
// import { Alert } from "../../components/alert/alerts";
import IMAGES from "../../assets/images/images";
import { logOut } from "../../authActions/authActions";
import Invalid from "../../components/invalid/invalid";

const ValidateEmail = (props) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: "",
    image: IMAGES.success
  });  
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAlert, setIsAlert] = useState(false);



  const evokeEmailValidation = () => {   
    return new Promise((resolve, reject) => {
      setShowLoader(true);
      props.verifyEmailAddress().then((res)=>{
        setIsSubmitted(true);
        setShowLoader(false);
        // setIsAlert(true);
        // setShowMessage({
        //   message: "Verification Email is sent to your registered email address.",
        //   image: IMAGES.success
        // });
        props.openAlertPopup({
          message: "Verification Email is sent to your registered email address.",
          image: IMAGES.success
        });
        console.log("please proceed for login..")

      }).catch(ex=>{
        // setIsAlert(true);
        // setShowMessage({
        //   message: "Something went wrong. Please try again later!!",
        //   image: IMAGES.error
        // });
        props.openAlertPopup({
          message: "Something went wrong. Please try again later!!",
          image: IMAGES.error
        });
        setShowLoader(false);
        setIsSubmitted(false);

      });
     

    })
  };

  return (
        <Invalid
        logo={IMAGES.logo} 
        headerText="IPXplore"
        >
        <div className={styles.headerText}>
          We just need to verify your email address before you can access IPX Portal.
          We have sent an email to your registered email address.
          <div  className={styles.or}>OR</div>          
          <SubmitButton
            type="button"
            style={styles.submitButton}
            disabled={isSubmitted}
            onClick={() => {
              evokeEmailValidation();
            }}
          >
            Resend Email Verification Link
          </SubmitButton>
           <LinkButton className={styles.resetLink}
                    title={"If you have already verified your Email Address, Please Click here to login again."}
                    onClick={() => {
                      logOut();
                    }}
                />
          {showLoader ? (          
            <Loader />          
        ) : (
          <></>
        )}
        {/* {isAlert && (
            <div
              style={{
                zIndex: 20,
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
              }}
            >
                    <Alert 
                      image={showMessage.image}
                      message={showMessage.message}
                      onClose={setIsAlert}
                    />
                  </div>
                )} */}
        
        </div>
        </Invalid>  
  );
}
export default ValidateEmail;