import {CLOSE_ALERT_POPUP,OPEN_ALERT_POPUP} from "../actionTypes"


export const openAlertPopup= (payload)=>{   
    return {
        type: OPEN_ALERT_POPUP,             
        payload        
    }
}

export const closeAlertPopup= (payload)=>{
    return {
        type: CLOSE_ALERT_POPUP,
        payload
    }
}