import { connect } from "react-redux";
import AccountApprovalViewer from './accountApprovalListViewer';
import { getAccountAccessApprovalList } from "../../../../../../redux/actions/userManagement/accountAccessApprovalList.action";

const mapStateToProps = (state) => {
  // console.log("state",state);
  return {
    requestList : state.accountAccessApproval.data,
    isLoading : state.accountAccessApproval.isLoading,
    next : state.accountAccessApproval.next
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getAccountAccessApprovalList: (next=null)=>{
      return dispatch(getAccountAccessApprovalList(next))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountApprovalViewer);
