import { FETCH_ONBOARDING_REQUEST_DETAIL } from "../actionTypes"
import { fetchOnBoardingRequestDetail } from "../../../api/userManagement/onBoardingRequestDetail.service"

const onBoardingRequestDetailRequest = ()=>({type: FETCH_ONBOARDING_REQUEST_DETAIL.REQUEST})
const onBoardingRequestDetailFailure = (error)=>({type: FETCH_ONBOARDING_REQUEST_DETAIL.FAILURE, error})
const onBoardingRequestDetailSuccess = (obj)=>({type: FETCH_ONBOARDING_REQUEST_DETAIL.SUCCESS, obj})

export const getOnBoardingRequestDetail = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(onBoardingRequestDetailRequest())
        return fetchOnBoardingRequestDetail(requestId)
        .then(response => {
            dispatch(onBoardingRequestDetailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(onBoardingRequestDetailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
