import {
    BASE_URL,
    GET,
    VERIFY_EMAIL
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import {getAccessToken} from "../../authActions/authActions";
  
  export const verifyEmail = async() => {
    const token = await getAccessToken();
    let resource = `${BASE_URL}${VERIFY_EMAIL}`;
    return fetch(resource, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      }
    })
    .then(statusParsing)
    .then(jsonParsing);     

};

  
  