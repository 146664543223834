import { FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER,UPDATE_WEATHERMAP_PEAK_INOUT_BY_ROUTER_FILTER} from "../../actionTypes";
import { fetchPeakInOutByRouter } from "../../../../api/dashboard/weatherMap/weatherMap.service";

const request = () => ({ type: FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER.REQUEST });
const failure = (error) => ({ type: FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER.FAILURE, error });
const success = (data) => ({ type: FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER.SUCCESS,data});

export const updatePeakInOutByRouterFilters= (filters)=>{
    return {
        type: UPDATE_WEATHERMAP_PEAK_INOUT_BY_ROUTER_FILTER,
        filters
    }
}

export const getPeakInOutByRouter = (filters) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchPeakInOutByRouter(filters)
      .then((res) => {
        dispatch(success(res?.result));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
