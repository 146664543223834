import { FORGOT_PASSWORD} from "../actionTypes";
import { resetPassword } from "../../../api/userManagement/forgotPassword.service";

const request = () => ({ type: FORGOT_PASSWORD.REQUEST });
const failure = (error) => ({ type: FORGOT_PASSWORD.FAILURE, error });
const success = (data) => ({ type: FORGOT_PASSWORD.SUCCESS,data});


export const forgotPassword= (emailAddress) => {
  return (dispatch, getState) => {
    dispatch(request());
    return resetPassword(emailAddress)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});
      })
      .catch((error) => {
        //dispatch(failure({error:error?.message,status:false}));
        //return Promise.resolve(error?.message);
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});

      });
  };
};
