export const DIAMETER = "DIAMETER"
export const SIGTRAN = "SIGTRAN"
export const TRANSPORT = "TRANSPORT"
export const DNS = "DNS"
export const GRX = "GRX"
export const serviceStatusColor =  {
    '#FFF1BB': 'received',  
    '#FFE1AB': 'in-progress',
    '#D5F9FF': 'pending-customer-action',
    '#C7FFE6' : 'completed',
    '#D4D4D4' : 'on-hold',
    '#E8E8E8' : 'closed'
}
export const SERVICE_OPTIONS = {
    "avg_monthly_dtr_volumes" : "Avg Monthly DTR Volumes",
    "dns_number" : "How Many DNS",
    "avg_monthly_msu_volumes" : "Avg Monthly MSU Volumes",
    "top_destinations" : "Top Destinations",
    "allocated_capacity" : "Allocated Capacity",
    "type" : "Type"
}
export const MY_SERVICE_TABS = {
    "LIVE_SERVICES":"Live Services",
    // "MY_DEMANDS":"My Demands",
    "DEMANDS":"Demands"
}

export const SERVICE_STATUS_OPTIONS = [    
    { label: "Received", value: "received" },
    { label: "In Progress", value: "in-progress" },
    { label: "Pending Customer Action", value: "pending-customer-action" },
    { label: "Completed", value: "completed" },
    { label: "Closed", value: "closed" },
    { label: "On Hold", value: "on-hold" },
  ]

  export const SERVICE_REQUEST_OPTIONS = {
    "CREATE_NEW":"Create New Demand",
    "AMMEND_EXISTING_SERVICE":"Amend Existing Service"
}

export const LIVE_SERVICE_STATUS={
    "No_Shutdown":"No Shutdown",
    "Live":"Live",
    "Shutdown":"Shutdown",
}