import {
    BASE_URL,
    PATCH,
    IAM_RIGHTS
} from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const editUserPermissions = async (payload, id) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${IAM_RIGHTS}/${id}`
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type": "application/json",
            "userauth": `bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
        .then(statusParsing)
        .then(jsonParsing)
}