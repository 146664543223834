import NewService from "./NewService";
import { connect } from "react-redux";
import { addService } from "../../../redux/actions/newService/service.actions";
import { SubmitRequst } from "../../../redux/actions/newService/submitServiceRequest.actions";
import { getLocations } from "../../../redux/actions/newService/locations.action";
import { getCustomers } from "../../../redux/actions/newService/customers.action";
import { closeNewServiceForm, setIsAmendMode } from "../../../redux/actions/newService/service.actions";
import { getServicesList } from "../../../redux/actions/newService/servicesList.actions";
import { getServices } from "../../../redux/actions/newService/customerService.action";
import { getLiveServices } from "../../../redux/actions/liveServies/liveServices.action";
import { openAlertPopup } from "../../../redux/actions/Alert/alert.action";

const mapStateToProps = (state) => {
    return {
      locations:state.newService.locations.locations.locations,
      organizations:state.newService.customers.customers.customers,
      myRights:state.myRights.myRights,
      services:state.newService.services.services.services,
      isServiceFromOpen:state.newService.service.isServiceFromOpen,
      isAmendMode:state.newService.service.isAmendMode,
      pops:state.liveServices.locations,
      liveServicesData:state.liveServices.data,
      initialValue:state.newService.service.initialValue
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getLocations: () => {
        return dispatch(getLocations())
      },
      getCustomers: (payload) => {
        return dispatch(getCustomers())
      },
      submitRequest: (payload) => {
        return dispatch(SubmitRequst(payload))
      }, 
      closeNewServiceForm: () => {
        return dispatch(closeNewServiceForm())
      },
      getServicesList: (status = '', searchKey = '',next=null)=>{
        return dispatch(getServicesList(status, searchKey,next))
    },
      getServices: ()=>{
        return dispatch(getServices())
      },
      getLiveServices: (cp_name,searchKey,filter) => {
        return dispatch(getLiveServices(cp_name,searchKey, filter))
      },
      setIsAmendMode: (mode) => {
        return dispatch(setIsAmendMode(mode))
      },
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      }
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(NewService);


