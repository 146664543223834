import { FETCH_ONBOARDING_APPROVAL_DETAIL } from "../actionTypes"
import { fetchonBoardingApprovalDetail } from "../../../api/userManagement/onBoardingApprovalDetail.service"

const onBoardingApprovalDetailRequest = ()=>({type: FETCH_ONBOARDING_APPROVAL_DETAIL.REQUEST})
const onBoardingApprovalDetailFailure = (error)=>({type: FETCH_ONBOARDING_APPROVAL_DETAIL.FAILURE, error})
const onBoardingApprovalDetailSuccess = (obj)=>({type: FETCH_ONBOARDING_APPROVAL_DETAIL.SUCCESS, obj})

export const getOnBoardingApprovalDetail = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(onBoardingApprovalDetailRequest())
        return fetchonBoardingApprovalDetail(requestId)
        .then(response => {
            console.log("response",response)
            dispatch(onBoardingApprovalDetailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(onBoardingApprovalDetailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
