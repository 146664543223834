import { FETCH_MYRIGHTS,UPDATE_MYRIGHTS,UPDATE_IS_LOGGED_IN } from "../actionTypes";
import { fetchMyRights } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: FETCH_MYRIGHTS.REQUEST });
const failure = (error_code) => ({ type: FETCH_MYRIGHTS.FAILURE, error_code });
const success = (myRights) => ({ type: FETCH_MYRIGHTS.SUCCESS, myRights });

export const getMyRights = () => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchMyRights()
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve(true)

      })
      .catch((error) => {
        dispatch(failure(error?.code));
        return Promise.resolve(false)
      });
  };
};


export const updateMyRights=(myRights)=>{
    return  {
      type: UPDATE_MYRIGHTS ,
      myRights
  }
}

export const updateIsLoggedIn=(isLoggedIn)=>{
  return  {
    type: UPDATE_IS_LOGGED_IN ,
    isLoggedIn
}
}
