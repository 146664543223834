import { useState } from 'react';
import { Popover,ArrowContainer } from 'react-tiny-popover'
import styles from './secondaryToolTip.module.css';

const SecondaryTooltip = ({children,text})=>{
    let [isPopoverOpen, setIsPopoverOpen] = useState(false);
    return (
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'right']} // preferred positions by priority
  
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'#ffffff'}
            arrowSize={10}
            className='popover-arrow-container'
            arrowClassName={`popover-arrow  ${styles.popoversecondary}`}
          >
           <div
          className={styles.tooltipBody}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
         {text}
        </div>
          </ArrowContainer>
        )}
        
       
      >
        <div
          onMouseLeave={() => setIsPopoverOpen((isPopoverOpen = false))}
          onMouseOver={() => setIsPopoverOpen((isPopoverOpen = true))} 
        >
          {children}
        </div> 
      </Popover>
    );
  }

export default SecondaryTooltip;