import { FETCH_CUSTOMERS } from "../../actions/actionTypes";

const initialState = {
   customers:[],
   loading:false
};

const reducer = (state=initialState, action)=>{
   
    switch(action.type){
        case FETCH_CUSTOMERS.REQUEST:
            return { ...state, customers: [], loading: true };
        case FETCH_CUSTOMERS.FAILURE:
            return { ...state, customers: [], loading: false };
        case FETCH_CUSTOMERS.SUCCESS:
            return {...state,customers: action.data,loading: false};
        default:
            return state
    }
}

export default reducer;