export const Graphs = {
    DIAMETER: "Diameter",
    SIGTRAN: "Sigtran",
    WEATHERMAPS: "IP Link Utilization"
}

export const Graph_Name = {
  Peak_In_Out: "Peak_In_Out",
  Volume_In_Out: "Volume_In_Out",
  In_Out_Message: "In_Out_Message",
  In_Out_Message_By_SigNode: "In_Out_Message_By_SigNode",
  In_Out_MSU: "In_Out_MSU",
  In_Out_MSU_By_SigNode: "In_Out_MSU_By_SigNode",
}