import { getDiaFinalTypeandFinalDist } from "../../redux/actions/dashboard/diameter/finalTypeandFinalDestination.action";
import { getDiaSigNodeandConectionId } from "../../redux/actions/dashboard/diameter/sigNodeandConnectionId.actions";
import { getSigFinalTypeandFinalDist } from "../../redux/actions/dashboard/sigtran/finalTypeandFinalDestination.action";
import { getSigSigNodeandConectionId } from "../../redux/actions/dashboard/sigtran/sigNodeandConnectionId.actions";
import { getWeatherMapFiltersValue } from "../../redux/actions/dashboard/weatherMap/dropdownsData.action";
import Dashboard from "./Dashboard";
import {connect} from "react-redux";

const mapStateToProps = (state)=>{
    return {
      myRights:state.myRights.myRights,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getDiaFinalTypeandFinalDist: (cp_name) => {
            return dispatch(getDiaFinalTypeandFinalDist(cp_name))
        },
        getDiaSigNodeandConectionId: (cp_name) => {
            return dispatch(getDiaSigNodeandConectionId(cp_name))
        },
        getSigFinalTypeandFinalDist: (cp_name) => {
            return dispatch(getSigFinalTypeandFinalDist(cp_name))
        },
        getSigSigNodeandConectionId: (cp_name) => {
            return dispatch(getSigSigNodeandConectionId(cp_name))
        },
        getWeatherMapFiltersValue: (cp_name) => {
            return dispatch(getWeatherMapFiltersValue(cp_name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);


