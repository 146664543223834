import Comment from "./Comment";
import { connect } from "react-redux";
import { AddComment, closeCommentForm } from "../../../redux/actions/newService/comment.actions";
import { getComments } from "../../../redux/actions/newService/comment.actions";
import { openAlertPopup, closeAlertPopup } from "../../../redux/actions/Alert/alert.action";

const mapStateToProps = (state) => {  
    return { 
      isAlert: false,
      isCommentFormOpen:state.newService.comment.isCommentFormOpen, 
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {     
      addComment: (payload) => {
        return dispatch(AddComment(payload))
      },
      closeCommentForm: () => {
        return dispatch(closeCommentForm())
      },
      getComments:(requestId,next=null)=>{
        return dispatch(getComments(requestId,next))
      },
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      },
      closeAlertPopup: () => {
          return dispatch(closeAlertPopup())
      },
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Comment);


