import { connect } from "react-redux";
import { getAccountAccessRequestList } from "../../../../../../redux/actions/userManagement/accountAccessRequest.action";
import AccountRequestListViewer from './accountRequestListViewer';
const mapStateToProps = (state) => {
  return {
    requestList : state.accountAccessRequest.data,
    isLoading : state.accountAccessRequest.isLoading,
    next : state.accountAccessRequest.next,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getAccountAccessRequestList: (next=null)=>{
      return dispatch(getAccountAccessRequestList(next))
  },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRequestListViewer);
