export const USER_TYPE = {
    "INTERNAL":"internal",
    "CUSTOMER":"customer"
}

export const FUNCTIONAL_ROLES_CONSTANTS = Object.freeze({
    account_manager_internal: {
      label: 'Account Manager',
      value: 'account_manager_internal'
    },
    head_of_sales_internal: {
      label: 'Head of Sales',
      value: 'head_of_sales_internal'
    },
    commercial_manager_internal: {
      label: 'Commercial Manager',
      value: 'commercial_manager_internal'
    },
    head_of_commercial_internal: {
      label: 'Head of Commercial',
      value: 'head_of_commercial_internal'
    },
    engineering_or_technology_internal: {
      label: 'Engineering/Technology',
      value: 'engineering_or_technology_internal'
    },
    billing_operations_internal: {
      label: 'Billing Operations',
      value: 'billing_operations_internal'
    },
    operations_internal: {
      label: 'Operations',
      value: 'operations_internal'
    },
    marketing_internal: {
      label: 'Marketing',
      value: 'marketing_internal'
    },
    regulatory_internal: {
      label: 'Regulatory',
      value: 'regulatory_internal'
    },
    service_manager_internal: {
      label: 'Service Manager',
      value: 'service_manager_internal'
    },
    super_admin_internal: {
      label: 'Super Admin',
      value: 'super_admin_internal'
    },
    product_internal: {
      label: 'Product Team',
      value: 'product_internal'
    }
  })