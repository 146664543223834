import { FETCH_ONBOARDING_REQUEST_LIST } from "../actionTypes";
import { fetchOnboardingRequestList } from "../../../api/userManagement/onboardingRequestList.service";
const requestListRequest = ()=>({type: FETCH_ONBOARDING_REQUEST_LIST.REQUEST})
const requestListFailure = (error)=>({type: FETCH_ONBOARDING_REQUEST_LIST.FAILURE, error})
const requestListSuccess = (obj)=>({type: FETCH_ONBOARDING_REQUEST_LIST.SUCCESS, obj})


export const getRequestList = (next=null)=>{
    return (dispatch, getState)=>{        
        dispatch(requestListRequest())
        return fetchOnboardingRequestList(next)  
        .then(response => {           
            let obj={
                'data':response?.data,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(requestListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(requestListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}