import AddComment from "./addComment";
import { connect } from "react-redux";
import { getIncidentComments, IncidentAddComment } from "../../../redux/actions/incidentManagement/incidentComment.action";
import { openAlertPopup } from "../../../redux/actions/Alert/alert.action";
import { getIncidentDetail } from "../../../redux/actions/incidentManagement/incidentDetail.action";

const mapStateToProps = (state) => {
    return { 
      isAlert: false,
      isCommentFormOpen:state.incidentComment.isCommentFormOpen,
    } 
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      incidentAddComment: (payload) => {
        return dispatch(IncidentAddComment(payload))
      },   
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      },
      getIncidentComments:(requestId,next=null)=>{
        return dispatch(getIncidentComments(requestId,next))
      },
      getIncidentDetail: (requestId)=>{
        return dispatch(getIncidentDetail(requestId))
      },
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddComment);


