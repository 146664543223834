import { FETCH_DIAMETER_INOUT_MESSAGE_BY_Node,UPDATE_DIAMETER_INOUT_MESSAGE_BY_Node_FILTER} from "../../actionTypes";
import { fetchTotalINOutMessagesBySigNode } from "../../../../api/dashboard/diameter/diameter";

const request = () => ({ type: FETCH_DIAMETER_INOUT_MESSAGE_BY_Node.REQUEST });
const failure = (error) => ({ type: FETCH_DIAMETER_INOUT_MESSAGE_BY_Node.FAILURE, error });
const success = (data) => ({ type: FETCH_DIAMETER_INOUT_MESSAGE_BY_Node.SUCCESS,data});

export const updateTotalInOutByNodeFilters= (filters)=>{
    return {
        type: UPDATE_DIAMETER_INOUT_MESSAGE_BY_Node_FILTER,
        filters
    }
}

export const getTotalInOutMessageByNode = (filter,graphType) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchTotalINOutMessagesBySigNode(filter,graphType)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};
