import { useField, Field } from 'formik';
import styles from "./component.module.css";
import {
    FormFieldLabel as Label,
    FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import DropDown from "../../../../components/dropDown/DropDown";
import { TextInput } from "../../../../components/input/inputs";
import Asterisk from '../../../../components/asterisk/Asterisk';


let obj = {
    width: "auto"
}

export const TimeGranularity = ({ title="Time Granularity",name,options, ...props }) => {
  
    return (
        <div className={styles.granularityContainer}>
            <div className={styles.sectionTitle}>
               {title}<Asterisk/>
            </div>
            <div className={styles.granularityOptionContainer}>
                {
                    options.map((item, index) => {
                        return (

                            <div className={styles.granularityOption} key={index}>
                                <label>
                                    <Field type="radio" name={name} value={item} />
                                   <span className={styles.radioLabel}>{item}</span> 
                                </label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const Header = (props) => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerText}>{props.title}</div>
        </div>

    )
}

export const FieldWrapper = ({ children }) => {
    return <div className={styles.fieldContainer}>{children}</div>;
};

export const TimeWindowField = ({ title, options, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    let _options = options.map((item) => ({ value: item.value, label: item.label }));
    return (
        <FieldWrapper>
            <div>
             <Label text={title} /><Asterisk/>
            </div>
            <DropDown
                options={_options}
                customStyles={obj}
                onChange={({ label, value }) => {
                    helpers.setValue(value);
                }}
                value={
                    _options
                        ? _options.find((option) => option.value === field.value)
                        : ""
                }
                hasError={hasError}
            />
            {hasError ? <ErrorLabel text={meta.error} /> : null}
        </FieldWrapper>
    );
};

export const SelectField = ({ title, options,onChange,isDisabled=false,isMandatory=true, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    let _options = options.map((item) => ({ value: item.value, label: item.name }));
    return (
        <FieldWrapper>
            <div>
             <Label text={title} />{isMandatory?<Asterisk/>:<></>}
            </div>
            <DropDown
                options={_options}
                customStyles={obj}
                onChange={({ label, value }) => {
                    helpers.setValue(value);
                    onChange(value);
                }}
                value={
                    _options
                        ? _options.find((option) => option.value === field.value)
                        : ""
                }
                hasError={hasError}
                isDisabled={isDisabled}
            />
            {hasError ? <ErrorLabel text={meta.error} /> : null}
        </FieldWrapper>
    );
};

export const TextField = ({ label, disabled, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    const inputStyle = hasError
        ? `${styles.input} ${styles.inputError}`
        : styles.input;
    return (
        <FieldWrapper>
            <div>
             <Label text={label} /><Asterisk/>
            </div>
            <TextInput {...field} {...props} style={inputStyle} disabled={disabled} />
            {hasError ? <ErrorLabel text={meta.error} /> : null}
        </FieldWrapper>
    );
};
export const EmptyContainer =({children}) =>{
return <div className={styles.emptyContainer}>{children}</div>
}
