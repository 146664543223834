import React, { useEffect, useState } from "react";
import {
  LoginChildContainer,
  LoginContainer,
} from "../../components/Container/Containers";
import { Tabs } from "../../components/tabs/Tabs";
import { vfLogin } from "../../authActions/authActions";
import styles from "./login.module.css";
import { SubmitButton } from "../../components/button/buttons";
import CustomerLogin from "./CustomerLogin";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/loader/Loader";

const VfLoginContainer = () => {
  let [isSubmitVFLogin, setIsSubmitVFLogin] = useState(false);
  let counter=0;
  const NO_ACCESS_IPX_PORTAL_VF_USER = "auth/admin-restricted-operation";
  let navigate = useNavigate();
  const requestVFLogin = () => {
    setIsSubmitVFLogin(true);

    vfLogin()
      .then((res) => {
        setIsSubmitVFLogin(false);
      })
      .catch((error) => {
        console.log("SignInWithPopup Error==>", error);
        setIsSubmitVFLogin(false);
        if (error.code === NO_ACCESS_IPX_PORTAL_VF_USER) {
          navigate("/invalid-user");
        } else if (error.code == "auth/popup-closed-by-user") {
          alert(
            "Please try to login again, if you have accidentally closed the login popup."
          );
        }else if (error.code == "auth/internal-error") {
            counter=counter+1;
            if(counter < 100){ // CUP-670 --- to handle force point issue, this has to be check in feature
              requestVFLogin(); 
            }else{
              console.log("retry error==>", error);
              alert(
                "Something went wrong. Please refresh your browser and try again."
              );
              counter=0;
            }
        }else {
          console.log("generic login error==>", error);
          alert(
            "Something went wrong. Please refresh your browser and try again."
          );
        }
      });
  };
  return (
    <React.Fragment>
      {isSubmitVFLogin ? (
        <Loader style={styles.loaderContainer} />
      ) : (
        <div className={styles.vfLoginContainer}>
          <SubmitButton
            type="button"
            style={styles.vfLogin}
            onClick={() => {
              requestVFLogin();
            }}
          >
            {" "}
            Use Your Vodafone Credentials{" "}
          </SubmitButton>
        </div>
      )}
    </React.Fragment>
  );
};

// const Logo = () => {
//   return (
//     <div className={styles.headerLogo}>
//       <img className={styles.logo} src={logo} alt="Logo" />
//     </div>
//   );
// };

// const LoginHeader = () => {
//   return <div className={styles.headerText}>IPXplore</div>;
// };

// const Login = (props) => {
//   const [activeTab, setActiveTab] = useState("");
//   const Login = {
//     VODAFONE_LOGIN: "Vodafone login",
//     EXTERNAL_LOGIN: "External login"

//   }
//   useEffect(() => {
//     return () => {
//       window.recaptchaVerifier?.clear();
//       window.recaptcha = undefined;
//       window.grecaptcha = undefined;
//       window.recaptchaWidgetId = undefined; // to clear it after route change
//       window.recaptchaVerifier = undefined;
//     }
//   }, [])
//   const tabOption = [Login.VODAFONE_LOGIN, Login.EXTERNAL_LOGIN];
//   const getActiveTab = (tab) => {
//     setActiveTab(tab);
//   }
//   return (
// <LoginContainer>
//   <LoginChildContainer>
//     <Logo />
//     <LoginHeader />
//     <Tabs option={tabOption} defaultActiveTab={Login.VODAFONE_LOGIN} getActiveTab={getActiveTab} />

//     {
//       activeTab === Login.VODAFONE_LOGIN ?
//         <VfLoginContainer /> :
//         activeTab === Login.EXTERNAL_LOGIN ?
//           <div className={styles.customerLoginContainer}><CustomerLogin /></div> :
//           <></>
//     }
//   </LoginChildContainer>
// </LoginContainer >

//   )
// };

// export default Login;

const Login = () => {
  useEffect(() => {
    return () => {
      //window.recaptchaVerifier?.clear();
      /*window.recaptcha = undefined;
      window.grecaptcha = undefined;
      window.recaptchaWidgetId = undefined; // to clear it after route change*/
      window.recaptchaVerifier = undefined;
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.logoText}>
              <img className={styles.logo} src={logo} alt="Logo" />
              <div className={styles.headerText}>IPXplore</div>
          </div>
          <div className={styles.CustomerLogin}>
            <CustomerLogin />
            <div className={styles.hrlines}>OR</div>
          </div>    
          <div className={styles.vfLoginContainer}>
            <VfLoginContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
