import { connect } from "react-redux";
import { getRequestList } from "../../../../../../redux/actions/userManagement/onBoardingRequestList.action";
import OnboardingRequestListViewer from "./onboardingRequestListViewer";

const mapStateToProps = (state) => {
  // console.log("Rlist", state);
  return {
    requestList : state.onboardingRequest.onboardingRequestList.data,
    loading : state.onboardingRequest.onboardingRequestList.isLoading,
    next : state.onboardingRequest.onboardingRequestList.next
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
     getRequestList: (requestId)=>{
      return dispatch(getRequestList(requestId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingRequestListViewer);
